import { Stack } from "@mui/material";
import GooglePlaceInput from "components/Common/GooglePlaceInput";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";

export default function EditableAddressSquare({
  required,
  containerProps,
  label,
  captionRight,
  captionBottom,
  value,
  errorMessage,
  onChange,
}) {
  return (
    <Stack {...(containerProps || {})}>
      <Stack direction="row" alignItems="center" gap={4}>
        {!!label && (
          <PrimaryText fontSize={17} fontWeight={600}>
            {label}{" "}
            {!!required && (
              <span style={{ color: COLORS.primary.error }}>*</span>
            )}
          </PrimaryText>
        )}

        {!!captionRight && (
          <PrimaryText fontSize={17}>{captionRight}</PrimaryText>
        )}
      </Stack>

      {!!captionBottom && (
        <PrimaryText fontSize={12} fontWeight={200} style={{ marginTop: 3 }}>
          {captionBottom}
        </PrimaryText>
      )}

      <GooglePlaceInput
        selectedAddress={value}
        errorMessage={errorMessage}
        placeholder=""
        boxProps={{ mt: captionBottom ? 4 : 9 }}
        styles={styles}
        onChange={onChange}
      />
    </Stack>
  );
}

const styles = {
  control: {
    borderRadius: 5,
    height: 47,
  },
  indicatorsContainer: {
    height: 45,
  },
  input: {
    fontSize: 16,
  },
  singleValue: {
    fontSize: 16,
  },
};
