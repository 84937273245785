import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { COLORS } from "constants/Colors";
import { CustomInput } from "components/Inputs/CustomInput";
import { login, sendPasswordReset } from "services/auth-service";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useNavigate } from "react-router-dom";

export function PersonalLogin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); //jim@tracknicity.com
  const [password, setPassword] = useState(""); //0522MAy!
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const rayzeLogo = require("assets/rayzeLogo.png");

  useEffect(() => {
    setEmailError(null);
    setPasswordError(null);

    //check valid email here
    checkIfLoginActive();
  }, [email, password]);

  useEffect(() => {
    setLoading(false);
  }, [emailError, passwordError]);

  const checkIfLoginActive = () => {
    if (email !== "" && password !== "") {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  };

  const loginPressed = () => {
    setLoading(true);
    login(email, password, setEmailError, setPasswordError, () => {
      setLoading(false);
      navigate(Links.CorporateAccount);
    });
  };

  function forgotPasswordPressed() {
    const email = window.prompt("Enter your email address");
    if (email) {
      sendPasswordReset(email);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <View style={styles.imageContainer}>
          <Image source={rayzeLogo} style={{ width: 238, height: 168 }} />
        </View>

        <PrimaryText
          fontSize={32}
          fontWeight={300}
          style={{
            textAlign: "center",
            color: COLORS.primary.gray,
            marginBottom: 25,
          }}
        >
          Already have a rayze app account?
        </PrimaryText>

        <PrimaryText
          fontSize={20}
          fontWeight={900}
          style={{
            textAlign: "center",
            color: COLORS.primary.gray,
            marginBottom: 25,
          }}
        >
          Confused? Don’t worry!
        </PrimaryText>

        <PrimaryText
          fontSize={20}
          style={{
            textAlign: "center",
            color: COLORS.primary.gray,
            marginBottom: 30,
          }}
        >
          A personal rayze app account is required to create a corporate
          account. Please
          <br /> login or create a new personal account before creating your
          corporate account.
        </PrimaryText>

        <View style={styles.bodyContainer}>
          <CustomInput
            placeholder="Enter email*"
            value={email}
            errorMessage={emailError}
            onChangeText={(e) => setEmail(e)}
            style={{ marginBottom: 24, width: "100%" }}
            mandatory={true}
          />

          <CustomInput
            placeholder="Enter password*"
            value={password}
            errorMessage={passwordError}
            onChangeText={(e) => setPassword(e)}
            secure={true}
            mandatory={true}
            style={{ width: "100%" }}
          />

          <TouchableOpacity
            onPress={forgotPasswordPressed}
            style={styles.forgotPasswordBtn}
          >
            <Text style={styles.forgotPassword}>Forgot Password?</Text>
          </TouchableOpacity>

          <PrimaryButton
            title="Sign In"
            onPress={loginPressed}
            disabled={loginDisabled}
          />

          <View style={styles.separator}>
            <View style={styles.line} />

            <PrimaryText fontSize={20} style={styles.separatorText}>
              or
            </PrimaryText>

            <View style={styles.line} />
          </View>

          <PrimaryButton
            title="Create Personal Account"
            variant="outlined"
            to={Links.CreatePersonalAccount}
          />
        </View>
      </View>

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.primary.white,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    // minWidth: 500,
    alignItems: "center",
  },
  imageContainer: {
    alignItems: "center",
    marginBottom: 25,
  },
  loginText: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    color: COLORS.primary.lightBlue,
  },
  bodyContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 514,
    width: "100%",
  },
  forgotPasswordBtn: {
    width: "100%",
    marginTop: 6,
  },
  forgotPassword: {
    color: COLORS.primary.lightBlue,
    fontSize: 14,
    marginBottom: 31,
    textAlign: "right",
  },
  separator: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 18,
  },
  line: {
    width: 75,
    height: 1,
    backgroundColor: COLORS.primary.gray,
  },
  separatorText: { marginHorizontal: 15, color: COLORS.primary.gray },
  termsText: {
    textAlign: "center",
    color: COLORS.primary.black,
    fontSize: 14,
    fontWeight: "400",
    flexWrap: "nowrap",
  },
  termsButtonText: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    color: COLORS.primary.lightBlue,
    flexWrap: "nowrap",
  },
});
