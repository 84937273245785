import { firestore } from "utils/firebase";
import { unique } from "utils/helpers";

export const cotAccount = 'acct_1M49PfFjkJEvnTwL';

export const getAllNonprofits = async (allCauses, setNonprofits) => {
  return firestore
    .collection("organizations")
    .where("type", "==", "nonprofit")
    .where('verified', '==', true)
    .get()
    .then((querySnapshot) => {
      let temp = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        //get causes
        const causes = [];
        data.causes.forEach((cause) => {
          const causeObj = allCauses.find((c) => c.value === cause || c.rayze === cause);
          if (causeObj) {
            console.log('found stuff!', causeObj)
            causes.push(causeObj.name);
          }
        });
        temp.push({
          ...data,
          id: doc.id,
          causes,
        });
      });

      setNonprofits(temp);
    })
    .catch((e) => {
      console.log("getAllNonprofits err", e);
    });
};

export const fetchMoreOrgs = async (
  lastDoc,
  setLastDoc,
  orgs,
  setOrgs,
  type,
  allCauses,
) => {
  console.log("fetchMoreOrgs", lastDoc, type);
  let orgRef = firestore
    .collection("organizations")
    .where("type", "==", type)
    .where('verified', '==', true)
    .orderBy("name")
    // .limit(10);

  let tempOrgs = [...orgs];

  if (lastDoc) {
    orgRef = firestore
      .collection("organizations")
      .where("type", "==", type)
      .where('verified', '==', true)
      .orderBy("name")
      .startAfter(lastDoc.name)
      .limit(10);
  }
  const orgSnap = (await orgRef.get().catch((e) => {
    return console.log("fetchMoreOrgs err", e);
  })) || { docs: [] };

  console.log("orgSnap", orgSnap.docs.length);

  orgSnap.docs.map(async (item, index) => {
    const data = item.data();
    const causes = [];
    data.causes.forEach((cause) => {
      const causeObj = allCauses.find((c) => c.rayze === cause || c.value === cause);
      if (causeObj) {
        causes.push(causeObj.name);
      }
    });
    const org = {
      ...data,
      id: item.id,
      causes,
    }
    if (!org.stripeComplete) {
      org.cot = true;
      org.stripeID = cotAccount;
      org.stripeComplete = true;
    }
    tempOrgs.push(org);
  });
  console.log("tempOrgs", tempOrgs.length);
  setOrgs(tempOrgs);
  setLastDoc(tempOrgs[tempOrgs?.length - 1]);
};

export const searchOrg = (searchText, setResults, allCauses) => {
  const search = searchText.toLowerCase().replace(/[^a-zA-Z ]/g, "");

  let orgQuery = firestore
    .collection("organizations")
    .where("lowercaseName", ">=", search)
    .where("lowercaseName", "<=", search + "\uf8ff")
    .limit(20);

  let orgStateQuery = firestore
    .collection("organizations")
    .where("address.state", ">=", search)
    .where("address.state", "<=", search + "\uf8ff")
    .limit(20);

  let orgCityQuery = firestore
    .collection("organizations")
    .where("address.city", ">=", search)
    .where("address.city", "<=", search + "\uf8ff")
    .limit(20);

  let orgZipCodeQuery = firestore
    .collection("organizations")
    .where("address.zipCode", ">=", searchText)
    .where("address.zipCode", "<=", searchText + "\uf8ff")
    .limit(20);

  let promises = [];
  if (search) {
    promises = [orgQuery.get(), orgStateQuery.get(), orgCityQuery.get()];
  }
  if (searchText?.length === 5) {
    promises.push(orgZipCodeQuery.get());
  }

  Promise.all(promises)
    .then((snapshots) => {
      const temp = [];
      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const data = doc.data();
          const causes = [];
          data.causes.forEach((cause) => {
            const causeObj = allCauses.find((c) => c.value === cause || c.rayze === cause);
            if (causeObj) {
              causes.push(causeObj.name);
            }
          });
          const result = {
            id: doc.id,
            ...data,
            causes
          };
          temp.push(result);
        }
      }
      let uniq = unique(temp);
      setResults(uniq);
    })
    .catch((e) => {
      console.log("error getting org search", e);
    });
};
