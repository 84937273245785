import React, { useEffect, useState } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { ProfilePic } from "components/Pics/ProfilePic";
import { CustomInput } from "components/Inputs/CustomInput";
import { DateInput } from "components/Inputs/DateInput";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { FiUpload } from "react-icons/fi";
import { useFundraisers } from "context/FundraiserContext";
import { useOrganization } from "context/OrganizationContext";
import { FundraisingCard } from "components/Cards/FundraisingCard";
import MuiCard from "components/Common/MuiCard";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { FundraisingBackerCard } from "components/Cards/FundraisingBackerCard";
import { View } from "react-native-web";
import { capitalize, formattedDate, formattedPhoneNumber } from "utils";
import moment from "moment";
import { NavHeader } from "components/Headers/NavHeader";
import { useNavigate } from "react-router-dom";
import exportCSV from "utils/export-csv";
import { getUser } from "services/user-service";

export function FundraisingDetail() {
  const navigate = useNavigate();
  const {
    fundraiserDonations,
    setGetFundraisers,
    selectedFundraiser,
    setSelectedFundraiser,
  } = useFundraisers();
  const { selectedOrg } = useOrganization();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  // console.log("fundraiserDonations", fundraiserDonations);

  useEffect(() => {
    // console.log("useEffect", search);
    setGetFundraisers(true);
    let temp = [...fundraiserDonations];
    if (search) {
      temp = temp.filter((item) =>
        item?.user
          ? item?.user?.firstName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.user?.lastName
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.user?.email?.toLowerCase().includes(search.toLowerCase())
          : (item?.name &&
              item?.name.toLowerCase().includes(search.toLowerCase())) ||
            (item?.email &&
              item?.email.toLowerCase().includes(search.toLowerCase()))
      );
      setCurrentPage(0);
    }
    setFiltered(temp);
  }, [search, fundraiserDonations, startDate, endDate]);

  function onBack() {
    navigate(-1);
  }

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };

  // Export fundraiser backers in CSV
  const exportBackers = async () => {
    const currentData = currentTableData();

    if (!currentData?.length) return;

    const dataToExport = await Promise.all(
      currentData.map(async (item) => {
        const { email, date, amount = 0, fee = 0, userId } = item || {};
        const amountGiven = amount - fee;

        let user = item?.user;

        // const setUser = (data) => {
        // 	user = data || { firstName: "Anonymous", lastName: "" };
        // };

        // if (!user) {
        // 	userId && (await getUser(userId, setUser));
        // }

        return {
          NAME: user ? `${user?.firstName} ${user?.lastName}` : item?.name,
          EMAIL: email || user?.email,
          DATE: moment(date?.toDate?.()).format("MM/DD/YYYY"),
          "AMOUNT GIVEN": dollarFormatter.format(amountGiven),
        };
      })
    );
    exportCSV({
      data: dataToExport,
      filename: "Fundraiser Backers",
    });
  };

  const { user = {} } = selectedFundraiser || {};

  const userDetails = [
    // { title: "Email", value: user.email },
    // { title: "Phone", value: formattedPhoneNumber(user.phone) },
    { title: "Gender:", value: capitalize(user.gender) },
    {
      title: "Location:",
      value: user?.city ? user?.city + ", " + user?.state : user?.zipCode,
    },
    {
      title: "Birth Year:",
      value: user?.birthday
        ? moment(user?.birthday.toDate()).format("YYYY")
        : "",
    },
    { title: "Join Date:", value: formattedDate(user.createdAt) },
  ];

  //total sum of fundraiserDonations
  const totalAmounts = fundraiserDonations
    ? fundraiserDonations?.reduce((acc, curr) => acc + curr.amount, 0)
    : 0;
  const totalFees = fundraiserDonations
    ? fundraiserDonations?.reduce(
        (acc, curr) => acc + (curr?.fee ? curr?.fee : 0),
        0
      )
    : 0;
  const totalDonations = totalAmounts - totalFees;

  // console.log(totalAmounts, totalFees, totalDonations);

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <NavHeader title={"Champions"} onBack={onBack} />

      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="center"
        marginBottom={12}
        marginTop={20}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="center"
          flex={1}
          gap={24}
        >
          <ProfilePic uri={user?.profilePic} size={215} />

          <View style={{ alignItems: "flex-start" }}>
            <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
              {getUsersName(user)}
              {" - "}
              <PrimaryText
                fontSize={32}
                fontWeight={900}
                style={[styles.title, { color: COLORS.primary.neutral_500 }]}
              >
                @{user?.username}
              </PrimaryText>
            </PrimaryText>
            <PrimaryText
              fontSize={24}
              fontWeight={400}
              style={{ marginVertical: 12 }}
            >
              User Information
            </PrimaryText>
            {userDetails.map((u, i) => {
              const { title, value } = u;
              if (!value) return;
              return (
                <View style={styles.infoTextRow} key={i}>
                  <View style={{ marginRight: 12 }} className="text-right mr-4">
                    <PrimaryText
                      fontSize={20}
                      fontWeight={400}
                      style={{ width: 104, textAlign: "left" }}
                    >
                      {title}
                    </PrimaryText>
                  </View>
                  <PrimaryText
                    fontSize={20}
                    fontWeight={900}
                    style={u?.capitalize && { textTransform: "capitalize" }}
                  >
                    {value}
                  </PrimaryText>
                </View>
              );
            })}
          </View>
        </Box>

        <Box
          display={{ xs: "none", md: "block" }}
          width="1px"
          height={140}
          bottom={-24}
          backgroundColor={COLORS.primary.neutral_500}
        />

        <View>
          <PrimaryText
            fontSize={32}
            fontWeight={900}
            style={[styles.title, { textAlign: "center", marginBottom: 20 }]}
          >
            Details
          </PrimaryText>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <Grid item xs={12} sm={6} md={4}>
              <MuiCard
                value={dollarFormatter.format(totalDonations)}
                title="Total Raised"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}></Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MuiCard
                value={selectedFundraiser?.backers?.length || 0}
                title="Backers"
              />
            </Grid>
          </View>
        </View>
      </Box>

      <ShadowContainer style={styles.container}>
        {/** HEADER */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="100%">
            <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
              Backers
            </PrimaryText>

            <CustomInput
              placeholder="Search"
              style={{
                flexGrow: 1,
                maxWidth: 550,
                marginTop: 14,
                marginBottom: 12,
                marginRight: 24,
              }}
              value={search}
              onChangeText={setSearch}
            />
          </Box>

          {/* Export Button */}
          <PrimaryButton
            title="Export Report"
            onPress={exportBackers}
            icon={<FiUpload color="white" style={{ marginRight: 10 }} />}
            style={{ marginBottom: 0 }}
          />
        </Box>

        {/** TABLE */}
        <TableContainer>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ textAlign: "left" }}>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount Given</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentTableData() &&
                currentTableData().map((item) => (
                  <FundraisingBackerCard item={item} key={item.id} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MuiTablePagination
          count={filtered?.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e.target.value);
            setCurrentPage(0);
          }}
        />
      </ShadowContainer>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },
  infoTextRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 4,
  },
});
