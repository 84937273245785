import { Box, IconButton, Modal } from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";
import { LuX } from "react-icons/lu";

export default function EmployeeMatchModal({ open, onClose }) {
  return (
    <Modal open={open}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="100%"
          maxWidth={444}
          minHeight={100}
          bgcolor={COLORS.primary.white}
          position="relative"
          borderRadius={6}
          py={24}
          px={30}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mx={12}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 12,
              right: 12,
            }}
            onClick={onClose}
          >
            <LuX size={24} />
          </IconButton>

          <PrimaryText
            fontSize={24}
            fontWeight={900}
            style={{ textAlign: "center", width: "100%" }}
          >
            Employee Match
          </PrimaryText>

          <PrimaryText fontSize={24} fontWeight={900} style={{ marginTop: 20 }}>
            Sucess!
          </PrimaryText>

          <PrimaryText fontSize={72} fontWeight={900}>
            82
          </PrimaryText>

          <PrimaryText
            fontSize={24}
            fontWeight={500}
            style={{ textAlign: "center", marginBottom: 17 }}
          >
            Employee donations have{"\n"} been matched
          </PrimaryText>

          <PrimaryButton variant="contained" title="Finish" />
        </Box>
      </Box>
    </Modal>
  );
}
