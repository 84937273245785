import { StyleSheet, TouchableOpacity, View } from "react-native";
import React, { useEffect } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { useOrganization } from "context/OrganizationContext";
import { Links } from "constants/Links";
import { logout } from "services/auth-service";
import useMediaQuery from "hooks/useMediaQuery";

export default function CreateAccount() {
  const navigate = useNavigate();
  const { currentAuth, onboarding } = useAuth();
  const { newOrg = {}, setNewOrg } = useOrganization();
  const { getResponsiveValue } = useMediaQuery();

  useEffect(() => {
    if (!onboarding) {
      navigate(Links.Landing);
    }
  }, [onboarding]);

  function typeSelected(type) {
    setNewOrg({ ...newOrg, type });
    currentAuth
      ? navigate(Links.CorporateAccount)
      : navigate(Links.PersonalLogin);
  }

  const styles = getStyles(getResponsiveValue);

  return (
    <View style={styles.container}>
      <img src={require("assets/rayze.png")} width={77.64} alt="Rayze Logo" />

      <PrimaryText fontSize={54} fontWeight="900">
        Create Account
      </PrimaryText>

      <PrimaryText
        fontSize={32}
        style={{ color: COLORS.primary.gray, textAlign: "center" }}
      >
        Which Account Type Are You Making?
      </PrimaryText>

      <View style={styles.centered}>
        <View>
          <PrimaryButton
            title="Nonprofit Account"
            onPress={() => typeSelected("nonprofit")}
          />

          <PrimaryText
            fontSize={17}
            style={{ color: COLORS.primary.gray, lineHeight: "150%" }}
          >
            Who is this account for?
            {"\n"}・Nonprofit companies
            {"\n"}・Accepting donations
            {"\n"}・Setting up volunteer events
          </PrimaryText>
        </View>

        <View style={styles.line} />

        <View>
          <PrimaryButton
            title="Corporate Account"
            onPress={() => typeSelected("business")}
          />

          <PrimaryText
            fontSize={17}
            style={{ color: COLORS.primary.gray, lineHeight: "150%" }}
          >
            Who is this account for?
            {"\n"}・For profit companies
            {"\n"}・Employee matching campaigns
            {"\n"}・Corporate donations
          </PrimaryText>
        </View>
      </View>

      {!currentAuth ? (
        <View style={[styles.centered, { gap: 0 }]}>
          <PrimaryText fontSize={14} style={{ color: COLORS.primary.gray }}>
            Already have an organization account?{" "}
          </PrimaryText>
          <TouchableOpacity onPress={() => navigate(Links.PersonalLogin)}>
            <PrimaryText
              fontSize={14}
              style={{ color: COLORS.primary.lightBlue }}
            >
              Sign In
            </PrimaryText>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={[styles.centered, { gap: 0 }]}>
          <PrimaryText fontSize={14} style={{ color: COLORS.primary.gray }}>
            Need to login to a different account?{" "}
          </PrimaryText>
          <TouchableOpacity onPress={() => logout()}>
            <PrimaryText
              fontSize={14}
              style={{ color: COLORS.primary.lightBlue }}
            >
              Sign Out
            </PrimaryText>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

const getStyles = (getResponsiveValue) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    line: {
      height: getResponsiveValue({ xs: 1, md: 351 }),
      maxWidth: getResponsiveValue({ xs: 300, md: 1 }),
      width: "100%",
      backgroundColor: "#737373",
    },
    centered: {
      flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
      alignItems: "center",
      justifyContent: "center",
      marginTop: 60,
      gap: getResponsiveValue({ xs: 20, md: 60, lg: 130 }),
    },
  });
