import { StyleSheet, View } from "react-native";
import React, { useRef } from "react";
import { TouchableOpacity } from "react-native-web";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";

export default function UploadPhoto({
  onChangeImage,
  title,
  mandatory,
  style,
  textRowStyle,
  value,
  onClick,
}) {
  const inputRef = useRef(null);

  // const onClick = () => {
  // 	inputRef.current.click();
  // };

  const onSelectImage = (e) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const file = e.target.files[0];
      file.url = reader.result?.toString();

      onChangeImage(file);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <TouchableOpacity
      onPress={onClick}
      style={[
        style,
        {
          width: "100%",
          maxWidth: 314,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        },
      ]}
    >
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        accept=".jpg, .png"
        onChange={onSelectImage}
      />

      <View style={[styles.textRow, textRowStyle]}>
        <PrimaryText
          fontSize={20}
          fontWeight={700}
          style={{ color: COLORS.primary.gray }}
        >
          {title}
        </PrimaryText>

        {mandatory && (
          <PrimaryText
            fontSize={20}
            fontWeight={700}
            style={{ color: COLORS.primary.error }}
          >
            *
          </PrimaryText>
        )}
      </View>
      <View>
        <img
          src={value || require("assets/misc/emptyPicRect.png")}
          style={{ width: "100%", maxWidth: 314, borderRadius: 10 }}
          alt="empty"
        />
        <img
          src={require("assets/misc/plusCircle.png")}
          style={{
            width: 24,
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: -12,
            marginTop: -12,
          }}
          alt="Plus Circle"
        />
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  textRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 11,
    textAlign: "center",
    backgroundColor: "red",
  },
});
