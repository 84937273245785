import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { COLORS } from "constants/Colors";
import SelectCauses from "./SelectCauses";
import { causes } from "constants/Data";

export function CausesSelect({
  // causes,
  selectedCauses = [],
  setSelectedCauses,
}) {
  useEffect(() => {
    setSelectedCauses?.(causes);
  }, [causes]);

  const itemSelected = (item) => {
    let new_causes = [...selectedCauses];
    if (new_causes.find((x) => x === item.id)) {
      //remove
      new_causes = selectedCauses.filter((x) => x !== item.id);
    } else {
      new_causes.push(item.id);
    }
    setSelectedCauses?.(new_causes);
  };

  return (
    <View style={styles.container}>
      <View style={styles.causes}>
        <SelectCauses
          maxWidth={705}
          selectedCauses={selectedCauses}
          onChange={(e) => setSelectedCauses(e)}
          maxLimit={5}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // width: "100%",
    // paddingHorizontal: 12,
    // backgroundColor:'red'
  },
  causesContainer: {
    flex: 1,
    // height: 200,
    padding: 20,
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    marginLeft: 16,
  },
  causes: {
    // flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: 20,
    paddingTop: 12,
  },
  cause: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 6,
    paddingHorizontal: 11,
    marginRight: 8,
    marginBottom: 10,
    borderRadius: 100,
    backgroundColor: COLORS.primary.lightBlue,
    borderWidth: 0.5,
  },
  button: {
    backgroundColor: COLORS.primary.green,
    width: 100,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    marginHorizontal: 4,
    marginTop: 12,
    borderRadius: 6,
  },
});
