import React, { useState } from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  Image,
} from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { EventsTab } from "./EventsTab";
import { DonationsTab } from "./DonationsTab";
import { MembersTab } from "./MembersTab";
import { InspiringTab } from "./InspiringTab";
import { useOrganization } from "context/OrganizationContext";
import { useDonations } from "context/DonationsContext";
import { useAuth } from "context/AuthContext";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { ConfirmModal } from "components/Modals/Confirmation";
import { deleteEvent } from "services/events-service";
import { OrgFlyersPicker } from "components/Flyers/OrgFlyersPicker";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { Box } from "@mui/material";
import useMediaQuery from "hooks/useMediaQuery";

const { height } = Dimensions.get("window");

export function Dashboard() {
  const { selectedOrg, setSelectedOrg, causes, members, ourOrgs } =
    useOrganization();
  const { currentUser, queryParams } = useAuth();
  const { ourRecurringDonations } = useDonations();
  const navigate = useNavigate();

  if (queryParams.get("state")) {
    const orgId = queryParams.get("state");
    const org = ourOrgs.find((o) => o.id === orgId);
    setSelectedOrg(org);
    navigate(Links.Settings);
  }

  // console.log('queryParams2', queryParams)
  // console.log(Object.fromEntries(queryParams))
  const { getResponsiveValue } = useMediaQuery();

  const tabs =
    selectedOrg?.type === "nonprofit"
      ? ["Upcoming Events", "Recent Donations"]
      : ["Upcoming Events"];

  const url = `https://dev.donate.rayzeapp.com/orgs/${selectedOrg?.id}`;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [loading, setLoading] = useState(false);

  const statistics =
    selectedOrg?.type === "nonprofit"
      ? [
          [
            {
              title: "Total Donations",
              amount: `${dollarFormatter.format(
                selectedOrg?.totalDonations || 0
              )}`,
              color: COLORS.primary.green,
            },
            {
              title: "Monthly Recurring Amount",
              amount:
                ourRecurringDonations?.length > 0
                  ? `${dollarFormatter.format(
                      ourRecurringDonations
                        .map((x) => x.amount)
                        .reduce((a, b) => {
                          return a + b;
                        })
                    )}`
                  : "$0",
              color: COLORS.primary.green,
            },
          ],
          [
            {
              title: "Monthly Donors",
              amount: ourRecurringDonations?.length,
              color: COLORS.primary.lightBlue,
            },

            {
              title: "Team Members",
              amount: members?.length,
              color: COLORS.primary.lightBlue,
            },
          ],
        ]
      : [
          [
            {
              title: "Team Members",
              amount: members?.length,
              color: COLORS.primary.lightBlue,
            },
          ],
          [
            {
              title: "Followers",
              amount: selectedOrg?.followers || 0,
              color: COLORS.primary.lightBlue,
            },
          ],
        ];

  function onDeleteEvent() {
    setLoading(true);
    deleteEvent(showConfirmModal, onSuccessDelete);
  }

  function onSuccessDelete() {
    setShowConfirmModal(null);
    setLoading(false);
  }

  // return (

  //   <>
  //   <div ref={svgRef} style={{ display: "none" }}>
  //                       {svgCode}
  //                     </div>
  //     <Flyer selectedFlyer={'simple'} selectedOrg={selectedOrg} SvgDL={SvgDL()} />
  //     </>
  // );

  const styles = getStyles(getResponsiveValue);

  return (
    <>
      {!selectedOrg || loading ? (
        <View style={{ flexGrow: 1, height: height - 100 }}>
          <ActivityIndicator
            animating
            size={"large"}
            style={styles.activity}
            color={COLORS.primary.lightBlue}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <PrimaryText fontSize={24} fontWeight={800} style={styles.header}>
            {`Welcome to your dashboard, ${getUsersName(currentUser)}`}
          </PrimaryText>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            alignItems={"center"}
            mb={16}
            mt={12}
            gap={12}
          >
            <Image
              style={{
                width: "35%",
                borderRadius: 12,
                minWidth: 300,
                aspectRatio: 16 / 9,
              }}
              source={{ uri: selectedOrg?.coverPhoto }}
              resizeMode={"contain"}
            />

            <View style={styles.stats}>
              {statistics.map((row, i) => {
                return (
                  <View
                    style={{
                      flex: 1,
                      flexWrap: "wrap",
                      gap: 12,
                    }}
                    key={i}
                  >
                    {row.map((item, i) => {
                      const { title, amount } = item;
                      return (
                        <View style={styles.statContainer} key={i}>
                          <PrimaryText
                            fontSize={14}
                            fontWeight={600}
                            style={styles.header}
                          >
                            {title}
                          </PrimaryText>
                          <PrimaryText
                            fontSize={40}
                            fontWeight={800}
                            style={[styles.header, { color: item.color }]}
                          >
                            {amount}
                          </PrimaryText>
                        </View>
                      );
                    })}
                  </View>
                );
              })}
            </View>
          </Box>

          <View style={styles.info}>
            <View style={styles.infoContainer}>
              {/* <PrimaryText fontSize={24} fontWeight={800}>
                {selectedOrg?.name}
              </PrimaryText>
              <View style={{ marginTop: 12 }}>
                <PrimaryText fontSize={16} fontWeight={800}>
                  Address
                </PrimaryText>
                <PrimaryText fontSize={14} fontWeight={400}>
                  {`${
                    selectedOrg?.address?.line1
                      ? selectedOrg?.address?.line1 + "\n"
                      : ""
                  }${selectedOrg?.address?.city}, ${
                    selectedOrg?.address?.state +
                    (selectedOrg?.address?.zipCode ? "," : "")
                  } ${selectedOrg?.address?.zipCode || ""} `}
                </PrimaryText>
              </View>
              <View style={{ marginTop: 12 }}>
                <PrimaryText fontSize={16} fontWeight={800}>
                  Contact
                </PrimaryText>
                <PrimaryText fontSize={14} fontWeight={400}>
                  {selectedOrg?.phone}
                </PrimaryText>
                <PrimaryText fontSize={14} fontWeight={400}>
                  {selectedOrg?.adminEmail}
                </PrimaryText>
                <PrimaryText fontSize={14} fontWeight={400}>
                  {selectedOrg?.website}
                </PrimaryText>
              </View>
              <View style={{ marginTop: 12 }}>
                <PrimaryText fontSize={16} fontWeight={800}>
                  Employer Identification Number
                </PrimaryText>
                <PrimaryText fontSize={14} fontWeight={400}>
                  {selectedOrg?.ein}
                </PrimaryText>
              </View> */}

              {/** DOWNLOADS */}

              <OrgFlyersPicker selectedOrg={selectedOrg} causes={causes} />
            </View>

            {/** RIGHT */}
            {/* {selectedOrg?.stripeID && (
							<View style={styles.causesContainer}>
								<View style={{ marginBottom: 12 }}>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center"
										}}
									>
										<PrimaryText
											fontSize={24}
											fontWeight={800}
										>
											Direct Donation Link
										</PrimaryText>

									</View>
									<PrimaryText
										fontSize={14}
										fontWeight={400}
									>
										{url}
									</PrimaryText>
								</View>
							</View>
						)} */}
          </View>

          <View style={styles.tabs}>
            {tabs.map((t, i) => {
              return (
                <TouchableOpacity
                  key={i}
                  style={[
                    styles.tabsButton,
                    t === activeTab && styles.tabActive,
                  ]}
                  onPress={() => setActiveTab(t)}
                >
                  <PrimaryText
                    fontSize={14}
                    fontWeight={700}
                    style={[
                      styles.tabText,
                      t === activeTab && styles.tabTextActive,
                    ]}
                  >
                    {t}
                  </PrimaryText>
                </TouchableOpacity>
              );
            })}
          </View>

          {activeTab === tabs[0] ? (
            <EventsTab setShowConfirmModal={setShowConfirmModal} />
          ) : activeTab === tabs[1] ? (
            <DonationsTab />
          ) : activeTab === "Team Members" ? (
            <MembersTab />
          ) : activeTab === "Inspiring" ? (
            <InspiringTab />
          ) : null}
        </View>
      )}
      {!!showConfirmModal && (
        <ConfirmModal
          title={"Delete Event"}
          message={"Are you sure?"}
          onConfirm={onDeleteEvent}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
}

const getStyles = (getResponsiveValue) =>
  StyleSheet.create({
    activity: {
      flex: 1,
      width: "100%",
      backgroundColor: COLORS.primary.white,
    },
    container: {
      flex: 1,
      width: "100%",
      paddingTop: 8,
      backgroundColor: COLORS.primary.white,
    },
    header: {
      color: COLORS.primary.darkBlue,
    },
    cover_img_v: {
      width: "100%",
      minWidth: 300,
      aspectRatio: 16 / 9,
    },
    statsPhoto: {
      width: "35%",
    },
    stats: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 12,
    },
    statContainer: {
      height: 100,
      minWidth: 150,
      paddingHorizontal: 10,
      width: "96%",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: COLORS.primary.offWhite,
      borderRadius: 12,
    },
    info: {
      flexDirection: "row",
      marginBottom: 16,
      // maxHeight: 300,
    },
    infoContainer: {
      flex: 1,
      padding: 20,
      justifyContent: "space-around",
      // backgroundColor: COLORS.primary.offWhite,
      borderRadius: 12,
    },
    infoTextRow: {
      flexDirection: "row",
      alignItems: "center",
      marginVertical: 6,
    },
    causesContainer: {
      flex: 1,
      // height: 200,
      padding: 20,
      // backgroundColor: COLORS.primary.offWhite,
      borderRadius: 12,
      marginLeft: 16,
    },
    causes: {
      flex: 1,
      flexDirection: "row",
      flexWrap: "wrap",
      paddingBottom: 20,
      paddingTop: 0,
    },
    cause: {
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: 4,
      paddingHorizontal: 8,
      marginRight: 8,
      marginBottom: 10,
      borderRadius: 100,
      backgroundColor: COLORS.primary.lightBlue,
    },
    tabs: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      overflow: "scroll",
    },
    tabsButton: {
      paddingHorizontal: 20,
      height: 36,
      backgroundColor: COLORS.primary.offWhite,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      marginRight: 12,
      alignItems: "center",
      justifyContent: "center",
    },
    tabActive: {
      backgroundColor: COLORS.primary.darkBlue,
    },
    tabText: {
      textAlign: "center",
      color: COLORS.primary.darkBlue,
      fontWeight: "400",
      fontSize: 13,
    },
    tabTextActive: {
      color: COLORS.primary.white,
      fontWeight: "800",
    },
    flyerContainer: {
      backgroundColor: COLORS.primary.lightestBlue,
      borderWidth: 1,
      borderColor: "rgba(29, 161, 242, 0.4)",
      borderRadius: 5,
      padding: 4,
      paddingHorizontal: 12,
      marginRight: 12,
      // shadowColor: "#000",
      // shadowOffset: {
      //   width: 0,
      //   height: 2,
      // },
      // shadowOpacity: 0.25,
      // shadowRadius: 3.84,
    },
  });
