// Sort rows by given heading
export const sortRows = ({ sortby, tableRows, ascending }) => {
	if (!sortby && !tableRows?.length) return;

	try {
		const isString = typeof tableRows[0]?.[sortby] === "string";

		const rows = [...tableRows];

		const coalesce = isString ? "" : 0;

		rows?.sort((a, b) => {
			const firstValue = isString ? a?.[sortby].toLowerCase() : a?.[sortby] ?? coalesce;
			const secondValue = isString ? b?.[sortby].toLowerCase() : b?.[sortby] ?? coalesce;

			// console.log({ firstValue, secondValue });

			if (ascending) {
				if (isString) {
					return firstValue.localeCompare(secondValue);
				}
				return firstValue < secondValue ? -1 : 1;
			}

			if (!ascending) {
				if (isString) {
					return secondValue.localeCompare(firstValue);
				}
				return firstValue > secondValue ? -1 : 1;
			}

			return 0;
		});

		return rows;
	} catch (error) {
		console.error("Error sorting rows:", sortby, error);
		return tableRows;
	}
};
