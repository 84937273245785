import { Checkbox } from "@mui/material";
import React from "react";
import { LuSquare, LuSquareCheck } from "react-icons/lu";
import { MdOutlineIndeterminateCheckBox } from "react-icons/md";

export default function MuiCheckboxBlue(props) {
  const { indeterminate } = props;
  return (
    <Checkbox
      color="darkBlue"
      indeterminate={indeterminate}
      indeterminateIcon={<MdOutlineIndeterminateCheckBox size={23} />}
      checkedIcon={<LuSquareCheck size={23} />}
      icon={<LuSquare size={23} strokeWidth={1} />}
      sx={{
        "&.MuiCheckbox-root": {
          color: "darkBlue.main",
        },
        "&.Mui-disabled": {
          color: "neutral.400",
        },
        "&.Mui-checked": {
          color: "primary.main",
        },
      }}
      {...props}
    />
  );
}
