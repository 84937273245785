import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

import { COLORS } from "constants/Colors";
import { useDisplay } from "context/DisplayContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { CustomInput } from "../Inputs/CustomInput";
import { FaCheck, FaEdit, FaPencilAlt } from "react-icons/fa";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Cropper from "react-easy-crop";
import getCroppedImg from "screens/Settings/cropImage";
import { Image, TouchableOpacity } from "react-native-web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RiAlertFill } from "react-icons/ri";
import { CausesSelect } from "components/CausesSkills/CausesSelect";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { Button } from "components/Common/Button";
import { FullModal } from "components/Modals/FullModal";
import { QnAEdit } from "components/Common/QnAEdit";
import MuiButton from "components/Common/MuiButton";
import { NonprofitSelectionModal } from "components/Modals/NonprofitSelectionModal";
import PaymentMethodPicker from "components/Inputs/PaymentMethodPicker";
import { Box, Radio } from "@mui/material";
import MuiRadio from "components/Common/MuiRadio";
import useMediaQuery from "hooks/useMediaQuery";

const options = [
  { value: "", label: "" },
  { value: "In-Person", label: "In-Person" },
  { value: "Virtual", label: "Virtual" },
];

export const CampaignInfoForm = (props) => {
  const {
    setCoverPhotos,
    coverPhotos,
    details,
    setDetails,
    address,
    selectedCauses,
    setSelectedCauses,
  } = props;
  const { getResponsiveValue } = useMediaQuery();
  const { darkMode } = useDisplay();
  const [dateModal, setDateModal] = useState(false);
  const [startTimeModal, setStartTimeModal] = useState(false);
  const [endTimeModal, setEndTimeModal] = useState(false);
  const [value, setValue] = useState(null);

  const [editing, setEditing] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedCoverPhotoArea, setCroppedCoverPhotoArea] = useState(null);
  const [croppedCoverPhoto, setCroppedCoverPhoto] = useState(coverPhotos[0]);

  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [showNonprofitModal, setShowNonprofitModal] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(null);

  const handleSave = (property, value) => {
    if (editing) setEditing(null);
    setDetails({
      ...details,
      [property]: value,
    });
  };

  function onSelectCoverPhoto(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          setZoom(1);
          setCroppedCoverPhoto(reader.result.toString() || "");
          setShowPhotoModal("coverPhoto");
        }
        // setCrop(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onCropCoverPhotoComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      setCroppedCoverPhotoArea(croppedAreaPixels);
    },
    []
  );

  const showCroppedImage = useCallback(
    async (type) => {
      try {
        const finalImage = await getCroppedImg(
          croppedCoverPhoto,
          croppedCoverPhotoArea,
          rotation
        );
        setCoverPhotos([finalImage]);
      } catch (e) {
        console.error(e);
      }
    },
    [croppedCoverPhotoArea, rotation]
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid lightgray",
      color: state.isSelected ? COLORS.primary.white : COLORS.primary.darkGray,
      paddingHorizontal: 12,
      fontFamily: "Montserrat",
      fontSize: 14,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      width: 115,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: COLORS.primary.darkGray,
      fontFamily: "Montserrat",
      fontSize: 14,
    }),
  };

  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <CustomInput
      titleStyle={{ fontSize: 16 }}
      innerRef={ref}
      title={"Start Date"}
      mandatory
      placeholder={"Enter start date"}
      value={value ? new Date(value) : null}
      onChange={(e) =>
        setDetails({
          ...details,
          startDate: e,
        })
      }
      date
      onPress={onClick}
    />
  ));

  const EndDateInput = forwardRef(({ value, onClick }, ref) => (
    <CustomInput
      titleStyle={{ fontSize: 16 }}
      innerRef={ref}
      title={"End Date"}
      mandatory
      placeholder={"Select end date"}
      value={value ? new Date(value) : null}
      onChange={(e) =>
        setDetails({
          ...details,
          endDate: e,
        })
      }
      date
      onPress={onClick}
    />
  ));

  const style = getStyles(getResponsiveValue);

  return (
    <>
      <View style={style.container_v}>
        {/** Name */}
        <View style={style.form_item_v}>
          <CustomInput
            titleStyle={{ fontSize: 16 }}
            title={`Name Your Campaign`}
            mandatory
            placeholder={"Enter Campaign Name"}
            value={details.name}
            // maxLength={24}
            onChangeText={(text) =>
              setDetails({
                ...details,
                name: text,
              })
            }
            name
          />
        </View>
        {/** Location */}
        {/* <View style={[style.form_item_v, { zIndex: 999, marginBottom: 12 }]}>
        <CustomPicker
          title={"Which Nonprofit(s) does this support?"}
          mandatory
          styles={customStyles}
          value={
            !details?.mode
              ? null
              : options.find((x) => x.value === details?.mode)?.value
          }
          onChange={(value) => setDetails({ ...details, mode: value })}
          options={options}
          placeholder={"Select type..."}
        />
      </View> */}

        <Box mb={20}>
          <PrimaryText
            fontSize={16}
            fontWeight={900}
            style={{ marginLeft: 10 }}
          >
            Allow Donations to all Nonprofits?{" "}
            <span style={{ color: COLORS.primary.error }}>*</span>
          </PrimaryText>

          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            flexWrap="wrap"
            gap={10}
            mt={10}
          >
            {["Yes", "No"].map((option, index) => (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                key={index}
              >
                <Radio
                  sx={{ color: COLORS.primary.lightBlue }}
                  size="small"
                  onClick={() =>
                    handleSave(
                      "allowDonationsToAllNonprofits",
                      option === "Yes"
                    )
                  }
                  checked={
                    (details?.allowDonationsToAllNonprofits &&
                      option === "Yes") ||
                    (!details?.allowDonationsToAllNonprofits && option === "No")
                  }
                />
                <PrimaryText fontSize={17} fontWeight={900}>
                  {option}
                </PrimaryText>
              </Box>
            ))}

            <MuiButton
              color="primary"
              sx={{
                mb: 0,
                fontSize: 14,
                fontWeight: 700,
                ml: { xs: 0, sm: 29 },
              }}
              variant="contained"
              title="View/Edit Supported Nonprofits"
              onClick={() => setShowNonprofitModal(true)}
              disabled={details?.allowDonationsToAllNonprofits}
            />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          zIndex={997}
          marginBottom={20}
          gap={8}
        >
          <CustomInput
            titleStyle={{ fontSize: 16 }}
            title={`What is your fundraising goal?`}
            mandatory
            placeholder={"Enter Dollar Amount"}
            value={details.goal}
            style={{ bottom: -1 }}
            onChangeText={(text) =>
              setDetails({
                ...details,
                goal: text,
              })
            }
            number
          />
          <View style={{ flexDirection: "column-reverse" }}>
            <DatePicker
              selected={details?.startDate}
              onChange={(startDate) =>
                setDetails({
                  ...details,
                  startDate,
                })
              }
              customInput={<StartDateInput />}
            />
          </View>
          <View style={{ flexDirection: "column-reverse" }}>
            <DatePicker
              selected={details?.endDate}
              onChange={(endDate) =>
                setDetails({
                  ...details,
                  endDate,
                })
              }
              customInput={<EndDateInput />}
            />
          </View>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          gap={24}
          mb={28}
        >
          <CustomInput
            titleStyle={{ fontSize: 16 }}
            title="Max Match Per Employee"
            subtitle="(This cannot be changed later)"
            mandatory
            placeholder="Enter Dollar Amount"
            value={details.maxMatchPerEmployee}
            style={{ bottom: -1 }}
            onChangeText={(text) =>
              setDetails({
                ...details,
                maxMatchPerEmployee: text,
              })
            }
            number
          />

          <CustomInput
            titleStyle={{ fontSize: 16 }}
            title="Match Percentage"
            subtitle="(This cannot be changed later)"
            mandatory
            placeholder="Enter Percentage"
            value={details.matchPercentage}
            style={{ bottom: -1 }}
            onChangeText={(text) =>
              setDetails({
                ...details,
                matchPercentage: text,
              })
            }
            number
          />
        </Box>

        {/* <PaymentMethodPicker
				title="Employee Match Payment Method"
				required={true}
				paymentMethod={paymentMethod}
				onChange={(e) => setPaymentMethod(e)}
			/> */}

        {/** About */}
        <View style={[style.form_item_v, { zIndex: 0, marginTop: 28 }]}>
          <CustomInput
            titleStyle={{ fontSize: 16 }}
            title={`Describe your campaign`}
            multiline
            mandatory
            maxLength={500}
            placeholder={"Describe your event\n(500 characters max)"}
            value={details.about}
            onChangeText={(text) =>
              setDetails({
                ...details,
                about: text,
              })
            }
            onPress={() => console.log("test")}
          />
        </View>

        <View style={style.form_item_v}>
          <PrimaryText fontSize={16} fontWeight={700} style={style.textinput}>
            Cover Photo*:
          </PrimaryText>
          <View style={style.settingsRow}>
            <TouchableOpacity
              disabled={coverPhotos[0]}
              onPress={() =>
                document.getElementById("choose-cover-photo").click()
              }
              style={[
                style.cover_img_v,
                !coverPhotos[0] && {
                  borderWidth: 1,
                  borderColor: "lightgray",
                  borderRadius: 12,
                },
              ]}
            >
              <img
                style={{
                  width: coverPhotos[0] ? "100%" : undefined,
                  height: "100%",
                  borderRadius: 12,
                  objectFit: "contain",
                  alignSelf: "center",
                }}
                src={
                  coverPhotos[0]
                    ? coverPhotos[0].uri
                      ? coverPhotos[0]?.uri
                      : coverPhotos[0]
                    : require("assets/misc/emptyPicRect.png")
                }
                alt="Cover"
              />
              {!coverPhotos[0] && (
                <Image
                  source={require("assets/misc/plusCircle.png")}
                  style={{
                    width: 24,
                    height: 24,
                    position: "absolute",
                    bottom: "46%",
                    right: "47%",
                  }}
                />
              )}
            </TouchableOpacity>
          </View>
          <input
            id="choose-cover-photo"
            type="file"
            accept="image/*"
            onChange={onSelectCoverPhoto}
            style={{ display: "none" }}
          />
          {coverPhotos[0] && (
            <TouchableOpacity
              style={{ alignSelf: "flex-start", marginLeft: 4 }}
              onPress={() =>
                document.getElementById("choose-cover-photo").click()
              }
            >
              <Button
                title={"Edit/Change"}
                style={{
                  paddingVertical: 4,
                  text: {
                    fontSize: 12,
                    fontWeight: 700,
                  },
                }}
                iconLeft={
                  <FaEdit
                    size={14}
                    color={COLORS.primary.white}
                    style={{ marginRight: 4 }}
                  />
                }
                onPress={() => {
                  // setZoom(1)
                  setShowPhotoModal("coverPhoto");
                }}
              />
            </TouchableOpacity>
          )}
        </View>

        {showNonprofitModal && (
          <NonprofitSelectionModal
            supportedNonprofits={details?.supportedNonprofits}
            showNonprofitModal={showNonprofitModal}
            setShowNonprofitModal={setShowNonprofitModal}
            onUpdateSupportedNonprofits={(selectedNonprofits) => {
              handleSave("supportedNonprofits", selectedNonprofits);
              setShowNonprofitModal(false);
            }}
            limit={5}
          />
        )}
      </View>
      {!!showPhotoModal && (
        <FullModal
          title={"Upload Cover Photo"}
          subtitle={`Upload a minimum size of "1300 x 730px" for best results.`}
          setShowModal={setShowPhotoModal}
          // containerStyle={{height: height}}
          containerStyle={{ width: "100%", height: "100%" }}
          maxWidth={600}
          zIndex={9999}
          // style={{}}
        >
          <View
            style={{
              flex: 1,
              paddingHorizontal: getResponsiveValue({ xs: 10, sm: 36 }),
              paddingBottom: 24,
            }}
          >
            <View
              style={{
                width: "100%",
                aspectRatio: 16 / 9,
                borderRadius: 4,
                overflow: "hidden",
                maxHeight: 300,
              }}
            >
              <Cropper
                image={croppedCoverPhoto}
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={16 / 9}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropCoverPhotoComplete}
                onZoomChange={setZoom}
              />
            </View>

            <View
              style={{
                width: "80%",
                alignSelf: "center",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <PrimaryText
                fontSize={12}
                fontWeight={700}
                style={{
                  color: COLORS.primary.darkGray,
                  marginRight: 8,
                }}
              >
                Zoom
              </PrimaryText>
              <input
                type="range"
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e) => {
                  setZoom(e.target.value);
                }}
                className="zoom-range"
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingHorizontal: 36,
                marginTop: 20,
              }}
            >
              <Button
                title={"Change Photo"}
                onPress={() =>
                  document
                    .getElementById(
                      showPhotoModal === "coverPhoto"
                        ? "choose-cover-photo"
                        : "choose-profile-photo"
                    )
                    .click()
                }
                style={{
                  backgroundColor: COLORS.primary.offWhite,
                  borderColor: COLORS.primary.darkGray,
                  borderWidth: 1,
                  text: {
                    color: COLORS.primary.darkGray,
                  },
                }}
              />
              <Button
                title={"Confirm"}
                onPress={() => {
                  showCroppedImage(showPhotoModal);
                  setShowPhotoModal(false);
                }}
                // onPress={saveNewCampaign}
                // style={{
                //   opacity: saveCampaignDisabled ? 0.5 : 1,
                // }}
                // disabled={saveCampaignDisabled}
              />
            </View>
          </View>
        </FullModal>
      )}
    </>
  );
};

const getStyles = (getResponsiveValue) =>
  StyleSheet.create({
    container_v: {
      paddingHorizontal: getResponsiveValue({ xs: 10, md: 20 }),
      maxWidth: 775,
    },
    form_item_v: {
      width: "100%",
      marginBottom: 20,
      // zIndex: 0,
      borderWidth: 0,
    },
    form_item: {
      height: 50,
    },
    verticalScroll: {
      flex: 1,

      alignContent: "center",
    },
    errorContainer: {
      backgroundColor: "#FEEFEF",
      height: 32,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingHorizontal: 16,
      paddingVertical: 6,
      borderRadius: 8,
      flexDirection: "row",
      marginBottom: 12,
    },
    errorMessage: {
      color: COLORS.primary.error,
      paddingLeft: 8,
    },
    coverPhotos: {
      fontSize: 16,
      fontWeight: "600",
    },
    textinput: {
      paddingLeft: 10,
      color: COLORS.primary.darkBlue,
    },
    dateContainer: {
      marginHorizontal: 6,
      backgroundColor: COLORS.primary.white,
      alignItems: "center",
    },
    cover_img_v: {
      width: "100%",
      maxWidth: 300,
      aspectRatio: 16 / 9,
      alignSelf: "center",
    },
    settingsRow: {
      flexDirection: "row",
      margin: 12,
      alignItems: "center",
    },
  });
