import {
  Box,
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Misc from "assets/misc";
import { ChevronLeft } from "assets/svgs";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Button } from "components/Common/Button";
import LoadingStateless from "components/Common/LoadingStateless";
import MuiCard from "components/Common/MuiCard";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { PrimaryText } from "components/Common/PrimaryText";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { CustomInput } from "components/Inputs/CustomInput";
import EmployeeGiftConfirmationModal from "components/Modals/EmployeeGiftConfirmationModal";
import RayzeEmployeeGiftModal from "components/Modals/RayzeEmployeeGiftModal";
import { ProfilePic } from "components/Pics/ProfilePic";
import { COLORS } from "constants/Colors";
import { useUsers } from "context/UserContext";
import React, { useState } from "react";
import { FiEdit, FiUser } from "react-icons/fi";
import { LuArchive, LuShare } from "react-icons/lu";
import { useNavigate } from "react-router-dom";

export function EmployeeDetail() {
  const { selectedUser } = useUsers();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Donation History");
  const [donationHistory, setDonationHistory] = useState([]);
  const [totalDonation, setTotalDonation] = useState(0);
  const [search, setSearch] = useState("");
  const [donationsCount, setDonationsCount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [loading, setLoading] = useState();

  const userInfo = {
    Role: "Admin",
    "Job Title": "IT Manager",
    Birthday: "01/01/2009",
  };

  const userContact = {
    Email: "email@address.com",
    Phone: "000–000–0000",
  };

  function onBack() {
    navigate(-1);
  }

  return (
    <Box width="100%">
      <Box display="flex" alignItems="center">
        <IconButton onClick={onBack}>
          <ChevronLeft stroke={COLORS.primary.darkBlue} />
        </IconButton>

        <PrimaryText fontSize={24} fontWeight={900} style={{ marginLeft: 17 }}>
          Employee Details
        </PrimaryText>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        mt={20}
        ml={40}
      >
        <Button
          title="Export Report"
          iconLeft={<LuShare color="white" size={20} />}
          onPress={() => setShowModal("deposit")}
        />

        <PrimaryButton
          title="Gift Funds to Employees"
          variant="outlined"
          style={{
            height: 33,
            paddingHorizontal: 24,
            marginLeft: 32,
          }}
          icon={<FiUser size={20} />}
          titleStyle={{ marginLeft: 8 }}
          onPress={() => setShowModal("empolyeeGift")}
        />
      </Box>

      {/* User Details Section */}
      <Box mt={8} display="flex">
        <ProfilePic uri={selectedUser?.profilePic} size={215} />

        <Box ml={40}>
          <Box display="flex" alignItems="center">
            <PrimaryText fontSize={32} fontWeight={900}>
              John Doe -{" "}
              <PrimaryText
                fontSize={32}
                fontWeight={900}
                style={{ color: COLORS.primary.neutral_500 }}
              >
                @johndoe
              </PrimaryText>
            </PrimaryText>

            <FiEdit
              size={30}
              color={COLORS.primary.darkBlue}
              style={{ marginLeft: 27, marginRight: 27 }}
            />

            <LuArchive size={32} color={COLORS.primary.error} />
          </Box>

          <Box display="flex" mt={14}>
            <Box>
              <PrimaryText fontSize={24}>User Information</PrimaryText>

              {Object.entries(userInfo).map(([key, value], index) => (
                <RowItem title={key} value={value} key={key + index} />
              ))}
            </Box>

            <Box ml={50}>
              <PrimaryText fontSize={24}>Contact Information</PrimaryText>

              {Object.entries(userContact).map(([key, value], index) => (
                <RowItem title={key} value={value} key={key + index} />
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          width={2}
          height={160}
          bgcolor={COLORS.primary.neutral_400}
          ml={30}
          mr={10}
          mt={40}
        />

        <Box display="flex" flexDirection="column" alignItems="center">
          <PrimaryText fontSize={32} fontWeight={900}>
            Details
          </PrimaryText>

          <Box display="flex" alignItems="center" mt={40}>
            <MuiCard
              value={"$192,912"}
              title="Total Donated"
              containerStyle={{
                width: 220,
                height: 100,
              }}
            />

            <MuiCard
              value="195 hrs"
              title="Volunteering"
              containerStyle={{
                width: 220,
                height: 100,
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Tab & Table */}
      <Box width="100%" mt={110}>
        {/* Tabs */}
        <Box display="flex">
          {["Donation History", "Volunteering"].map((title, index) => (
            <Tab
              isActive={activeTab === title}
              title={title}
              onClick={setActiveTab}
              key={title + index}
            />
          ))}
        </Box>

        {/* Table */}
        <ShadowContainer>
          <Box mb={20} mt={10}>
            <CustomInput
              placeholder="Search"
              style={{ width: 380 }}
              onChangeText={setSearch}
              value={search}
            />
          </Box>

          {activeTab === "Donation History" && (
            <DonationHistoryTable data={donationHistory} loading={loading} />
          )}

          {activeTab === "Volunteering" && (
            <VolunteeringTable loading={loading} />
          )}

          {loading && <LoadingStateless />}

          <MuiTablePagination
            disabled={loading}
            count={donationsCount}
            page={page}
            setCurrentPage={(newPage) => {}}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => {}}
            setPageToLast={(e) => console.log({ e })}
          />
        </ShadowContainer>
      </Box>

      <RayzeEmployeeGiftModal
        isOpen={showModal === "empolyeeGift"}
        onClose={() => setShowModal(false)}
        onNext={() => setShowModal("empolyeeGiftConfirmation")}
      />

      <EmployeeGiftConfirmationModal
        isOpen={showModal === "empolyeeGiftConfirmation"}
        onClose={() => setShowModal(false)}
        onBack={() => setShowModal("empolyeeGift")}
      />
    </Box>
  );
}

const RowItem = ({ title, value }) => (
  <Box display="flex" alignItems="center" mt={15}>
    <PrimaryText
      fontSize={20}
      style={{
        width: 110,
      }}
    >
      {title}:
    </PrimaryText>
    <PrimaryText
      fontSize={20}
      fontWeight={900}
      style={{ color: title === "Email" ? COLORS.primary.lightBlue : null }}
    >
      {value}
    </PrimaryText>
  </Box>
);

const Tab = ({ title, isActive, onClick }) => (
  <ButtonBase
    sx={{
      ml: 40,
      boxShadow: isActive ? "5px 5px 15px rgba(128, 128, 128, 0.15)" : "",
      borderRadius: "10px 10px 0 0",
      overflow: "hidden",
    }}
    onClick={() => onClick(title)}
  >
    <Box
      pt={8}
      pb={10}
      px={25}
      bgcolor={isActive ? COLORS.primary.neutral : COLORS.primary.neutral_200}
    >
      <PrimaryText fontSize={24} fontWeight={900}>
        {title}
      </PrimaryText>
    </Box>
  </ButtonBase>
);

const DonationHistoryTable = ({ data, loading }) => (
  <Table className="table">
    <TableHead>
      <TableRow>
        {[
          "NONPROFIT",
          "FREQUENCY",
          "AMOUNT GIVEN",
          "COMPANY MATCH",
          "TOTAL",
          "DATE",
        ].map((title, index) => (
          <TableCell key={title + index}>{title}</TableCell>
        ))}
      </TableRow>
    </TableHead>

    {!loading && (
      <TableBody>
        {data?.map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <PrimaryText fontSize={20} fontWeight={900}>
                {row.source}
              </PrimaryText>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>{row.frequency}</PrimaryText>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>${row.amount}</PrimaryText>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>${row.companyMatch}</PrimaryText>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>${row.total}</PrimaryText>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>{row.date}</PrimaryText>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )}
  </Table>
);

const VolunteeringTable = ({ loading }) => (
  <Table className="table">
    <TableHead>
      <TableRow>
        {["EVENT NAME", "DATE", "LOCATION", "HOURS VOLUNTEERED"].map(
          (title, index) => (
            <TableCell key={title + index}>{title}</TableCell>
          )
        )}
      </TableRow>
    </TableHead>

    {!loading && (
      <TableBody>
        <TableRow>
          <TableCell>
            <Box
              display="flex"
              alignItems="center"
              alignSelf="center"
              justifyContent="center"
              flexShrink={1}
            >
              <img
                src={Misc.emptyPicRect}
                alt="Event"
                width={110}
                height={70}
                style={{
                  borderWidth: 0.5,
                  borderColor: COLORS.primary.neutral_400,
                  borderRadius: 12,
                  marginRight: 18,
                }}
              />
              <PrimaryText
                fontSize={20}
                fontWeight={900}
                style={{ color: COLORS.primary.lightBlue }}
              >
                Event Name
              </PrimaryText>
            </Box>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16}>
              Sun Dec 29, 2024 @ 3:14pm MST
            </PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16}>LIttleton, CO, 80127</PrimaryText>
          </TableCell>
          <TableCell>
            <PrimaryText fontSize={16}>4 hrs</PrimaryText>
          </TableCell>
        </TableRow>
      </TableBody>
    )}
  </Table>
);
