import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { DONATION_STATUSES } from "constants/Data";
import { COLORS } from "constants/Colors";
import moment from "moment";
import MuiCheckboxBlue from "components/Common/MuiCheckboxBlue";
import { LuCircleCheck, LuCircleX } from "react-icons/lu";
import { ActivityIndicator } from "react-native-web";

export const DonationCard = ({ item, onPress, isSelected, onCheck, onApprove, onDeny, loading }) => {
  const {
    userId,
    amount,
    date,
    organization = {},
    match = 0,
    corporateStatus = 0,
    anonymous = false,
    companyId,
  } = item;

  const [user, setUser] = useState(item?.user || {});

  useEffect(() => {
    if (anonymous) {
      setUser({
        firstName: "Anonymous",
        lastName: "",
        profilePic: "",
      });
      return;
    }
    if (userId) getUser(userId, setUser);
  }, [userId]);

  let color = COLORS.green;
  switch (corporateStatus) {
    case 0:
      color = COLORS.primary.orange;
      break;
    case 1:
      color = COLORS.primary.green;
      break;
    case 2:
      color = COLORS.primary.error;
      break;
    default:
      color = COLORS.orange;
      break;
  }

  if (!date) return null;

  return (
    <TableRow hover onClick={onPress}>
      <TableCell >
        {corporateStatus === 0 && <MuiCheckboxBlue style={{paddingLeft: 0, paddingRight: 0}} onClick={onCheck} checked={isSelected} />}
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText fontSize={18} fontWeight={900}>
          {user?.firstName ? getUsersName(user) : ""}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {organization?.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {moment(date?.toDate() ? date.toDate() : date).format("MM/DD/YYYY")}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {dollarFormatter.format(amount)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {companyId ? "-" : dollarFormatter.format(match)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {dollarFormatter.format(amount + match)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500} style={{ color }}>
          {companyId ? "-" : DONATION_STATUSES[corporateStatus]?.label}
        </PrimaryText>
      </TableCell>
      <TableCell>
        {corporateStatus === 1 && (
          loading ? (
            <ActivityIndicator
              animating={true}
              size={"small"}
              // style={styles.activity}
              color={COLORS.primary.blue}
            />
          ) : (
            <LuCircleCheck
              fill={COLORS.primary.green}
              size={30}
              stroke={COLORS.primary.neutral}
            />
          )
        )}

        {corporateStatus === 2 && (
          <LuCircleX
            fill={COLORS.primary.error}
            size={30}
            stroke={COLORS.primary.neutral}
          />
        )}
        {[0, 3].includes(corporateStatus) && (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <IconButton style={{paddingRight: 6, paddingLeft: 0}} onClick={() => corporateStatus === 0 && onApprove?.()}>
              <LuCircleCheck
                size={30}
                color={
                  corporateStatus === 0
                    ? COLORS.primary.darkBlue
                    : COLORS.primary.dark_100
                }
                strokeWidth={1}
                
              />
            </IconButton>

            <IconButton style={{paddingRight: 0, paddingLeft: 0}} onClick={() => corporateStatus === 0 && onDeny?.()}>
              <LuCircleX
                size={30}
                color={
                  corporateStatus === 0
                    ? COLORS.primary.darkBlue
                    : COLORS.primary.dark_100
                }
                strokeWidth={1}
              />
            </IconButton>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};
