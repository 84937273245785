import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { PrimaryText } from "components/Common/PrimaryText";
import {
	Box,
	FormControl,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Table,
	TableBody
} from "@mui/material";
import { ActivityIndicator, Image, View } from "react-native-web";
import { COLORS } from "constants/Colors";
import { CustomInput } from "components/Inputs/CustomInput";
import { useBusiness } from "context/Business/BusinessContext";
import PaymentMethodCard from "components/Cards/PaymentMethodCard";
import { Button } from "components/Common/Button";
import { oneTimeDonation } from "services/businesses/business-donations-service";
import { useOrganization } from "context/OrganizationContext";
import { Close } from "assets/svgs";
import PaymentMethodPicker from "components/Inputs/PaymentMethodPicker";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { LuCoins } from "react-icons/lu";
import { dollarFormatter } from "utils/helpers";
import { getTotalAndFees } from "utils/donations-helpers";

export function DonateModal(props) {
	const { isVisible, setIsVisible, selectedNonprofit } = props;

	const { paymentMethods } = useBusiness();
	const { selectedOrg } = useOrganization();
	const { rayzeAccountBalance } = useBusinessDonations();

	const [loading, setLoading] = useState(false);
	const [amount, setAmount] = useState(500);
	const [coverFees, setCoverFees] = useState(true);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState();
	const [showPaymentMethods, setShowPaymentMethods] = useState(false);

	useEffect(() => {
		if (!paymentMethods || !paymentMethods.length) return;
		console.log("paymentMethods", paymentMethods);
		const isDefault = paymentMethods.find((item) => item.isDefault);
		if (isDefault) {
			setSelectedPaymentMethod(isDefault);
		} else {
			// setSelectedPaymentMethod(paymentMethods[0]);
		}
	}, [paymentMethods]);

	const donatePressed = () => {
		console.log("donatePressed");
		setLoading(true);
		console.log(selectedOrg?.stripeCustomerId);
		oneTimeDonation(
			selectedOrg,
			selectedNonprofit,
			total,
			selectedPaymentMethod,
			coverFees,
			fee,
			() => {
				window.alert("Donation Successful!");
				setIsVisible(false);
				setLoading(false);
			}
		);
	};

	function roundToTwo(num) {
		return +(Math.round(num + "e+2") + "e-2");
	}

	function feePercentage(amount) {
		const feePercentage = 0.01 + 0;
		let application_fee = amount * feePercentage;
		if (application_fee < 0.25) application_fee = 0.25;
		const stripeFee = amount * 0.029 + 0.3;
		const finalFee = application_fee + stripeFee;

		return finalFee;
	}

	//stripe is taking fee on top of the fee we are taking
	const initialFee = roundToTwo(feePercentage(amount));
	const fee = roundToTwo(feePercentage(initialFee + amount));

	// console.log("fee", initialFee, fee);
	// const total = coverFees ? roundToTwo(amount + fee) : amount;

	const isCard = selectedPaymentMethod?.card;
	const isAmex = isCard && selectedPaymentMethod?.card?.brand === "amex";

	const calculatedTotal = getTotalAndFees(coverFees, isCard, amount, isAmex);
	const { total, finalFee, secondFee } = calculatedTotal;
	// console.log('card', selectedMethod?.card?.brand, total, isAmex, finalFee)
	console.log('calculatedTotal', calculatedTotal, isCard);

	const INSUFFICIENT = !selectedPaymentMethod &&
		total > rayzeAccountBalance
	const donateDisabled = !amount || INSUFFICIENT;

	return (
		<Modal open={isVisible}>
			<Box sx={style.modalBox}>
				<PrimaryText
					fontSize="24"
					fontWeight="900"
					style={{ textAlign: "center" }}
				>
					Donate
				</PrimaryText>

				<IconButton
					sx={style.close}
					onClick={() => setIsVisible(false)}
				>
					<Close fill={COLORS.primary.neutral_500} />
				</IconButton>

				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						marginVertical: 12
					}}
				>
					<PrimaryText
						fontSize={20}
						fontWeight={900}
					>
						{selectedNonprofit?.name}
					</PrimaryText>
					{selectedNonprofit?.verified && (
						<View style={{ marginLeft: 12 }}>
							<Image
								style={{
									width: 20,
									height: 20,
									tintColor: COLORS.primary.sunflower
								}}
								source={require("assets/verification.png")}
								resizeMode={"contain"}
							/>
						</View>
					)}
				</View>

				<PrimaryText
					fontSize={10}
					fontWeight={400}
					style={{ marginBottom: 12, fontStyle: "italic" }}
				>
					{`${selectedNonprofit?.name} has passed Rayze’s multi-step 501(c)(3) verification process. While you can rest assured that your donation is going to a good place, we always encourage you to conduct your own research before donating. Thank you.`}
				</PrimaryText>

				<CustomInput
					placeholder="Amount"
					title={"Your Donation Amount"}
					mandatory
					value={amount}
					onChangeText={(text) =>
						setAmount(Number(text.replace(/[^0-9.]/g, "")))
					}
					style={{ marginBottom: 12, alignSelf: "flex-start" }}
					titleStyle={{
						color: COLORS.primary.black,
						fontSize: 16,
						fontWeight: 900
					}}
				/>

				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
						marginBottom: 12,
						alignSelf: "flex-start"
					}}
				>
					<PrimaryText
						fontSize={16}
						fontWeight={900}
						style={{ marginRight: 12 }}
					>
						Cover Fees?
						<span style={{ color: COLORS.primary.error }}>*</span>
					</PrimaryText>
					<FormControl>
						<RadioGroup
							row
							value={coverFees ? "yes" : "no"}
							onChange={(e) => {
								setCoverFees(e.target.value === "yes");
							}}
						>
							<FormControlLabel
								control={<Radio />}
								componentsProps={{
									typography: {
										fontSize: 17,
										fontWeight: 900
									}
								}}
								value="yes"
								label="Yes"
								sx={{ ml: 4 }}
							/>
							<FormControlLabel
								componentsProps={{
									typography: {
										fontSize: 17,
										fontWeight: 900
									}
								}}
								value="no"
								control={<Radio />}
								label="No"
								sx={{ ml: 12 }}
							/>
						</RadioGroup>
					</FormControl>
				</View>

				{/** Payment Method Table */}
				{/* <PrimaryText
					fontSize={20}
					fontWeight={900}
					style={{ marginBottom: 12, alignSelf: "flex-start" }}
				>
					Payment Method
				</PrimaryText>

				<Table
					className="table"
					style={{
						borderTopLeftRadius: 12,
						overflow: "hidden",
						marginLeft: 18
					}}
				>
					<TableBody>
						{paymentMethods.map((item, i) => {
							return (
								<PaymentMethodCard
									key={i.toString()}
									isSelected={selectedPaymentMethod?.id === item?.id}
									onSelect={() => setSelectedPaymentMethod(item)}
									item={item}
									style={{ width: "100%" }}
								/>
							);
						})}

					</TableBody>
				</Table> */}
				{(!showPaymentMethods && !selectedPaymentMethod) ? (
					<Box
						display="flex"
						alignItems="center"
						gap={20}
						mt={11}
						mr={20}
					>
						<Box
							display="flex"
							flexDirection="row"
							bgcolor={
								INSUFFICIENT
									? COLORS.primary.error_50
									: true
										? COLORS.primary.lightestBlue
										: COLORS.primary.white
							}
							border={`1px solid ${INSUFFICIENT
								? COLORS.primary.error
								: true
									? COLORS.primary.lightBlue
									: COLORS.primary.neutral_400
								}`}
							borderRadius={3}
							width={308}
							boxSizing="border-box"
							py={true ? 20 : 9}
							pr={20}
							pl={24}
							justifyContent="space-between"
							sx={{ cursor: !true ? "pointer" : "default" }}
						>
							<LuCoins color={COLORS.primary.darkGray} size={24} />
							<Box
								display="flex"
								flexDirection="row"
								alignItems={"center"}
								justifyContent="space-between"
								width={'100%'}
								ml={12}
							>
								<PrimaryText
									fontSize={14}
									fontWeight={700}
								>
									Rayze Account
								</PrimaryText>
								<PrimaryText
									fontSize={10}
									fontWeight={400}
								>
									{`Balance: ${dollarFormatter.format(rayzeAccountBalance)}`}
								</PrimaryText>
							</Box>

						</Box>
						<Button
							title="Change"
							style={{
								backgroundColor: 'transparent',
								width: undefined,
								paddingVertical: 12,
								paddingHorizontal: 24,
								borderRadius: 100,
								marginTop: 12,
								text: {
									fontSize: 16,
									fontWeight: 900,
									color: COLORS.primary.lightBlue
								},
								// opacity: donateDisabled ? 0.5 : 1,
							}}
							onPress={() => setShowPaymentMethods(true)}
						/>
					</Box>
				) : (
					<PaymentMethodPicker
						boxProps={{ marginBottom: 20 }}
						paymentMethod={paymentMethod}
						rayzeAccountBalance={rayzeAccountBalance}
						insufficient={INSUFFICIENT}
						title="Payment Method"
						selectedPaymentMethod={selectedPaymentMethod}
						setSelectedPaymentMethod={setSelectedPaymentMethod}
						paymentMethods={paymentMethods}
						onChange={(e) => {
							setShowPaymentMethods(false);
							setSelectedPaymentMethod(e)
						}}
						required={true}
						showPaymentMethods={showPaymentMethods}
						setShowPaymentMethods={setShowPaymentMethods}
						setLoading={setLoading}
					/>
				)}

				{amount > 0 && (
					<View style={style.totalRow}>
						<PrimaryText
							fontSize={16}
							fontWeight={600}
						>
							Total Charge:
						</PrimaryText>

						<View style={{ flexDirection: "row" }}>
							<PrimaryText
								fontWeight={600}
								style={{ marginTop: 5, marginRight: 4 }}
							>
								$
							</PrimaryText>

							<PrimaryText
								fontSize={24}
								fontWeight={600}
							>
								{total.toFixed(2)}
							</PrimaryText>
						</View>
					</View>
				)}

				<View
					style={{
						flexDirection: "row",
						justifyContent: "space-between",
						marginVertical: 12,
						width: "100%",
						marginBottom: 30
					}}
				>
					<Button
						title={"Cancel"}
						style={{
							backgroundColor: "transparent",
							borderColor: COLORS.primary.black,
							borderWidth: 1,
							text: {
								fontSize: 14,
								color: COLORS.primary.black
							}
						}}
						onPress={() => setIsVisible(false)}
					/>
					<Button
						title={"Complete Donation"}
						style={{
							backgroundColor: COLORS.primary.lightBlue,
							text: {
								fontSize: 14
							},
							opacity: donateDisabled ? 0.5 : 1
						}}
						onPress={donatePressed}
						disabled={donateDisabled}
					/>
				</View>

				{loading && (
					<ActivityIndicator
						size="large"
						color={COLORS.primary.darkGray}
						style={{
							marginTop: 20,
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)"
						}}
						animating={loading}
					/>
				)}
			</Box>
		</Modal>
	);
}

const style = {
	modalBox: {
		minWidth: "20vw",
		maxWidth: 500,
		minHeight: "20vh",
		bgcolor: COLORS.primary.white,
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "25px",
		pt: 35,
		paddingX: 60,
		display: "flex",
		flexDirection: "column",
		border: `0.5px solid ${COLORS.primary.lightGray}`
	},
	modalHeader: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		marginTop: 32
	},
	tabBox: {
		display: "flex",
		justifyContent: "flex-start",
		width: "100%",
		marginTop: 20
	},
	tabs: {
		borderBottom: 1,
		borderColor: "gray.lighter",
		width: "100%"
		// marginTop: 30,
	},
	tabItem: ({ width, isActive }) => ({
		textTransform: "none",
		fontSize: 16,
		fontWeight: isActive ? 700 : 400,
		fontFamily: "Montserrat",
		width,
		"&:hover": {
			backgroundColor: "neutral.200"
		}
	}),
	close: { position: "absolute", right: 24, top: 20 },
	totalRow: {
		marginTop: 24
	}
};
