import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { COLORS } from "constants/Colors";
import { CustomInput } from "components/Inputs/CustomInput";
import { login, sendPasswordReset } from "services/auth-service";
import { PrimaryText } from "components/Common/PrimaryText";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";

export function Landing() {
  const navigate = useNavigate();
  const { setOnboarding } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const rayzeLogo = require("assets/rayzeLogo.png");

  useEffect(() => {
    setEmailError(null);
    setPasswordError(null);

    //check valid email here
    checkIfLoginActive();
  }, [email, password]);

  useEffect(() => {
    setLoading(false);
  }, [emailError, passwordError]);

  const checkIfLoginActive = () => {
    if (email !== "" && password !== "") {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  };

  const loginPressed = () => {
    setLoading(true);
    login(email, password, setEmailError, setPasswordError);
  };

  function forgotPasswordPressed() {
    const email = window.prompt("Enter your email address");
    if (email) {
      sendPasswordReset(email);
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.body}>
        <View style={styles.imageContainer}>
          <Image source={rayzeLogo} style={{ width: 238, height: 168 }} />
        </View>

        <PrimaryText
          fontSize={32}
          fontWeight={300}
          style={{
            textAlign: "center",
            color: COLORS.primary.gray,
            marginBottom: 58,
          }}
        >
          Please login with your organization account
        </PrimaryText>

        <View style={styles.bodyContainer}>
          <CustomInput
            placeholder="Enter email*"
            value={email}
            errorMessage={emailError}
            onChangeText={(e) => setEmail(e)}
            style={{ marginBottom: 24, width: "100%" }}
            mandatory={true}
            title="Email Address"
          />

          <CustomInput
            placeholder="Enter password*"
            value={password}
            errorMessage={passwordError}
            onChangeText={(e) => setPassword(e)}
            secure={true}
            mandatory={true}
            style={{ width: "100%" }}
            title="Password"
          />

          <TouchableOpacity
            onPress={forgotPasswordPressed}
            style={styles.forgotPasswordBtn}
          >
            <Text style={styles.forgotPassword}>Forgot Password?</Text>
          </TouchableOpacity>

          <PrimaryButton
            title="Sign In"
            onPress={loginPressed}
            disabled={loginDisabled}
          />

          <View style={styles.separator}>
            <View style={styles.line} />

            <PrimaryText fontSize={20} style={styles.separatorText}>
              or
            </PrimaryText>

            <View style={styles.line} />
          </View>

          <PrimaryButton
            title="Create Org Account"
            variant="outlined"
            // to="/create-account"
            onPress={() => {
              setOnboarding(true);
              navigate(Links.CreateAccount);
            }}
          />
        </View>
      </View>

      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={StyleSheet.absoluteFill}
          color={COLORS.primary.blue}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.primary.white,
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    width: "100%",
    maxWidth: 500,
    alignItems: "center",
  },
  imageContainer: {
    alignItems: "center",
    marginBottom: 25,
  },
  loginText: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "center",
    color: COLORS.primary.lightBlue,
  },
  bodyContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 514,
    width: "100%",
  },
  inputContainer: {
    width: "100%",
  },
  forgotPasswordBtn: {
    width: "100%",
    marginTop: 6,
  },
  forgotPassword: {
    color: COLORS.primary.lightBlue,
    fontSize: 14,
    marginBottom: 31,
    textAlign: "right",
  },
  separator: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 18,
  },
  line: {
    width: 75,
    height: 1,
    backgroundColor: COLORS.primary.gray,
  },
  separatorText: { marginHorizontal: 15, color: COLORS.primary.gray },
  termsText: {
    textAlign: "center",
    color: COLORS.primary.black,
    fontSize: 14,
    fontWeight: "400",
    flexWrap: "nowrap",
  },
  termsButtonText: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    color: COLORS.primary.lightBlue,
    flexWrap: "nowrap",
  },
});
