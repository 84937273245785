import React, { useState, createContext, useEffect, useContext } from "react";
import { useOrganization } from "./OrganizationContext";
import {
  listenForDonationsToFundraiser,
  listenForFundraiserChanges,
} from "services/fundraisers-service";

export const FundraiserContext = createContext();

export const FundraiserProvider = ({ children }) => {
  const { selectedOrg } = useOrganization();

  const [getFundraisers, setGetFundraisers] = useState(false);
  const [ourFundraisers, setOurFundraisers] = useState([]);
  const [selectedFundraiser, setSelectedFundraiser] = useState(null);
  const [fundraiserDonations, setFundraiserDonations] = useState([]);

  useEffect(() => {
    if (!selectedOrg || !getFundraisers) return;

    const sub = listenForFundraiserChanges(selectedOrg, setOurFundraisers);

    return () => {
      sub();
    };
  }, [selectedOrg, getFundraisers]);

  useEffect(() => {
    if (!selectedFundraiser) return;
    const findIndex = ourFundraisers.findIndex(
      (f) => f.id === selectedFundraiser.id
    );
    if (findIndex !== -1) {
      setSelectedFundraiser(ourFundraisers[findIndex]);
    }
  }, [ourFundraisers]);

  useEffect(() => {
    if (!selectedFundraiser) return;

    const sub = listenForDonationsToFundraiser(
      selectedOrg,
      selectedFundraiser.userId,
      setFundraiserDonations
    );

    return () => {
      sub();
      setFundraiserDonations([]);
    };
  }, [selectedFundraiser]);

  return (
    <FundraiserContext.Provider
      value={{
        setGetFundraisers,
        ourFundraisers,
        selectedFundraiser,
        setSelectedFundraiser,
        fundraiserDonations,
      }}
    >
      {children}
    </FundraiserContext.Provider>
  );
};

export const useFundraisers = () => useContext(FundraiserContext);
