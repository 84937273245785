import React, { useState } from "react";
import { COLORS } from "constants/Colors";
import { StyleSheet, View } from "react-native";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useUsers } from "context/UserContext";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "context/OrganizationContext";
import { PrimaryText } from "components/Common/PrimaryText";
import { ActivityIndicator } from "react-native-web";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { BusinessDonationCard } from "components/Cards/Business/BusinessDonationCard";
import { CustomInput } from "components/Inputs/CustomInput";
import { Button } from "components/Common/Button";
import * as FiIcons from "react-icons/fi";
import { CustomPicker } from "components/Inputs/CustomPicker";

const options = [
  { value: "all", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
];

export const BusinessVolunteers = () => {
  const navigate = useNavigate();
  const { employeeMatchDonations } = useBusinessDonations();
  const { setSelectedUser } = useUsers();
  const { selectedOrg } = useOrganization();

  const [search, setSearch] = useState("");
  const [selectedView, setSelectedView] = useState(options[0]?.value);
  const [ascending, setAscending] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [sorted, setSorted] = useState([]);

  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <View
        style={[styles.container, { paddingTop: "80%", alignItems: "center" }]}
      >
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.blue}
        />
      </View>
    );
  }

  return (
    <ShadowContainer style={styles.container}>
      <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
        Volunteer Hours
      </PrimaryText>
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          marginVertical: 12,
        }}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}
        >
          <CustomInput
            placeholder={"Search"}
            style={{ flexGrow: 1, maxWidth: 400 }}
            onChangeText={setSearch}
            value={search}
            backgroundColor={COLORS.primary.white}
          />

          <PrimaryText
            fontSize={14}
            fontWeight={900}
            style={{
              color: COLORS.primary.darkGray,
              marginHorizontal: 4,
              marginLeft: 24,
            }}
          >
            View
          </PrimaryText>
          <CustomPicker
            value={selectedView}
            onChange={(value) => setSelectedView(value)}
            options={options}
            style={{ maxWidth: 120, marginLeft: 3 }}
            backgroundColor={COLORS.primary.white}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            title={"Export Report"}
            // onPress={() => setShowInviteUser(true)}
            iconLeft={
              <FiIcons.FiUpload
                size={18}
                color={COLORS.primary.white}
                style={{ marginRight: 6 }}
              />
            }
          />
        </View>
      </View>
      <Table
        className="table"
        style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
            <TableCell>NONPROFIT</TableCell>
            <TableCell>FREQUENCY</TableCell>
            <TableCell>{"AMOUNT\nGIVEN"}</TableCell>
            <TableCell>COMPANY MATCH</TableCell>
            <TableCell>TOTAL</TableCell>
            <TableCell>STATUS</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {employeeMatchDonations &&
            employeeMatchDonations.map((el, i) => {
              return <BusinessDonationCard item={el} key={i} />;
            })}
        </TableBody>
      </Table>
    </ShadowContainer>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    // flex: 1,
    height: "100%",
    width: "100%",
  },
  headerContainer: {
    flexDirection: "row",
  },
  infoContainer: {
    backgroundColor: COLORS.primary.white,
    marginHorizontal: 12,
    borderRadius: 12,
    paddingHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 24,
  },
  title: {
    color: COLORS.primary.darkBlue,
  },
  URL: {
    fontSize: 20,
    fontWeight: 800,
    color: COLORS.primary.lightBlue,
  },
});
