import { Box } from "@mui/material";
import ErrorMessage from "components/Common/ErrorMessage";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React, { useEffect, useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";

const API_KEY = "AIzaSyDR0wfcPs5HkIoR4iJTVP8K-srlpOn-Yhs";

const onPressSuggestion = async (result) => {
  if (!result) return {};

  try {
    let details = await geocodeByPlaceId(result.value.place_id);
    details = details[0];

    let geometry = details.geometry.location,
      line1 = null,
      city = null,
      state = null,
      zipCode = null,
      streetNumber = null;

    var addressComponent = details?.address_components;
    addressComponent.forEach((comp, index) => {
      let types = comp.types;
      if (types.find((x) => x === "street_number")) {
        streetNumber = comp.long_name + " ";
      } else if (types.find((x) => x === "route")) {
        if (streetNumber !== null) {
          line1 = streetNumber + comp.long_name;
        }
      } else if (types.find((x) => x === "locality")) {
        city = comp.long_name;
      } else if (types.find((x) => x === "administrative_area_level_1")) {
        state = comp.long_name;
      } else if (types.find((x) => x === "postal_code")) {
        zipCode = comp.long_name;
      }
      if (!city && types.find((x) => x === "sublocality")) {
        city = comp.long_name;
      }
    });

    const lat = geometry.lat();
    const lng = geometry.lng();

    return {
      latLng: {
        lat,
        lng,
      },
      address: {
        line1,
        city,
        state,
        zipCode,
        formattedAddress: details.formatted_address,
      },
    };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export default function GooglePlaceInput({
  title,
  required,
  placeholder,
  onChange,
  selectedAddress,
  errorMessage,
  boxProps,
  styles: externalStyles,
}) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const { line1, city, state, zipCode } = selectedAddress || {};
    city &&
      setSearchText({
        label: line1 + ", " + city + ", " + state + " " + zipCode,
      });
  }, [selectedAddress]);

  const styles = getStyles(externalStyles);

  return (
    <Box mt={20} {...boxProps}>
      {!!title && (
        <PrimaryText
          fontSize={16}
          fontWeight={900}
          style={{ marginBottom: 20 }}
        >
          {title}
          {required && <span style={{ color: COLORS.primary.error }}>*</span>}
        </PrimaryText>
      )}

      <GooglePlacesAutocomplete
        apiKey={API_KEY}
        selectProps={{
          isClearable: true,
          value: searchText,
          placeholder: placeholder ?? "Enter Address",
          onChange: (address) => {
            setSearchText(address);
            onPressSuggestion(address).then((result) => onChange?.(result));
          },
          styles,
        }}
      />

      <ErrorMessage errorMessage={errorMessage} marginTop={12} />
    </Box>
  );
}

const getStyles = (externalStyles) => ({
  container: (provided) => ({
    ...provided,
    ...(externalStyles?.container || {}),
  }),
  valueContainer: (provided) => ({
    ...provided,
    margin: 0,
    border: 0,
    padding: 0,
    paddingLeft: 13,
    ...(externalStyles?.valueContainer || {}),
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: 30,
    minHeight: 30,
    borderColor: COLORS.primary.neutral_400,
    cursor: "text",
    ...(externalStyles?.control || {}),
  }),
  dropdownIndicator: (_) => ({
    display: "none",
  }),
  indicatorSeparator: (_) => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 30,
    cursor: "pointer",
    ...(externalStyles?.indicatorsContainer || {}),
  }),
  input: (provided) => ({
    ...provided,
    color: COLORS.primary.darkBlue,
    margin: 0,
    outline: 0,
    display: "flex",
    flex: 1,
    fontFamily: "Montserrat",
    fontSize: 14,
    ...(externalStyles?.input || {}),
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    fontFamily: "Montserrat",
    color: COLORS.primary.neutral_500,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: 14,
    fontFamily: "Montserrat",
    color: COLORS.primary.darkBlue,
    ...(externalStyles?.singleValue || {}),
  }),
});
