import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";
import { RiAlertFill } from "react-icons/ri";
import { StyleSheet, View } from "react-native";

export default function ErrorMessage({
  errorMessage,
  marginTop,
  marginBottom,
}) {
  if (!errorMessage) return null;

  return (
    <View style={[style.errorContainer, { marginBottom, marginTop }]}>
      <RiAlertFill
        width={16}
        height={16}
        fill={COLORS.primary.error}
        stroke={COLORS.primary.white}
      />
      <PrimaryText fontSize={12} fontWeight={400} style={style.errorMessage}>
        {errorMessage}
      </PrimaryText>
    </View>
  );
}

const style = StyleSheet.create({
  errorContainer: {
    backgroundColor: "#FEEFEF",
    height: 32,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 4,
    flexDirection: "row",
    marginBottom: 12,
  },
  errorMessage: {
    color: COLORS.primary.error,
    paddingLeft: 8,
  },
});
