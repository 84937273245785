import { OutlinedInput, Stack } from "@mui/material";
import ErrorMessage from "components/Common/ErrorMessage";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";

export default function TextInputSquare({
  required,
  containerProps,
  label,
  captionRight,
  captionBottom,
  multiline,
  height,
  value,
  onChange,
  name,
  errorMessage,
}) {
  return (
    <Stack {...(containerProps || {})}>
      <Stack direction="row" alignItems="center" gap={4}>
        {!!label && (
          <PrimaryText fontSize={17} fontWeight={600}>
            {label}{" "}
            {!!required && (
              <span style={{ color: COLORS.primary.error }}>*</span>
            )}
          </PrimaryText>
        )}

        {!!captionRight && (
          <PrimaryText fontSize={17}>{captionRight}</PrimaryText>
        )}
      </Stack>

      {!!captionBottom && (
        <PrimaryText fontSize={12} fontWeight={200} style={{ marginTop: 3 }}>
          {captionBottom}
        </PrimaryText>
      )}

      <OutlinedInput
        variant="outlined"
        multiline={multiline}
        value={value}
        onChange={onChange}
        name={name}
        sx={{
          marginTop: captionBottom ? 4 : 9,
          height,
          "&.MuiOutlinedInput-root": {
            py: 0,
          },
          ".MuiOutlinedInput-input": {
            py: 12,
          },
        }}
      />

      {!!errorMessage && (
        <ErrorMessage errorMessage={errorMessage} marginTop={4} />
      )}
    </Stack>
  );
}
