import { formatShortNumber } from './helpers';

export const getDonationDateString = (date, recurring, short) => {
  try {
    let dateObj = new Date(date);

    if (isNaN(dateObj)) return '-';

    let dateString = dateObj.toLocaleDateString();
    if (!recurring) return dateString;

    let startDay = dateObj.getDate();
    let todaysMonth = new Date().getMonth();
    let today = new Date();
    if (startDay <= today.getDate()) {
      today = new Date(today.setMonth(todaysMonth + 1));
    }
    today = new Date(today.setDate(startDay));
    dateString = short
      ? today.toLocaleDateString()
      : 'Next donation on: ' + today.toLocaleDateString();
    return dateString;
  } catch (e) {
    console.log('get donation string', date, e);
    return '';
  }
};

var dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatDollar = (amount, short) => {
  let dollar = dollarFormatter.format(isNaN(amount) ? 0 : amount);
  if (short && amount > 9999) {
    // let split = dollar.split('$')
    // console.log('split', split, dollar)
    let shortNum = formatShortNumber(amount);
    return '$' + shortNum;
  } else if (short) {
    return dollar.slice(0, -3);
  }
  return dollar;
};

export const canReceiveDonations = (org, user) => {
  return (org?.verified || org?.cot);
};

function bankFee(amount) {
  const feePercentage = 0.01 + 0;
  let application_fee = amount * feePercentage;
  if (application_fee < 0.25) application_fee = 0.25;
  if (application_fee > 20) application_fee = 20;
  const stripeFee = amount * 0.008 >= 5 ? 5 : amount * 0.008;
  const finalFee = application_fee + stripeFee;
  return finalFee;
}

function feePercentage(amount, isAmex) {
  const feePercentage = 0.01 + 0;
  let application_fee = amount * feePercentage;
  if (application_fee < 0.25) application_fee = 0.25;
  const stripeFee = isAmex ? amount * 0.035 : amount * 0.022 + 0.3;
  const finalFee = application_fee + stripeFee;

  return finalFee;
}

function roundToTwo(num) {
  return +(Math.round(num + 'e+2') + 'e-2');
}

export function getTotalAndFees(isCoverFees, isCard, amount, isAmex, isRayzeAccount) {
  let numberAmount = Number(amount);
  const initialFee = roundToTwo(feePercentage(amount, isAmex));
  const fee = roundToTwo(feePercentage(initialFee + numberAmount, isAmex));
  const initialBankFee = bankFee(numberAmount);
  const bankFeeAmount = roundToTwo(bankFee(initialBankFee + numberAmount));

  // console.log("fee", initialFee, fee);
  const totalCard = isCoverFees ? roundToTwo(numberAmount + fee) : numberAmount;
  const totalBank = isCoverFees
    ? roundToTwo(numberAmount + bankFeeAmount)
    : numberAmount;
  const total = roundToTwo((isCard) ? totalCard : totalBank);

  let finalFee = isCard ? fee : bankFeeAmount;
  // if (isRayzeAccount) {
  //   finalFee = bankFeeAmount;
  // }
  return {
    total,
    finalFee,
    secondFee: roundToTwo(fee - initialFee),
    initialFee,
    totalBank,
  };
}
