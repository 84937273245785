import { COLORS } from "./Colors";

export const MuiTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1792,
    },
  },
  palette: {
    primary: {
      light: COLORS.primary.lightBlue,
      main: "rgba(29, 161, 242, 1)",
      contrastText: "#fff",
    },
    secondary: {
      main: COLORS.primary.darkGray,
    },
    error: {
      main: COLORS.primary.error,
    },
    darkBlue: { main: COLORS.primary.darkBlue },
    neutral: {
      50: "#FAFAFA",
      200: "#E8E8E8",
      300: "#DEDEDE",
      350: "#D9D9D9",
      400: "#C4C4C4",
      600: "#737373",
      700: "rgba(68, 68, 68, 1)",
    },
    success: {
      main: COLORS.primary.green,
    },
    offwhite: {
      main: COLORS.primary.offWhite,
    },
    gray: {
      lighter: "rgba(196, 196, 196, 1)",
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    h1: {
      fontFamily: "Montserrat",
      fontSize: 32,
      fontWeight: 900,
    },
    p1_regular: {
      fontFamily: "Montserrat",
      fontSize: 16,
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: 17,
      fontWeight: 300,
    },
    h2_semibold: {
      fontFamily: "Montserrat",
      fontSize: 24,
      fontWeight: 600,
    },
    h4_semibold: {},
  },
  spacing: 1,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*, *::before, *::after": {
          boxSizing: "border-box",
        },
      },
    },
  },
};
