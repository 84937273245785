import React, { useEffect } from "react";
import RootNavigator from "./navigators/RootNavigator";
import { AuthProvider, useAuth } from "context/AuthContext";
import { DisplayProvider } from "context/DisplayContext";
import "./App.css";
import * as Geocode from "react-geocode";
import { isMobile, isIOS, isAndroid } from "react-device-detect";
import { StripeProvider } from "context/StripeContext";
import { LoadingProvider } from "context/LoadingContext";
import { CausesProvider } from "context/CausesContext";
import { OrganizationProvider } from "context/OrganizationContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function App() {
  const { setQueryParams } = useAuth();
  useEffect(() => {
    Geocode.setKey("AIzaSyDR0wfcPs5HkIoR4iJTVP8K-srlpOn-Yhs");

    //log query params
    const urlParams = new URLSearchParams(window.location.search);
    console.log("urlParams", window.location.search);
    setQueryParams(urlParams);

    // functions.useEmulator("localhost", 5001) //testing
  }, []);

  return <RootNavigator />;
}

export default () => {
  const path = window.location.pathname;
  if (isMobile && (isIOS || isAndroid) && path.includes("appstore")) {
    // console.log("isMobile", isIOS, isAndroid);
    if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/rayze/id1615610467";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.rayze";
    }
  } else {
    // console.log("not mobile");
    const stripePromise = loadStripe(
      "pk_test_51HxE9wC7aEEOIiKThu2L1FQL4tCIuETA1x2s71fx36xdQOdu9Xh4bTtfwMjuOKw64vnyJnzBjNwg51KafnvWoiYZ008b6NiGan"
    );
    return (
      <Elements stripe={stripePromise}>
        <DisplayProvider>
          <AuthProvider>
            <CausesProvider>
              <OrganizationProvider>
                <StripeProvider>
                  <LoadingProvider>
                    <App />
                  </LoadingProvider>
                </StripeProvider>
              </OrganizationProvider>
            </CausesProvider>
          </AuthProvider>
        </DisplayProvider>
      </Elements>
    );
  }
};
