import { Dialog, DialogActions } from "@mui/material";
import React, { useState } from "react";
import ModalHeader from "./ModalHeader";
import Steps from "./Steps";
import { ArrowRight, Calendar, Heart, MapPin } from "assets/svgs";
import DetailsAndLocation from "./DetailsAndLocation";
import { COLORS } from "constants/Colors";
import DateAndTime from "./DateAndTime";
import CausesAndSkills from "./CausesAndSkills";
import MuiButton from "components/Common/MuiButton";
import { validateUrl } from "utils/helpers";
import { createAnEvent } from "services/events-service";
import { useOrganization } from "context/OrganizationContext";

const OPTIONS = {
  Unlimited: "Unlimited",
  Limited: "Limited",
  IndividualShiftsJobs: "Individual Shifts/Jobs",
};

const DEFAULT_STATE = {
  name: "",
  about: "",
  mode: "In-Person",
  location: "",
  meetingURL: "",
  passcode: "",
  coverPhoto: null,
  numberOfVolunteers: "Unlimited",
  date: null,
  startTime: null,
  endTime: null,
  volunteersNeeded: 0,
  shifts: [],
  latLng: {},
  address: {},
  uploadWaiver: "No",
  waiver: null,
};

export default function EventCreationModal({
  open,
  onClose,
  event,
  editing = false,
}) {
  // Contexts
  const { selectedOrg } = useOrganization();

  // States
  const [activeStep, setActiveStep] = useState(0);
  const [details, setDetails] = useState(event || DEFAULT_STATE);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleEventChange = (e) => {
    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (errors?.[e.target.name]) handleErrors(e.target.name, null);
  };

  const handleChange = (name, value) => {
    setDetails((prev) => ({ ...prev, [name]: value }));
    if (errors?.[name]) handleErrors(name, null);
  };

  const handleErrors = (name, value) =>
    setErrors((prev) => ({ ...prev, [name]: value }));

  const onNextFromDetailsAndLocation = () => {
    setErrors({});

    const newErrors = {};

    if (!details?.name) newErrors.name = "Invalid event name.";
    if (!details?.about) newErrors.about = "Invalid event description.";

    if (
      details?.mode === "In-Person" &&
      (!details?.address?.line1 ||
        !details?.address?.city ||
        !details?.address?.state ||
        !details?.address?.zipCode)
    )
      newErrors.address = "Invalid address.";

    if (details?.mode === "Virtual" && validateUrl(details?.meetingURL))
      newErrors.meetingURL = "Invalid meeting url.";

    if (details?.mode === "Virtual" && details?.passCode?.length < 4)
      newErrors.passCode = "Invalid meeting passcode.";

    if (!details?.coverPhoto)
      newErrors.coverPhoto = "Please upload a cover photo.";

    if (Object.keys(newErrors).length) setErrors(newErrors);
    else setActiveStep((prev) => prev + 1);

    // console.log("details", details);
  };

  const onNextFromDateAndTime = () => {
    setErrors({});
    const newErrors = {};

    if (!details?.date) newErrors.date = "Invalid date.";

    if (!details?.startTime) newErrors.startTime = "Invalid start time.";

    if (!details?.endTime) newErrors.endTime = "Invalid end time.";

    if (
      details?.numberOfVolunteers === OPTIONS.Limited &&
      details?.volunteersNeeded < 1
    )
      newErrors.numberOfVolunteers = "Invalid number of volunteers.";

    if (
      details?.numberOfVolunteers === OPTIONS.IndividualShiftsJobs &&
      !details?.shifts?.length
    )
      newErrors.numberOfVolunteers = "Please add at least one shift.";

    if (
      details?.numberOfVolunteers === OPTIONS.IndividualShiftsJobs &&
      details?.shifts?.length
    ) {
      const shiftErrors = [];
      details?.shifts?.forEach((shift) => {
        let shiftError = "";

        if (!shift?.name) shiftError = "name, ";
        if (!shift?.spots) shiftError += "spots, ";
        if (!shift?.startTime) shiftError += "start time, ";
        if (!shift?.endTime) shiftError += "end time, ";
        if (!shift?.description) shiftError += "description, ";

        if (shiftError) {
          const errorMessage = `You missed: ${shiftError.slice(0, -2)}.`;
          shiftErrors.push({
            shiftNo: shift?.shiftNo,
            errorMessage,
          });
        }
      });

      if (shiftErrors.length) newErrors.shifts = shiftErrors;
    }

    // Set the final errors
    if (Object.keys(newErrors).length) setErrors(newErrors);
    else setActiveStep((prev) => prev + 1);
  };

  // Final step to create event
  const onCreateEvent = () => {
    setErrors({});

    const newErrors = {};

    if (!details?.categories?.length)
      newErrors.categories = "Please select at least one cause.";
    if (details?.uploadWaiver === "Yes" && !details?.waiver)
      newErrors.waiver = "Please upload a waiver file.";

    if (Object.keys(newErrors).length) setErrors(newErrors);
    else {
      setLoading(true);
      createAnEvent({
        ...details,
        orgId: selectedOrg?.id,
        orgName: selectedOrg?.name,
        orgType: selectedOrg?.type,
      })
        .then((res) => onClose?.())
        .catch((err) => {
          console.log(err);
          alert(err.message);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        variant: "outlined",
        sx: {
          width: 730,
          pb: 36,
          borderRadius: 3,
          border: `1px solid ${COLORS.primary.lightGray}`,
        },
      }}
    >
      <ModalHeader onClose={onClose} editing={editing} />

      <Steps steps={steps} active={activeStep} />

      {activeStep === 0 && (
        <DetailsAndLocation
          {...{
            details,
            errors,
            handleChange,
            handleEventChange,
            handleErrors,
          }}
        />
      )}

      {activeStep === 1 && (
        <DateAndTime
          {...{
            details,
            errors,
            handleChange,
            handleEventChange,
            handleErrors,
          }}
        />
      )}

      {activeStep === 2 && (
        <CausesAndSkills
          {...{
            details,
            errors,
            handleChange,
            handleEventChange,
            handleErrors,
          }}
        />
      )}

      <DialogActions
        sx={{
          paddingRight: 40,
          paddingTop: 22,
          flexWrap: "wrap",
          gap: { xs: 20, sm: 0 },
        }}
      >
        {activeStep > 0 && (
          <MuiButton
            variant="outlined"
            title="Back"
            color="secondary"
            onPress={() => {
              setActiveStep((prev) => prev - 1);
            }}
          />
        )}

        {activeStep < 2 && (
          <MuiButton
            title="Next"
            variant="contained"
            endIcon={<ArrowRight fill={COLORS.primary.white} />}
            onPress={() =>
              activeStep === 0
                ? onNextFromDetailsAndLocation()
                : activeStep === 1
                ? onNextFromDateAndTime()
                : null
            }
          />
        )}

        {activeStep === 2 && (
          <MuiButton
            loading={loading}
            variant="contained"
            title={editing ? "Save" : "Create Event"}
            onPress={onCreateEvent}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

const steps = [
  {
    title: "Details and Location",
    icon: (props) => <MapPin {...props} />,
  },
  {
    title: "Date and Time",
    icon: (props) => <Calendar {...props} />,
  },
  {
    title: "Causes and Skills",
    icon: (props) => <Heart {...props} />,
  },
];
