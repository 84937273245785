import { Box, FormControl, Radio } from "@mui/material";
import { Plus } from "assets/svgs";
import ShiftCard from "components/Cards/ShiftCard";
import ErrorMessage from "components/Common/ErrorMessage";
import MuiButton from "components/Common/MuiButton";
import MuiTextInput from "components/Common/MuiTextInput";
import { PrimaryText } from "components/Common/PrimaryText";
import MuiDatePicker from "components/Inputs/MuiDatePicker";
import MuiTimePicker from "components/Inputs/MuiTimePicker";
import { COLORS } from "constants/Colors";
import moment from "moment";
import React, { useState } from "react";

const OPTIONS = {
  Unlimited: "Unlimited",
  Limited: "Limited",
  IndividualShiftsJobs: "Individual Shifts/Jobs",
};

const DEFAULT_SHIFT_STATE = {
  name: "",
  startTime: null,
  endTime: null,
  description: "",
  shiftNo: 1,
  spots: 1,
};

export default function DateAndTime({
  details,
  handleChange,
  errors,
  handleEventChange,
}) {
  const [expandedShiftCard, setExpandedShiftCard] = useState(null);

  const isToday = details?.date?.isSame?.(moment(), "day");

  const isDateValid =
    moment(details?.date).isValid() || moment.isMoment(details?.date);

  const isStartTimeValid =
    moment(details?.startTime).isValid() || moment.isMoment(details?.startTime);

  const isEndTimeValid =
    moment(details?.endTime).isValid() || moment.isMoment(details?.endTime);

  const onShiftDelete = (index) => {
    const newShifts = [...details?.shifts];
    newShifts.splice(index, 1);
    handleChange("shifts", newShifts);
  };

  const addShift = () => {
    const lastShiftNo = details?.shifts?.[details?.shifts?.length - 1]?.shiftNo;

    handleChange("shifts", [
      ...details?.shifts,
      {
        ...DEFAULT_SHIFT_STATE,
        startTime: details?.startTime,
        endTime: details?.endTime,
        shiftNo: lastShiftNo + 1 || 1,
      },
    ]);
  };

  return (
    <Box px={40}>
      {/* <EventRadioGroup
				title="Frequency"
				options={["One-Time", "Recurring"]}
				onChange={setFrequency}
				value={frequency}
			/> */}

      <Box
        display="flex"
        alignItems="flex-start"
        flexWrap="wrap"
        gap={20}
        mb={20}
        mt={16}
      >
        <MuiDatePicker
          label="Date"
          value={details?.date}
          format="MM/DD/YYYY"
          onChange={(e) => {
            handleChange("date", e);
            handleChange("startTime", null);
            handleChange("endTime", null);
          }}
          required
          errorMessage={errors?.date}
        />

        <MuiTimePicker
          label="Start Time"
          value={details?.startTime}
          onChange={(e) => {
            handleChange("startTime", e);
            // If the end time is null, same or before then set the end time after 5 minutes
            if (
              (details?.endTime &&
                moment(details?.endTime).isSameOrBefore(e)) ||
              !details?.endTime
            )
              handleChange("endTime", moment(e).add(5, "m").format());
          }}
          minTime={isToday ? moment().add(5, "m") : null}
          required
          errorMessage={errors?.startTime}
        />

        <MuiTimePicker
          label="End Time"
          value={details?.endTime}
          onChange={(e) => handleChange("endTime", e)}
          required
          errorMessage={errors?.endTime}
          minTime={
            moment(details?.startTime).isValid() &&
            moment(details?.startTime).add(5, "m")
          }
        />
      </Box>

      <EventRadioGroup
        title="Number of Volunteers"
        options={Object.values(OPTIONS)}
        onChange={(e) => handleChange("numberOfVolunteers", e)}
        value={
          details?.numberOfVolunteers
            ? details?.numberOfVolunteers
            : OPTIONS.Unlimited
        }
        details={details}
        handleEventChange={handleEventChange}
      />

      <ErrorMessage
        errorMessage={errors?.numberOfVolunteers}
        marginBottom={12}
      />

      {details?.numberOfVolunteers === OPTIONS.IndividualShiftsJobs && (
        <Box display="flex" flexDirection="column">
          <PrimaryText fontSize={14} fontWeight={900}>
            {!isDateValid ? (
              <ErrorMessage errorMessage="Please select a date." />
            ) : !isStartTimeValid ? (
              <ErrorMessage errorMessage="Please select a start time." />
            ) : !isEndTimeValid ? (
              <ErrorMessage errorMessage="Please select a end time." />
            ) : (
              moment(details?.date).format("dddd, MMM D")
            )}
          </PrimaryText>

          {isDateValid &&
            isStartTimeValid &&
            isEndTimeValid &&
            details?.shifts?.map((shift, index) => (
              <ShiftCard
                key={index}
                expanded={expandedShiftCard}
                onToggleExpansion={setExpandedShiftCard}
                handleChange={handleChange}
                shiftNo={shift?.shiftNo}
                shifts={details?.shifts}
                minStartTime={details?.startTime}
                maxEndTime={details?.endTime}
                errorMessage={
                  errors?.shifts?.find(
                    (shiftError) => shiftError?.shiftNo === shift?.shiftNo
                  )?.errorMessage
                }
                onDelete={() => onShiftDelete?.(index)}
              />
            ))}

          {isDateValid && isStartTimeValid && isEndTimeValid && (
            <MuiButton
              title="Add Shift"
              sx={{
                fontSize: 14,
                fontWeight: 700,
                marginTop: 28,
                width: 128,
              }}
              variant="contained"
              startIcon={<Plus fill={COLORS.primary.white} />}
              onPress={addShift}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export const EventRadioGroup = ({
  title,
  options,
  value,
  onChange,
  handleEventChange,
  details,
}) => (
  <FormControl fullWidth>
    <PrimaryText
      fontSize={16}
      fontWeight={900}
      color={COLORS.primary.darkBlue}
      style={{ marginBottom: 4 }}
    >
      {title}
      <span style={{ color: COLORS.primary.error }}>*</span>
    </PrimaryText>

    {options?.map((option) => (
      <Box
        key={option}
        display="flex"
        alignItems="center"
        onClick={() => onChange?.(option)}
        sx={{ cursor: "pointer" }}
      >
        <Radio
          checked={value === option}
          sx={{
            color: COLORS.primary.lightBlue,
            "& .MuiSvgIcon-root": {
              fontSize: 20,
            },
          }}
          value={value}
          name={option}
        />

        <PrimaryText fontSize={16} fontWeight={600}>
          {option}
        </PrimaryText>

        {option === OPTIONS.Limited && value === OPTIONS.Limited && (
          <MuiTextInput
            placeholder="Enter Number"
            boxProps={{ mt: 0, ml: 14, width: 130 }}
            name="volunteersNeeded"
            onChange={handleEventChange}
            value={details?.volunteersNeeded}
          />
        )}
      </Box>
    ))}
  </FormControl>
);
