import { firestore } from "utils/firebase";

export const pushData = async (
  collection = "fundraisers",
  data = FUNDRAISERS_DATA
) => {
  try {
    const collectionRef = firestore.collection(collection);

    const promises = data.map((item) => collectionRef.add(item));
    await Promise.all(promises);

    console.log("Document written!");
  } catch (error) {
    console.log({ error });
  }
};

export const FUNDRAISERS_DATA = [
  {
    backers: ["bobby@gmail.com", "cpnassib@gmail.com", "Paige@yahoo.com"],
    lastUpdated: new Date("February 13, 2025 at 2:45:16 AM UTC+5"),
    orgId: "WwZx6Gi48JQciTowriSw",
    totalDonations: 995.22,
    totalDonationsCount: 4,
    userEmail: "owaisulwara@gmail.com",
    userId: "SwxeLsiwQtSJgA0BIUQkWiM48VD3",
  },
  {
    backers: ["william@gmail.com", "Tommy@gmail.com", "Paige@yahoo.com"],
    lastUpdated: new Date("February 12, 2025 at 2:45:16 AM UTC+5"),
    orgId: "WwZx6Gi48JQciTowriSw",
    totalDonations: 2750,
    totalDonationsCount: 2,
    userEmail: "owaisulwara@gmail.com",
    userId: "SwxeLsiwQtSJgA0BIUQkWiM48VD3",
  },
  {
    backers: ["claire@gmail.com", "sean@yahoo.com", "Hannah@gmail.com"],
    lastUpdated: "February 11, 2025 at 9:40:56 PM UTC+5",
    orgId: "WwZx6Gi48JQciTowriSw",
    totalDonations: 850,
    totalDonationsCount: 3,
    userEmail: "owaisulwara@gmail.com",
    userId: "SwxeLsiwQtSJgA0BIUQkWiM48VD3",
  },
];
