import React, { useEffect, useState } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { ProfilePic } from "components/Pics/ProfilePic";
import { CustomInput } from "components/Inputs/CustomInput";
import { DateInput } from "components/Inputs/DateInput";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { FiUpload } from "react-icons/fi";
import { useFundraisers } from "context/FundraiserContext";
import { useOrganization } from "context/OrganizationContext";
import { FundraisingCard } from "components/Cards/FundraisingCard";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import exportCSV from "utils/export-csv";
import moment from "moment";
import { dollarFormatter } from "utils/helpers";
import { pushData } from "services/pushdata-service";
import { sortRows } from "utils/sorting";

const HEAD = [
  { title: "NAME" },
  { title: "EMAIL" },
  { title: "LATEST DONATION" },
  { title: "BACKERS" },
  { title: "AMOUNT RAISED" },
];

export function Fundraising() {
  const navigate = useNavigate();
  const { ourFundraisers, setGetFundraisers, setSelectedFundraiser } =
    useFundraisers();
  const { selectedOrg } = useOrganization();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState({
    sortBy: "totalDonations",
    order: "desc",
  });

  useEffect(() => {
    setGetFundraisers(true);

    let temp = [...(ourFundraisers || [])].map((item) => ({
      ...item,
      noOfBackers: item?.backers?.length,
    }));

    let sorted = sortRows({
      sortby: sorting.sortBy,
      tableRows: temp,
      ascending: sorting.order === "asc",
    });

    if (search) {
      sorted = sorted.filter(
        (item) =>
          item?.userEmail.toLowerCase().includes(search.toLowerCase()) ||
          item?.totalDonations.toString().includes(search)
      );
    }
    if (startDate) {
      sorted = sorted.filter((item) => item.lastUpdated.toDate() >= startDate);
    }
    if (endDate) {
      sorted = sorted.filter((item) => item.lastUpdated.toDate() <= endDate);
    }
    setFiltered(sorted);
  }, [search, ourFundraisers, startDate, endDate, sorting]);

  const resetFilters = () => {
    pushData();
    setSearch("");
    setStartDate(null);
    setEndDate(null);
  };

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };

  // Export fundraisers in CSV
  const exportFundraisers = () => {
    const currentData = currentTableData();

    if (!currentData?.length) return;

    const dataToExport = currentData.map((row) => {
      return {
        NAME: row?.user
          ? `${row?.user?.firstName} ${row?.user?.lastName}`
          : row?.name,
        EMAIL: row?.userEmail,
        "LATEST DONATION": moment(row?.lastUpdated.toDate()).format(
          "MM/DD/YYYY"
        ),
        BACKERS: row?.backers?.length,
        "AMOUNT RAISED": dollarFormatter.format(row?.totalDonations),
      };
    });

    exportCSV({
      data: dataToExport,
      filename: "Fundraisers",
    });
  };

  const handleSort = (sortBy) => {
    if (sorting.sortBy === sortBy) {
      setSorting({
        ...sorting,
        order: sorting.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSorting({ sortBy, order: "asc" });
    }
  };

  return (
    <>
      <ShadowContainer style={styles.container}>
        {/** HEADER */}
        <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
          Champions
        </PrimaryText>

        <Box
          display="flex"
          flexDirection={{ xs: "column", xl: "row" }}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={40}
          mt={14}
          gap={12}
        >
          <CustomInput
            placeholder="Search"
            style={{
              width: "100%",
              maxWidth: 350,
            }}
            value={search}
            onChangeText={setSearch}
          />

          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={12}
          >
            {/* Start Date */}
            <Box display="flex" alignItems="center">
              <PrimaryText
                fontSize={14}
                fontWeight={900}
                style={{ marginRight: 10 }}
              >
                Start Date
              </PrimaryText>
              <DateInput
                value={startDate}
                onChange={(e) => setStartDate(new Date(e.target.value))}
                placeholder="MM/DD/YY"
                long
              />
            </Box>

            {/* End Date */}
            <Box display="flex" alignItems="center">
              <PrimaryText
                fontSize={14}
                fontWeight={900}
                style={{ marginRight: 10 }}
              >
                End Date
              </PrimaryText>
              <DateInput
                value={endDate}
                onChange={(e) => setEndDate(new Date(e.target.value))}
                placeholder="MM/DD/YY"
                long
              />
            </Box>

            {/* Reset */}
            <PrimaryButton
              title="Reset"
              variant="outlined"
              titleStyle={{ fontSize: 14, fontWeight: 700 }}
              style={{ height: 30, margin: 0 }}
              onPress={resetFilters}
            />
          </Box>

          {/* Export Button */}
          <PrimaryButton
            title="Export Report"
            onPress={exportFundraisers}
            icon={<FiUpload color="white" style={{ marginRight: 10 }} />}
            style={{ marginBottom: 0 }}
          />
        </Box>

        {/** TABLE */}
        <TableContainer>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ textAlign: "left" }}>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Latest Donation</TableCell>
                <TableCell>
                  <TableSortLabel
                    direction={sorting.order}
                    active={sorting.sortBy === "noOfBackers"}
                    onClick={() => handleSort("noOfBackers")}
                  >
                    Backers
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sorting.sortBy === "totalDonations"}
                    direction={sorting.order}
                    onClick={() => handleSort("totalDonations")}
                  >
                    Amount Raised
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentTableData() &&
                currentTableData().map((item) => (
                  <FundraisingCard
                    item={item}
                    onPress={() => {
                      setSelectedFundraiser(item);
                      navigate(Links.FundraisingDetail);
                    }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <MuiTablePagination
          count={filtered?.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e.target.value);
            setCurrentPage(0);
          }}
        />
      </ShadowContainer>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </>
  );
}

// Temporary table rows
const Rows = () => (
  <TableRow>
    <TableCell
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ProfilePic size={50} />
      <PrimaryText fontSize={20} fontWeight={900} style={{ marginLeft: 14 }}>
        John Doe
      </PrimaryText>
    </TableCell>

    {/* Email */}
    <TableCell>
      <PrimaryText
        fontSize={16}
        fontWeight={400}
        style={{ color: COLORS.primary.blue }}
      >
        email@address.com
      </PrimaryText>
    </TableCell>

    {/* Latest donation */}
    <TableCell>
      <PrimaryText fontSize={16} fontWeight={400}>
        01/01/2023
      </PrimaryText>
    </TableCell>

    {/* Backers */}
    <TableCell>
      <PrimaryText fontSize={16} fontWeight={400}>
        195
      </PrimaryText>
    </TableCell>

    {/* Amount raised */}
    <TableCell>
      <PrimaryText fontSize={16} fontWeight={400}>
        $182,029
      </PrimaryText>
    </TableCell>
  </TableRow>
);

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },
});
