import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { TableCell, TableRow } from "@mui/material";
import { getEventDate } from "utils/events-helper";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { useEvents } from "context/EventContext";
import * as FiIcons from "react-icons/fi";
import { COLORS } from "constants/Colors";
import { Image, TouchableOpacity, View } from "react-native-web";
import { getCityStateZip } from "utils/helpers";

export const EventsCard = ({ item, deleteEvent }) => {
  const { name, interested, going } = item || {};

  const navigate = useNavigate();
  const { setSelectedEvent } = useEvents();

  const onSelectEvent = () => {
    setSelectedEvent(item);
    navigate(Links.EventDetail);
  };

  return (
    <TableRow onClick={onSelectEvent} hover>
      <TableCell style={{ width: 110 }}>
        <Image
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 12,
            minWidth: 110,
            aspectRatio: 16 / 9,
          }}
          source={{ uri: item?.coverPhoto }}
          resizeMode={"contain"}
        />
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText fontSize={18} fontWeight={900}>
          {name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={500}>
          {getEventDate(item, true)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={500}>
          {getCityStateZip(item)}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={500}>
          {going || 0}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={14} fontWeight={500}>
          {interested || 0}
        </PrimaryText>
      </TableCell>
      {deleteEvent && (
        <TableCell>
          <TouchableOpacity onPress={deleteEvent}>
            <FiIcons.FiTrash2 size={20} color={COLORS.primary.black} />
          </TouchableOpacity>
        </TableCell>
      )}
    </TableRow>
  );
};
