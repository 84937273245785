import { StyleSheet, View, Linking, TouchableOpacity } from "react-native";
import React from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Links } from "constants/Links";
import TextButton from "components/Buttons/TextButton";
import { useLocation, useNavigate } from "react-router-dom";

const { gray, black } = COLORS.primary;

export default function AccountCreated() {
  const navigate = useNavigate();
  const { state } = useLocation();

  // console.log('1', state)

  return (
    <View style={styles.container}>
      <img src={require("assets/rayze.png")} width={77.64} />

      <PrimaryText
        fontSize={54}
        fontWeight="900"
        style={{ textAlign: "center", marginTop: 50 }}
      >
        Account Created!!!
      </PrimaryText>

      <PrimaryText fontSize={32} style={{ color: gray, marginTop: 14 }}>
        Your account is ready to go
      </PrimaryText>

      <View style={styles.line} />

      <PrimaryText
        fontSize={24}
        style={{
          color: gray,
          marginTop: 24,
          textAlign: "center",
        }}
      >
        In order to accept direct donations on Rayze there are two more quick
        steps.
        <br />
        (You can always do these later)
      </PrimaryText>

      <PrimaryText
        fontSize={24}
        fontWeight={900}
        style={{ color: gray, marginTop: 25 }}
      >
        Step 1:
      </PrimaryText>

      <TouchableOpacity
        style={styles.verifyView}
        onPress={() => navigate(Links.VerifyNonProfit, { state })}
      >
        <PrimaryText fontSize={24} fontWeight={900} style={{ color: gray }}>
          Get Rayze Verified
        </PrimaryText>
        <img src={require("assets/verificationLarge.png")} width={77} />
      </TouchableOpacity>

      <PrimaryText fontSize={17} style={{ color: gray, marginTop: 30 }}>
        To get verified we require a PDF of your IRS Tax Exempt Determination
        letter.
      </PrimaryText>

      <PrimaryButton
        onPress={() => navigate(Links.AccountCreatedStep2, { state })}
        title="Skip"
        style={{ marginTop: 111, backgroundColor: COLORS.primary.error }}
      />

      <PrimaryText fontSize={17} style={{ color: black, marginTop: 30 }}>
        Questions? Contact us at{" "}
        <TextButton
          text="support@rayzeapp.com"
          onPress={() =>
            Linking.openURL("mailto:support@rayzeapp.com", "_blank").catch(
              (err) => alert(err.message)
            )
          }
        />
      </PrimaryText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: 500,
    height: 2,
    backgroundColor: gray,
    marginTop: 35,
  },
  verifyView: {
    width: 475,
    height: 146,
    borderRadius: 15,
    backgroundColor: COLORS.primary.neutral,
    shadowColor: "#272727",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 25,
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
  },
});
