import { StyleSheet, View, Linking } from "react-native";
import React, { useRef, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import PrimaryButton from "components/Buttons/PrimaryButton";
import TextButton from "components/Buttons/TextButton";
import { LuFiles } from "react-icons/lu";
import { Links } from "constants/Links";
import { useLoading } from "context/LoadingContext";
import { uploadOrgDoc } from "services/document-service";
import { useLocation, useNavigate } from "react-router-dom";
import { functions } from "utils/firebase";
import { updateOrganization } from "services/organizations-service";

const { gray, black } = COLORS.primary;

export default function VerifyNonProfit() {
  const inputRef = useRef(null);
  const { setLoading } = useLoading();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectedFile, setSelectedFile] = useState();

  // console.log('2',state);

  const onBrowseFile = () => inputRef.current.click();

  const handleChange = (e) => setSelectedFile(e.target.files[0]);

  function onSave() {
    setLoading(true);
    uploadOrgDoc(state?.id, selectedFile, onSuccessSave);
  }

  function onSuccessSave() {
    functions.httpsCallable("orgDocumentUploaded")({
      name: state?.name,
      orgId: state?.id,
      fileName: selectedFile?.name,
    });
    updateOrganization(state, { pendingVerification: true }, [], [], () => {});
    setLoading(false);
    navigate(Links.Verifying, { state });
  }

  return (
    <View style={styles.container}>
      <img src={require("assets/rayze.png")} width={77} alt="Rayze Logo" />

      <PrimaryText
        fontSize={54}
        fontWeight="900"
        style={{
          textAlign: "center",
          marginTop: 30,
        }}
      >
        Account Created!!!
      </PrimaryText>

      <PrimaryText
        fontSize={32}
        style={{
          textAlign: "center",
          marginTop: 14,
          color: COLORS.primary.gray,
        }}
      >
        Your account is ready to go
      </PrimaryText>

      <View style={styles.line} />

      <PrimaryText
        fontSize={20}
        style={{
          textAlign: "center",
          marginTop: 24,
          color: COLORS.primary.gray,
        }}
      >
        In order to accept direct donations on Rayze there are two more quick
        steps.
        <br />
        (You can always do these later)
      </PrimaryText>

      <PrimaryText
        fontSize={32}
        fontWeight="900"
        style={{
          textAlign: "center",
          marginTop: 28,
        }}
      >
        Step 1: Get Verified
      </PrimaryText>

      <PrimaryText
        fontSize={20}
        style={{
          textAlign: "center",
          marginTop: 26,
          color: COLORS.primary.gray,
          marginBottom: 35,
        }}
      >
        To become a verified nonprofit organization, upload your
        <br />
        IRS Tax Exempt Determination Letter
      </PrimaryText>

      {!selectedFile ? (
        <View style={styles.uploadFileView}>
          <LuFiles size={50} color={COLORS.primary.lightGray} />

          <PrimaryText
            fontSize={16}
            fontWeight={500}
            style={{ color: COLORS.primary.darkBlue }}
          >
            <TextButton
              text="browse"
              textStyle={{ fontWeight: 700 }}
              onPress={onBrowseFile}
            />{" "}
            files
          </PrimaryText>
        </View>
      ) : (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 93,
          }}
        >
          <LuFiles size={50} color={COLORS.primary.lightGray} />

          <PrimaryText
            fontSize={20}
            fontWeight={900}
            style={{ marginLeft: 10 }}
          >
            {selectedFile?.name}
          </PrimaryText>
        </View>
      )}

      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handleChange}
      />

      <PrimaryButton
        disabled={selectedFile ? false : true}
        title="Save"
        style={{ marginVertical: 20 }}
        onPress={onSave}
      />

      <PrimaryText fontSize={12} style={{ textAlign: "center" }}>
        *Please note that if your organization has a fiscal sponsor, you will
        <br />
        also need to upload a letter or a copy of existing documentation
        <br />
        that confirms the fiscal sponsor relationship in order to be verified.
      </PrimaryText>

      <PrimaryButton
        title="Skip"
        variant="outlined"
        style={{ marginTop: 49, borderColor: COLORS.primary.error }}
        titleStyle={{ color: COLORS.primary.error }}
        onPress={() => navigate(Links.AccountCreatedStep2, { state })}
      />

      <PrimaryText fontSize={17} style={{ color: black, marginTop: 48 }}>
        Questions? Contact us at{" "}
        <TextButton
          text="support@rayzeapp.com"
          onPress={() =>
            Linking.openURL("mailto:support@rayzeapp.com", "_blank").catch(
              (err) => alert(err.message)
            )
          }
        />
      </PrimaryText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    width: 555,
    height: 2,
    backgroundColor: gray,
    marginTop: 35,
  },
  uploadFileView: {
    width: 485,
    height: 112,
    borderRadius: 12,
    backgroundColor: COLORS.primary.neutral,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0.5,
    borderColor: COLORS.primary.gray,
  },
});
