import React from "react";
import { Text, StyleSheet, Dimensions } from "react-native";
import { COLORS } from "constants/Colors";

const WIDTH_OF_DESIGN_SCREEN = 1792;

export const PrimaryText = ({
  fontSize,
  fontWeight,
  color,
  style: customStyles,
  numberOfLines,
  ...props
}) => {
  const [width, setWidth] = React.useState(
    Dimensions.get("window").width < 1792
      ? Dimensions.get("window").width
      : 1792
  );

  React.useEffect(() => {
    const handleChange = ({ screen, window: win }) => {
      win.width > 1024 && win.width < 1792 && setWidth(win.width);
    };

    const subscription = Dimensions.addEventListener("change", handleChange);
    return () => {
      subscription.remove();
    };
  }, [setWidth]);

  let style = styles(fontSize, fontWeight, width, color);
  return (
    <Text
      style={[style.title, customStyles]}
      numberOfLines={numberOfLines ? numberOfLines : 0}
    >
      {props.children}
    </Text>
  );
};

const styles = (fontSize, fontWeight, screenWidth, color) => {
  const font = (fontSize / WIDTH_OF_DESIGN_SCREEN) * screenWidth;
  return StyleSheet.create({
    title: {
      fontSize: font > 12 ? font : 12,
      lineHeight: (font > 12 ? font : 12) * 1.25,
      color: color || COLORS.primary.darkBlue,
      fontFamily: "Montserrat",
      fontWeight,
      textAlign: "left",
      textAlignVertical: "middle",
    },
  });
};
