import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";
import * as FiIcons from "react-icons/fi";

import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";

export function ModalHeader({ title, onClose, subtitle }) {
  return (
    <View style={styles.modalHeader}>
      <View style={{ width: 24 }} />

      <View style={{ flex: 1 }}>
        <PrimaryText
          fontSize={24}
          fontWeight={900}
          style={{
            textAlign: "center",
          }}
        >
          {title}
        </PrimaryText>

        {subtitle && (
          <PrimaryText
            fontSize={12}
            fontWeight={400}
            style={{
              color: COLORS.primary.neutral_600,
              marginTop: 8,
              textAlign: "center",
            }}
          >
            {subtitle}
          </PrimaryText>
        )}
      </View>

      <TouchableOpacity onPress={onClose} style={{ marginTop: 4 }}>
        <FiIcons.FiX color={COLORS.primary.gray} size={24} />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  modalHeader: {
    width: "100%",
    backgroundColor: COLORS.primary.white,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    paddingHorizontal: 24,
  },
});
