import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import MuiCheckboxBlue from "components/Common/MuiCheckboxBlue";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { PrimaryText } from "components/Common/PrimaryText";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { COLORS } from "constants/Colors";
import { useBusiness } from "context/Business/BusinessContext";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { useOrganization } from "context/OrganizationContext";
import useMediaQuery from "hooks/useMediaQuery";
import { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { LuCircleCheck, LuCircleX } from "react-icons/lu";
import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  StyleSheet,
} from "react-native-web";
import { DonationCard } from "components/Cards/Business/DonationCard";
import {
  approveBusinessDonation,
  denyBusinessDonation,
} from "services/businesses/business-donations-service";
import ExportDonationModal from "components/Modals/ExportDonationModal";
import DonationMatchModal from "components/Modals/DonationMatchModal";
import EmployeeMatchModal from "components/Modals/EmployeeMatchModal";
import { dollarFormatter } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { sortRows } from "utils/sorting";
import { CompanyDonationCard } from "components/Cards/Business/CompanyDonationCard";

const options = [
  { value: "all", label: "All" },
  { value: "pending", label: "Pending" },
  { value: "approved", label: "Approved" },
  { value: "denied", label: "Denied" },
];

const options2 = [
  { value: "all", label: "All" },
  { value: "match", label: "Employee Match" },
  { value: "direct", label: "Direct Donation" }
];

export const BusinessDonations = () => {
  const { getResponsiveValue } = useMediaQuery();

  const { selectedOrg } = useOrganization();
  const { employeeMatchDonations, ourDonations, matchedDonations } = useBusinessDonations();
  const { defaultPaymentMethod } = useBusiness();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedDonations, setSelectedDonations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Employees");
  const [exportModal, setExportModal] = useState(false);
  const [donationMatchModal, setDonationMatchModal] = useState();
  const [selectedView, setSelectedView] = useState(options[0].value);
  const [sorted, setSorted] = useState({
    sortby: "date",
    ascending: false,
  });

  const [search2, setSearch2] = useState("");
  const [filtered2, setFiltered2] = useState([]);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [selectedView2, setSelectedView2] = useState(options[0].value);
  const [sorted2, setSorted2] = useState({
    sortby: "date",
    ascending: false,
  });

  useEffect(() => {
    let temp = employeeMatchDonations.sort((a, b) =>
      a.date.toDate().getTime() < b.date.toDate().getTime() ? 1 : -1
    );

    if (search) {
      temp = temp.filter(
        (item) =>
          item.organization?.name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          item.user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.user?.lastName.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (selectedView === "pending") {
      temp = temp.filter((item) => item.status === 0);
    } else if (selectedView === "approved") {
      temp = temp.filter((item) => item.status === 1);
    } else if (selectedView === "denied") {
      temp = temp.filter((item) => item.status === 2);
    }

    const sortedRows = sortRows({
      tableRows: temp,
      ...sorted,
    });
    setFiltered(sortedRows);

    // setFiltered(temp);
  }, [search, employeeMatchDonations, selectedView, sorted]);

  //check if we need to go to first page
  useEffect(() => {
    if (currentPage > 0 && filtered?.length <= currentPage * rowsPerPage) {
      setCurrentPage(0);
    }
  }, [filtered, currentPage, rowsPerPage]);

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };


  //company tab
  useEffect(() => {
    let temp = ourDonations.sort((a, b) =>
      a.date.toDate().getTime() < b.date.toDate().getTime() ? 1 : -1
    );

    if (search2) {
      temp = temp.filter(
        (item) =>
          item.organization?.name
            .toLowerCase()
            .includes(search2.toLowerCase()) ||
          item.user?.firstName.toLowerCase().includes(search2.toLowerCase()) ||
          item.user?.lastName.toLowerCase().includes(search2.toLowerCase())
      );
    }

    if (selectedView2 === "direct") {
      temp = temp.filter((item) => !matchedDonations?.some(x => x.paymentId === item.id));
    } else if (selectedView2 === "match") {
      temp = temp.filter((item) => matchedDonations?.some(x => x.paymentId === item.id));
    }

    const sortedRows = sortRows({
      tableRows: temp,
      ...sorted2,
    });
    setFiltered2(sortedRows);

    // setFiltered(temp);
  }, [search2, ourDonations, selectedView2, sorted2, matchedDonations]);

  //check if we need to go to first page
  useEffect(() => {
    if (currentPage2 > 0 && filtered2?.length <= currentPage2 * rowsPerPage2) {
      setCurrentPage2(0);
    }
  }, [filtered2, currentPage2, rowsPerPage2]);

  const currentTableData2 = () => {
    const firstPageIndex = currentPage2 * rowsPerPage2;
    const lastPageIndex = firstPageIndex + rowsPerPage2;
    return filtered2.slice(firstPageIndex, lastPageIndex);
  };

  const pendingDonations = currentTableData().filter(
    (item) => item?.corporateStatus === 0
  );

  console.log("selectedDonations", selectedDonations?.length, pendingDonations?.length);

  return (
    <>
      <Box width="100%">
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          gap={{ xs: 12, sm: 48 }}
        >
          <PrimaryText fontSize={32} fontWeight={900}>
            Donations
          </PrimaryText>

          <PrimaryButton
            title="Export Report"
            onPress={() => setExportModal(true)}
            style={{ paddingHorizontal: 20, margin: 0 }}
            icon={
              <FiUpload
                size={18}
                color={COLORS.primary.white}
                style={{ marginRight: 6 }}
              />
            }
          />
        </Stack>

        <Box overflow="hidden">
          <Stack
            direction="row"
            alignItems="center"
            ml={{ xs: 24, md: 38 }}
            gap={{ xs: 8, md: 27 }}
            mt={37}
          >
            {["Employees", "Company"].map((item) => (
              <Box
                px={{ xs: 12, md: 24 }}
                py={8}
                bgcolor={
                  item === selectedTab
                    ? COLORS.primary.neutral
                    : COLORS.primary.neutral_200
                }
                borderRadius="10px 10px 0 0"
                key={item}
                boxShadow={
                  item === selectedTab ? "5px 0px 15px 0px #80808026" : 0
                }
                sx={{ cursor: "pointer" }}
                onClick={() => setSelectedTab(item)}
              >
                <PrimaryText fontSize={24} fontWeight={900}>
                  {item}
                </PrimaryText>
              </Box>
            ))}
          </Stack>
        </Box>
        {selectedTab === "Employees" ? (
          <Box
            width="100%"
            p={{ xs: 12, md: 25 }}
            bgcolor={COLORS.primary.neutral}
            borderRadius={4}
            minHeight={100}
          >
            {/* Payment Method */}
            <Stack direction="row" alignItems="center">
              <PrimaryText fontSize={16}>Match Payment Method: </PrimaryText>
              <PrimaryText fontSize={16} fontWeight={700}>
                Visa xx-4023
              </PrimaryText>
            </Stack>

            {/* Settings */}
            <Stack direction="row" alignItems="center" gap={2}>
              <PrimaryText fontSize={14} fontWeight={300}>
                <i>Change in </i>
              </PrimaryText>

              <TouchableOpacity onPress={() => navigate("/b-settings")}>
                <PrimaryText
                  fontSize={14}
                  fontWeight={500}
                  color={COLORS.primary.lightBlue}
                >
                  Settings
                </PrimaryText>
              </TouchableOpacity>
            </Stack>

            {/* Filters */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              justifyContent="space-between"
              mt={12}
            >
              <Stack direction="row" alignItems="center">
                <MuiCheckboxBlue
                  disabled={pendingDonations?.length === 0}
                  checked={selectedDonations?.length}
                  onClick={(e) => {
                    console.log("value", e);
                    if (e.target.checked) {
                      setSelectedDonations(pendingDonations);
                    } else {
                      setSelectedDonations([]);

                    }
                  }}
                />

                {!selectedDonations?.length && (
                  <PrimaryText
                    fontSize={16}
                    fontWeight={900}
                    color={
                      pendingDonations?.length > 0
                        ? COLORS.primary.darkBlue
                        : COLORS.primary.neutral_400
                    }
                  >
                    Select All Pending
                  </PrimaryText>
                )}

                {!!selectedDonations?.length && (
                  <Stack direction="row" alignItems="center">
                    <PrimaryText fontSize={16} fontWeight={900}>
                      {selectedDonations?.length} Selected{" "}
                    </PrimaryText>

                    <PrimaryText fontSize={16} fontWeight={400}>
                      (Total Match:{" "}
                    </PrimaryText>

                    <PrimaryText fontSize={16} fontWeight={900}>
                      {dollarFormatter.format(pendingDonations?.reduce((acc, item) => acc + (item.amount * selectedOrg?.matchPercentage) / 100, 0))}
                    </PrimaryText>

                    <PrimaryText fontSize={16} fontWeight={400}>
                      )
                    </PrimaryText>
                  </Stack>
                )}

                <IconButton sx={{ ml: 17 }} disabled={!selectedDonations?.length} onClick={() => setDonationMatchModal(true)}>
                  <LuCircleCheck
                    size={30}
                    color={
                      pendingDonations?.length === 0
                        ? COLORS.primary.neutral_400
                        : selectedDonations?.length
                          ? COLORS.primary.darkBlue
                          : COLORS.primary.dark_100
                    }
                    strokeWidth={2}
                  />
                </IconButton>

                <IconButton disabled={!selectedDonations?.length}>
                  <LuCircleX
                    size={30}
                    color={
                      pendingDonations?.length === 0
                        ? COLORS.primary.neutral_400
                        : selectedDonations?.length
                          ? COLORS.primary.darkBlue
                          : COLORS.primary.dark_100
                    }
                    strokeWidth={2}
                  />
                </IconButton>
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                gap={{ xs: 12, sm: 24 }}
              >
                <CustomInput
                  placeholder={"Search"}
                  style={{ flexGrow: 1, maxWidth: 400 }}
                  onChangeText={setSearch}
                  value={search}
                  backgroundColor={COLORS.primary.white}
                />

                <PrimaryText
                  fontSize={14}
                  fontWeight={900}
                  style={{
                    color: COLORS.primary.darkGray,
                    marginHorizontal: 4,
                    display: getResponsiveValue({ xs: "none", sm: "block" }),
                  }}
                >
                  View
                </PrimaryText>
                <CustomPicker
                  value={selectedView}
                  onChange={(value) => setSelectedView(value)}
                  options={options}
                  style={{ maxWidth: 150, marginLeft: 3 }}
                  backgroundColor={COLORS.primary.white}
                />
              </Stack>
            </Stack>

            <TableContainer>
              <Table
                className="table"
                style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell width={20}></TableCell>
                    {[
                      { label: "NAME", sortby: "name", align: "left" },
                      { label: "NONPROFIT", sortby: "orgName" },
                      { label: "Date", sortby: "date" },
                      { label: "AMOUNT", sortby: "amount" },
                      { label: "MATCH", sortby: "match" },
                      { label: "TOTAL", sortby: "total" },
                      { label: "STATUS", sortby: "status" },
                    ].map(({ label, sortby, align }, index) => (
                      <TableCell style={{ textAlign: align }} key={index}>
                        <TableSortLabel
                          active={sortby === sorted?.sortby}
                          direction={sorted?.ascending ? "asc" : "desc"}
                          onClick={() =>
                            setSorted((prev) => ({
                              sortby,
                              ascending:
                                prev?.sortby === sortby ? !prev?.ascending : true,
                            }))
                          }
                        >
                          {label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentTableData() &&
                    currentTableData().map((el, i) => {
                      return (
                        <DonationCard
                          item={el}
                          key={el.id}
                          onPress={() => console.log("el", el)}
                          onApprove={() => {
                            el.corporateStatus = 1;
                            setLoading(el.id);
                            approveBusinessDonation(
                              el,
                              selectedOrg,
                              defaultPaymentMethod,
                              setLoading
                            );
                          }}
                          loading={loading === el.id}
                          onDeny={() => denyBusinessDonation(el.id)}
                          isSelected={selectedDonations?.includes(el) ? true : false}
                          onCheck={() => {
                            if (selectedDonations?.includes(el)) {
                              setSelectedDonations((prev) =>
                                prev.filter((item) => item !== el)
                              );
                            } else {
                              setSelectedDonations((prev) => [...prev, el]);
                            }
                          }}
                        />
                      );
                    })}
                </TableBody>
              </Table>

              <MuiTablePagination
                count={filtered?.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              />
            </TableContainer>
          </Box>
        ) : (
          <Box
            width="100%"
            p={{ xs: 12, md: 25 }}
            bgcolor={COLORS.primary.neutral}
            borderRadius={4}
            minHeight={100}
          >

            {/* Filters */}
            <Stack
              direction={{ xs: "column", md: "row" }}
              alignItems="center"
              justifyContent="space-between"
              mt={12}
            >

              <Stack direction="row" alignItems="center">

              </Stack>


              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                gap={{ xs: 12, sm: 24 }}
              >
                <CustomInput
                  placeholder={"Search"}
                  style={{ flexGrow: 1, maxWidth: 400 }}
                  onChangeText={setSearch2}
                  value={search2}
                  backgroundColor={COLORS.primary.white}
                />

                <PrimaryText
                  fontSize={14}
                  fontWeight={900}
                  style={{
                    color: COLORS.primary.darkGray,
                    marginHorizontal: 4,
                    display: getResponsiveValue({ xs: "none", sm: "block" }),
                  }}
                >
                  View
                </PrimaryText>
                <CustomPicker
                  value={selectedView2}
                  onChange={(value) => setSelectedView2(value)}
                  options={options2}
                  style={{ maxWidth: 150, marginLeft: 3 }}
                  backgroundColor={COLORS.primary.white}
                />
              </Stack>
            </Stack>

            <TableContainer>
              <Table
                className="table"
                style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
              >
                <TableHead>
                  <TableRow>
                    {[
                      { label: "NONPROFIT", sortby: "orgName", align: "left" },
                      { label: "DATE", sortby: "date" },
                      { label: "TYPE", sortby: "type", disabled: true },
                      // { label: "PAYMENT METHOD", sortby: "paymentMethod" },
                      { label: "DONATION TOTAL", sortby: "amount" },
                    ].map(({ label, sortby, align, disabled }, index) => (
                      <TableCell style={{ textAlign: align }} key={index}>
                        <TableSortLabel
                          active={sortby === sorted2?.sortby}
                          direction={sorted2?.ascending ? "asc" : "desc"}
                          disabled={disabled}
                          onClick={() =>
                            setSorted2((prev) => ({
                              sortby,
                              ascending:
                                prev?.sortby === sortby ? !prev?.ascending : true,
                            }))
                          }
                        >
                          {label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentTableData2() &&
                    currentTableData2().map((el, i) => {
                      const type = matchedDonations?.find(x => x.paymentId === el.id)

                      console.log('type', type);
                      return (
                        <CompanyDonationCard
                          item={el}
                          key={el.id}
                          onPress={() => console.log("el", el)}
                          isMatch={!!type}
                        />
                      );
                    })}
                </TableBody>
              </Table>

              <MuiTablePagination
                count={filtered2?.length}
                rowsPerPage={rowsPerPage2}
                page={currentPage2}
                setCurrentPage={setCurrentPage2}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage2(e.target.value);
                  setCurrentPage2(0);
                }}
              />
            </TableContainer>
          </Box>
        )}
      </Box>

      <ExportDonationModal
        open={exportModal}
        onClose={() => setExportModal(false)}
      />

      <DonationMatchModal
        open={donationMatchModal}
        onClose={() => setDonationMatchModal(false)}
        selectedDonations={selectedDonations}
        matchPercentage={selectedOrg?.matchPercentage}
        onApprove
      />

      <EmployeeMatchModal open={false} />
    </>
  );
};

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 999,
  },
  container: {
    height: "100%",
    width: "100%",
    paddingTop: "80%",
    alignItems: "center",
  },
});
