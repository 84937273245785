import React from "react";
import { Box, ButtonBase, Radio } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import styled from "@emotion/styled";

const StyledButtonBase = styled(ButtonBase)({
  textAlign: "left",
  marginTop: 20,
  width: "100%",
});

export default function TypeCard({ icon, title, description, value, onClick }) {
  const active = value === title;

  return (
    <StyledButtonBase onClick={() => onClick?.(title)}>
      <Box
        minHeight={83}
        height="100%"
        border={`1px solid ${
          active ? COLORS.primary.lightBlue : COLORS.primary.neutral_600
        }`}
        borderRadius={3}
        pl={22}
        pr={6}
        pb={6}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        bgcolor={active ? COLORS.primary.blue_100 : COLORS.primary.white}
        width="100%"
      >
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            mt={11}
            mb={4}
          >
            {icon}

            <PrimaryText
              fontSize={17}
              fontWeight={900}
              style={{ marginLeft: 7 }}
            >
              {title}
            </PrimaryText>
          </Box>

          <PrimaryText color={COLORS.primary.neutral_800}>
            {description}
          </PrimaryText>
        </Box>

        <Radio
          size="large"
          checked={active}
          sx={{
            "& .MuiSvgIcon-root": {
              fontSize: 24,
            },
          }}
        />
      </Box>
    </StyledButtonBase>
  );
}
