import { ArrowForwardIos } from "@mui/icons-material";
import { Box } from "@mui/material";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { Cause } from "components/CausesSkills/SelectCauses";
import EditableAddress from "components/Common/EditableAddress";
import MuiEditButton from "components/Common/MuiEditButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { QnAEdit } from "components/Common/QnAEdit";
import DonationInputs from "components/Inputs/DonationInputs";
import { ConfirmModal2 } from "components/Modals/Confirmation2";
import SelectCausesModal from "components/Modals/SelectCausesModal";
import UploadPhotoModal from "components/Modals/UploadPhotoModal";
import EditablePic from "components/Pics/EditablePic";
import { COLORS } from "constants/Colors";
import { causes } from "constants/Data";
import { useAuth } from "context/AuthContext";
import { useOrganization } from "context/OrganizationContext";
import React, { useEffect, useState } from "react";
import { ActivityIndicator, useWindowDimensions } from "react-native-web";
import {
  updateOrganization,
  uploadPhoto,
} from "services/organizations-service";
import { functions } from "utils/firebase";
import validate from "utils/validation";

export function Settings() {
  const { queryParams, setQueryParams } = useAuth();
  const { selectedOrg, stripeConnectAccount } = useOrganization();
  const [editing, setEditing] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false);

  const { height } = useWindowDimensions();

  useEffect(() => {
    if (queryParams.get("code")) {
      const code = queryParams.get("code");
      // console.log('code', code)
      functions
        .httpsCallable("handleOAuthForConnect")({
          code,
          orgId: queryParams.get("state"),
        })
        .then((response) => {
          // console.log("response", response)
          const urlParams = new URLSearchParams(window.location.search);
          setQueryParams(urlParams);
        })
        .catch((err) => {
          // console.log("handleOAuthForConnect Error: ", err);
          window.alert(
            `Error setting up Stripe account. Please try again. ${err.message}`
          );
          const urlParams = new URLSearchParams(window.location.search);
          setQueryParams(urlParams);
        });
    }
  }, [queryParams]);

  // console.log(Object.fromEntries(queryParams))

  const url = `https://dev.donate.rayzeapp.com/orgs/${selectedOrg?.id}`;

  const baseUrl = "https://dev.donate.rayzeapp.com/";

  const handleSave = async (key, value) => {
    try {
      if (["causes", "address", "customDonationAmounts"].includes(key))
        console.log("Doesn't required validation.");
      else if (key === "adminEmail")
        validate({ value, type: "email", name: key });
      else if (key === "website") validate({ value, type: "url", name: key });
      else if (key === "theme.primaryColor") {
        validate({ value, type: "color", name: key });
      } else if (key === "theme.secondaryColor")
        validate({ value, type: "color", name: key });
      else validate({ value, type: key });

      if (value === "Yes" || value === "No") value = value === "Yes";

      let data = {
        [key]: value,
      };

      if (key === "address") data = { ...value };

      setLoading(true);

      updateOrganization(
        selectedOrg,
        data,
        key === "coverPhotos" ? [value] : [],
        [],
        onSuccessUpdate
      );
    } catch (err) {
      if (err?.name) setErrors({ [err?.name]: err.message });
      console.log(err);
    }
  };

  const onSuccessUpdate = () => {
    if (errors) setErrors(null);
    setLoading(false);
    setEditing(null);
  };

  // Handle upload cover, profile and logo images
  const handleUpload = (image, type) => {
    setLoading(true);
    uploadPhoto({ orgId: selectedOrg?.id, imageUrl: image, type })
      .then((result) => console.log(result))
      .catch((err) => console.log(err))
      .finally((_) => {
        setLoading(false);
        setEditing(null);
      });
  };

  const handleCancel = () => {
    setEditing(null);
    if (errors) setErrors(null);
  };

  let stripeStatus =
    stripeConnectAccount?.requirements?.currently_due?.length > 0
      ? "Incomplete"
      : !selectedOrg?.stripeID
      ? "Not Started"
      : "Pending";
  if (stripeConnectAccount?.charges_enabled)
    stripeStatus = "Accepting Donations";

  const handleSetUpStripe = () => {
    if (!selectedOrg) return;

    // if (selectedOrg?.stripeComplete) {
    // 	//open stripe in new tab
    // 	return window.open(
    // 		`https://dashboard.stripe.com`,
    // 		"_blank"
    // 	);
    // }

    setLoading(true);

    if (!selectedOrg?.stripeID) {
      const client_id = "ca_JgPZjFUDLRz06BNUY6GdOEAyBtNZViSr";
      // const url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write`
      const url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${client_id}&scope=read_write&state=${selectedOrg.id}&redirect_uri=https://dev.org.rayzeapp.com`;
      // console.log("url", url);
      return (window.location = url);
    }

    let data = { ...selectedOrg };
    data.redirect = `https://dev.org.rayzeapp.com?state=${selectedOrg.id}`;
    const stringifiedOrg = JSON.stringify(data);

    functions
      .httpsCallable("getAccountLink")(stringifiedOrg)
      .then((response) => {
        setLoading(false);
        // console.log("test", response)
        const { url: uri, statusCode } = response.data;
        if (uri && !statusCode) {
          return (window.location = uri);
        } else {
          console.log("Error getting account setup link: ", response);
        }
      })
      .catch((err) => {
        console.log("handleSetUpStripe Error: ", err);
        setLoading(false);
      });
  };

  // console.log('stripeConnectAccount', JSON.stringify(stripeConnectAccount, null, 2));

  // console.log('stripeStatus', selectedOrg?.stripeID, stripeStatus);

  return (
    <Box
      width="100%"
      minHeight="80lvh"
      pl={{ xs: 0, md: 26 }}
      pr={{ xs: 12, md: 0 }}
      pt="20px"
      pb={100}
    >
      {loading && (
        <ActivityIndicator
          size="large"
          color={COLORS.primary.lightBlue}
          style={{
            position: "absolute",
            width: "100%",
            height: height,
            backgroundColor: "white",
            opacity: 0.8,
            zIndex: 99999,
          }}
        />
      )}

      {/* Start donation settings */}
      <PrimaryText fontSize={24} fontWeight={400}>
        Donation Settings
      </PrimaryText>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        mb={25}
        mt={18}
      >
        <Box
          display="flex"
          flexDirection="column"
          mr={{ md: 85 }}
          mb={{ xs: 24, md: 0 }}
        >
          <PrimaryText fontSize={17} fontWeight={600}>
            Stripe Account
          </PrimaryText>
          {stripeConnectAccount && stripeConnectAccount?.email && (
            <Box display="flex" flexDirection="column">
              <PrimaryText
                fontSize={17}
                fontWeight={400}
                style={{ marginTop: 8 }}
              >
                {"Connected Account Email: "}(
                <PrimaryText fontSize={17} fontWeight={600}>
                  {stripeConnectAccount?.email}
                </PrimaryText>
                )
              </PrimaryText>

              <PrimaryButton
                title={"Unlink Stripe Account from Rayze"}
                style={{
                  flex: 0,
                  marginTop: 8,
                  padding: 12,
                  maxWidth: 320,
                  backgroundColor: "transparent",
                  borderColor: COLORS.primary.error,
                  borderWidth: 2,
                  height: undefined,
                }}
                titleStyle={{ color: COLORS.primary.error, marginTop: 1 }}
                // onChange={handleSetUpStripe}
                onPress={() => setUnlinkModalOpen(true)}
              />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            // mt={8}
            gap={8}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <PrimaryText
                fontSize={17}
                fontWeight={400}
                color={COLORS.primary.neutral_800}
                style={{ marginTop: 4 }}
              >
                {"Connected Account Status: "}
                <PrimaryText
                  fontSize={17}
                  fontWeight={700}
                  color={
                    stripeStatus === "Accepting Donations"
                      ? COLORS.primary.green
                      : stripeStatus === "Pending"
                      ? COLORS.primary.orange
                      : COLORS.primary.error
                  }
                >
                  {stripeStatus}
                </PrimaryText>
              </PrimaryText>
              {stripeConnectAccount?.requirements?.currently_due?.length >
                0 && (
                <PrimaryText
                  fontSize={17}
                  fontWeight={700}
                  color={COLORS.primary.error}
                >
                  Action Required - need more info
                </PrimaryText>
              )}
            </Box>
          </Box>

          <MuiEditButton
            startIcon={null}
            endIcon={<ArrowForwardIos fill={COLORS.primary.white} />}
            title={`Manage Stripe Account`}
            style={{
              // flex: 1,
              padding: 12,
              justifyContent: "center",
              alignItems: "center",
              width: 291,
              height: 48,
              borderRadius: 12,
              fontWeight: 900,
              fontSize: 16,
              marginTop: 14,
              backgroundColor: "#635BFF",
            }}
            onChange={handleSetUpStripe}
          />
        </Box>
        <DonationInputs
          isEditing={editing}
          onEdit={(property) => setEditing(property)}
          property="customDonationAmounts"
          onCancel={handleCancel}
          onSave={handleSave}
          donations={selectedOrg?.customDonationAmounts}
        />
      </Box>
      {/* End donation settings */}

      {/* Start profile settings */}
      {loading && (
        <ActivityIndicator
          size="large"
          color={COLORS.primary.lightBlue}
          style={{
            position: "absolute",
            width: "100%",
            height: height,
            backgroundColor: "white",
            opacity: 0.8,
            zIndex: 99999,
          }}
        />
      )}

      <PrimaryText fontSize={24} fontWeight={400}>
        Profile Settings
      </PrimaryText>

      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        gap={{ xs: 20, md: 50, lg: 80 }}
        alignItems={{ md: "center" }}
        mt={18}
      >
        <EditablePic
          imageSrc={selectedOrg?.coverPhoto}
          label="Cover Photo"
          onChange={() => setEditing("coverPhoto")}
        />
        <EditablePic
          imageSrc={selectedOrg?.profilePic}
          label="Profile Photo"
          square
          onChange={() => setEditing("profilePhoto")}
        />
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        mt={25}
        gap={{ xs: 20, md: 50 }}
      >
        {/* Left */}
        <Box display="flex" flexDirection="column">
          <QnAEdit
            required={true}
            type="text"
            question="Organization Name"
            answer={selectedOrg?.name}
            property="name"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            errorMessage={errors?.name}
          />

          <EditableAddress
            required={true}
            title="Mailing Address (Will be displayed on map)"
            selectedAddress={selectedOrg?.address}
            property="address"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
          />

          <QnAEdit
            required={true}
            type="text"
            question="Admin Email"
            answer={selectedOrg?.adminEmail}
            property="adminEmail"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            errorMessage={errors?.adminEmail}
          />

          <QnAEdit
            required={true}
            type="text"
            question="Phone Number"
            answer={selectedOrg?.phone}
            property="phone"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            errorMessage={errors?.phone}
          />
        </Box>
        {/* Right */}
        <Box display="flex" flexDirection="column">
          <QnAEdit
            required={true}
            type="text"
            question="Employer Identification Number (EIN)"
            answer={selectedOrg?.ein}
            property="ein"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            errorMessage={errors?.ein}
          />

          <QnAEdit
            required={true}
            type="desc"
            question="About (500 character max)"
            answer={selectedOrg?.about}
            property="about"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            maxWidth={500}
          />

          <QnAEdit
            required={true}
            type="text"
            question="Website URL"
            answer={selectedOrg?.website}
            property="website"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            errorMessage={errors?.website}
          />

          {/* Causes */}

          <PrimaryText fontSize={17} fontWeight={600}>
            Causes (Limit 5)
          </PrimaryText>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            flexWrap="wrap"
            mt={12}
            gap={16}
          >
            {selectedOrg?.causes?.map((causeValue, index) => {
              let causeObj = causes.find((cause) => cause.value === causeValue);
              if (!causeObj) {
                causeObj = causes.find((cause) => cause.rayze === causeValue);
              }
              if (!causeObj) return null;

              return <Cause key={index} {...causeObj} isActive={true} />;
            })}
          </Box>
          <MuiEditButton onChange={() => setEditing("causes")} />
        </Box>
      </Box>
      {/* End profile settings */}

      <Box width="70%" height={3} bgcolor={COLORS.primary.black} my={40} />

      <PrimaryText fontSize={24}>Direct Donation Theme Settings</PrimaryText>

      {/* Brand */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        mt={16}
        gap={{ xs: 20, md: 50, lg: 100 }}
      >
        <Box>
          <QnAEdit
            required={false}
            type="color"
            question="Primary Brand Color"
            answer={
              selectedOrg?.theme?.primaryColor || COLORS.primary.lightBlue
            }
            property="theme.primaryColor"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            errorMessage={errors?.primaryColor}
          />
          <QnAEdit
            required={false}
            type="color"
            question="Header Background Color"
            answer={
              selectedOrg?.theme?.secondaryColor || COLORS.primary.darkBlue
            }
            property="theme.secondaryColor"
            onEdit={(property) => setEditing(property)}
            editing={editing}
            onSave={handleSave}
            onCancel={handleCancel}
            errorMessage={errors?.theme?.secondaryColor}
          />
        </Box>

        <Box>
          <EditablePic
            imageSrc={selectedOrg?.theme?.logo}
            label="Logo"
            square={true}
            onChange={() => setEditing("theme.logo")}
          />
        </Box>
      </Box>

      {/* Social Links */}

      <Box display="flex" flexDirection="column" mt={{ xs: 20, md: 0 }}>
        <QnAEdit
          required={false}
          type="text"
          question="Facebook"
          answer={selectedOrg?.facebook || "https://www.facebook.com/"}
          property="facebook"
          onEdit={(property) => setEditing(property)}
          editing={editing}
          onSave={handleSave}
          onCancel={handleCancel}
          errorMessage={errors?.facebook}
        />
        <QnAEdit
          required={false}
          type="text"
          question="Instagram"
          answer={selectedOrg?.instagram || "https://www.instagram.com/"}
          property="instagram"
          onEdit={(property) => setEditing(property)}
          editing={editing}
          onSave={handleSave}
          onCancel={handleCancel}
          errorMessage={errors?.instagram}
        />
        <QnAEdit
          required={false}
          type="text"
          question="LinkedIn"
          answer={selectedOrg?.linkedin || "https://www.linkedin.com/"}
          property="linkedin"
          onEdit={(property) => setEditing(property)}
          editing={editing}
          onSave={handleSave}
          onCancel={handleCancel}
          errorMessage={errors?.linkedin}
        />
        <QnAEdit
          required={false}
          type="text"
          question="X (formerly Twitter)"
          answer={selectedOrg?.twitter || "https://twitter.com/"}
          property="twitter"
          onEdit={(property) => setEditing(property)}
          editing={editing}
          onSave={handleSave}
          onCancel={handleCancel}
          errorMessage={errors?.twitter}
        />
      </Box>

      <Box display="flex" flexDirection="column" mt={34}>
        <PrimaryText fontSize={24} fontWeight={400}>
          View Direct Donation Link
        </PrimaryText>

        <a
          href={selectedOrg?.customUrl ? baseUrl + selectedOrg?.customUrl : url}
          target="_blank"
          rel={"noreferrer"}
        >
          <PrimaryText
            fontSize={16}
            fontWeight={400}
            style={{ color: COLORS.primary.lightBlue, marginTop: 7 }}
          >
            {selectedOrg?.customUrl ? baseUrl + selectedOrg?.customUrl : url}
          </PrimaryText>
        </a>
      </Box>

      {editing === "coverPhoto" && (
        <UploadPhotoModal
          onCancel={handleCancel}
          isOpen={true}
          title="Cover Photo"
          onSave={(image) => handleUpload(image, "cover")}
          loading={loading}
        />
      )}
      {editing === "profilePhoto" && (
        <UploadPhotoModal
          onCancel={handleCancel}
          isOpen={true}
          square={true}
          title="Profile Photo"
          onSave={(image) => handleUpload(image, "profile")}
          loading={loading}
        />
      )}
      {editing === "theme.logo" && (
        <UploadPhotoModal
          onCancel={handleCancel}
          isOpen={true}
          // square={true}
          freeform={true}
          title="Logo"
          onSave={(image) => handleUpload(image, "theme.logo")}
          loading={loading}
        />
      )}

      {editing === "causes" && (
        <SelectCausesModal
          isOpen={true}
          onCancel={() => setEditing(null)}
          onSave={(selectedCauses) => handleSave("causes", selectedCauses)}
          causes={selectedOrg?.causes}
          loading={loading}
        />
      )}

      <ConfirmModal2
        title="Are you sure you want to unlink your Stripe account from Rayze?"
        onConfirm={() => {
          updateOrganization(
            selectedOrg,
            { stripeID: null, stripeComplete: false },
            [],
            [],
            () => {
              setUnlinkModalOpen(false);
            }
          );
        }}
        onCancel={() => setUnlinkModalOpen(false)}
        onConfirmText={"Yes, unlink"}
        open={unlinkModalOpen}
      />
    </Box>
  );
}
