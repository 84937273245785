import React, { useState, useEffect, useMemo } from "react";
import { StyleSheet, View } from "react-native";
// import FormInput from "components/Partials/FormInput"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
// import { useDashboard } from "context/DashboardContext"
import { useOrganization } from "context/OrganizationContext";
import { OrgCard } from "components/Cards/OrgCard";
import { useNavigate } from "react-router";
import { Links } from "constants/Links";
import * as FaIcons from "react-icons/fa";
import { TouchableOpacity } from "react-native-web";
import { searchOrg } from "services/businesses/business-nonprofit-service";
import { useBusinessNonprofits } from "context/Business/BusinessNonprofitsContext";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { useCauses } from "context/CausesContext";
import { DonateModal } from "components/Modals/DonateModal";
import { causes } from "constants/Data";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";

const pageSize = 10;

export const Nonprofits = () => {
  const navigate = useNavigate();
  // const { rayzeStats } = useDashboard()
  const {
    orgs = [],
    allNonprofits,
    setGetOrgs,
    selectedNonprofit,
    setSelectedNonprofit,
    nextPage,
    page,
    setPage,
    setGetNonprofits
  } = useBusinessNonprofits();
  const { employeeMatchDonations } = useBusinessDonations();
  const { allCauses } = useCauses();

  const [nonProfits, setNonprofits] = useState(orgs);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState({
    verification: "",
    cause: "",
  });
  const [filteredNonprofits, setFilteredNonprofits] = useState(nonProfits);

  const [showDonateModal, setShowDonateModal] = useState(false);

  useEffect(() => {
    // setGetNonprofits(true);
    setGetOrgs(true);
  }, []);

  useEffect(() => {
    if (search) {
      setPage(1);
      searchOrg(search, setNonprofits, causes);
    } else {
      setNonprofits(orgs);
    }
  }, [search, orgs]);

  function onFilter(key, value) {
    setFilter((prev) => ({ ...prev, [key]: value }));
  }

  function onOrgSelected(org) {
    setSelectedNonprofit(org);
    navigate(Links.NonprofitDetail);
  }

  function donateSelected(org) {
    setSelectedNonprofit(org);
    setShowDonateModal(true);
  }

  const currentTableData = () => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return filteredNonprofits.slice(firstPageIndex, lastPageIndex);
  };

  useEffect(() => {
    console.log('nonProfits', nonProfits?.length)
    const filtered = nonProfits.filter((item) => {
      // Search by text
      const searched = item.name.toLowerCase().includes(search.toLowerCase());

      // Filter by cause
      console.log('filter', filter) 
      const filterCause = filter?.cause
        ? item.causes.includes(filter?.cause)
        : true;

      // Filter by verification
      const filterVerification = filter?.verification
        ? item?.verified == filter?.verification
        : filter?.verification === false
        ? !item?.verified
        : true;

      return searched === filterCause && filterCause === filterVerification;
    });

    setFilteredNonprofits(filtered);
  }, [search, nonProfits, filter]);

  const totalCount = search ? nonProfits?.length : filteredNonprofits?.length;
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startCount = (page - 1) * pageSize + 1;
  const endCount = startCount + currentTableData()?.length - 1;

  return (
    <View style={{ flex: 1, width: "100%" }}>
      <ShadowContainer style={styles.container}>
        <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
          Nonprofits
        </PrimaryText>

        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems="center"
          flexGrow={1}
          gap={24}
        >
          <CustomInput
            placeholder="Search"
            style={{ width: "100%", maxWidth: 400 }}
            backgroundColor={COLORS.primary.white}
            value={search}
            onChangeText={(text) => setSearch(text)}
          />

          <Box display="flex" flexDirection="row" alignItems="center" gap={14}>
            <PrimaryText
              fontSize={14}
              fontWeight={900}
              style={{
                color: COLORS.primary.darkGray,
              }}
            >
              Filter
            </PrimaryText>
            <CustomPicker
              placeholder="All Causes"
              style={{ maxWidth: 170 }}
              backgroundColor={COLORS.primary.white}
              options={[
                {
                  name: "All Causes",
                  all: null,
                },
              ]
                .concat(allCauses)
                .map((item) => ({
                  label: item.name,
                  value: item.all !== undefined ? item.all : item.name,
                }))}
              onChange={(e) => onFilter("cause", e)}
              value={filter?.cause}
            />
          </Box>
        </Box>

        <div className="flex-between-center">
          <div className="d-flex align-items-center w-60">
            <div className="flex-1"></div>
          </div>

          {/* <CSVLink
          {...csvReport}
          className="app-button btn-primary wh-unset p-10"
          style={{ backgroundColor: "#1DA1F2" }}
        >
          Export CSV
        </CSVLink> */}
        </div>
        <TableContainer>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
                <TableCell>VERIFICATION</TableCell>
                <TableCell>CAUSES</TableCell>
                <TableCell>DONATIONS</TableCell>
                <TableCell />
                {/* <TableCell>VOLUNTEER HOURS</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {currentTableData() &&
                currentTableData().map((nonprofit, i) => {
                  //find sum of donations
                  const sum = employeeMatchDonations
                    .filter((donation) => donation.orgId === nonprofit.id)
                    .reduce((acc, donation) => acc + donation.amount, 0);
                  return (
                    <OrgCard
                      nonprofit={nonprofit}
                      key={nonprofit.id}
                      onPress={() => onOrgSelected(nonprofit)}
                      onDonate={() => donateSelected(nonprofit)}
                      sum={sum}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <View
          style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
        >
          <TouchableOpacity
            onPress={() => (page > 1 ? setPage(page - 1) : null)}
          >
            <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkGray} />
          </TouchableOpacity>

          <PrimaryText fontSize={14} fontWeight={400} style={styles.subtitle}>
            {`${startCount} - ${endCount} of ${totalCount}`}
          </PrimaryText>

          <TouchableOpacity
            onPress={() => (page < totalPageCount ? nextPage() : null)}
          >
            <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkGray} />
          </TouchableOpacity>
        </View>
      </ShadowContainer>

      {showDonateModal && (
        <DonateModal
          isVisible={showDonateModal}
          setIsVisible={setShowDonateModal}
          selectedNonprofit={selectedNonprofit}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    height: "100%",
    width: "100%",
  },
  title: {
    color: COLORS.primary.darkBlue,
  },
  header: {
    color: COLORS.primary.darkBlue,
  },
  subtitle: {
    color: COLORS.primary.darkBlue,
    marginHorizontal: 24,
  },
});
