import React from "react";

import { Links } from "constants/Links";
import { Image } from "react-native-web";
import { COLORS } from "constants/Colors";
import { GoTrophy } from "react-icons/go";
import {
  FiHome,
  FiDollarSign,
  FiCalendar,
  FiUsers,
  FiSettings,
  FiUser,
} from "react-icons/fi";
import { PiQrCode } from "react-icons/pi";
import { IoMdArrowDropright } from "react-icons/io";
import { BiDonateHeart } from "react-icons/bi";
import { LuWalletCards } from "react-icons/lu";

export const SidebarData = [
  {
    title: "Dashboard",
    path: Links.Dashboard,
    icon: <FiHome size={22} />,
  },
  {
    title: "Donations",
    path: Links.Donations,
    icon: <FiDollarSign size={22} />,

    detailedNav: [
      {
        title: "Donor Details",
        path: Links.DonorDetails,
        icon: <FiCalendar size={22} />,

        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Champions",
    path: Links.Fundraising,
    icon: <GoTrophy size={22} />,
    detailedNav: [
      {
        title: "Fundraiser Details",
        path: Links.FundraisingDetail,
        icon: <FiCalendar size={22} />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Custom Links",
    path: Links.CustomLinks,
    icon: <PiQrCode size={25} />,
    detailedNav: [
      {
        title: "Custom Link Details",
        path: Links.CustomLinkDetails,
        icon: <IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Events",
    path: Links.Events,
    icon: <FiCalendar size={22} />,
    detailedNav: [
      {
        title: "Event Detail",
        path: Links.EventDetail,
        icon: <IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Team",
    path: Links.Team,
    icon: <FiUsers size={22} />,
  },
  {
    title: "General Settings",
    path: Links.Settings,
    icon: <FiSettings size={22} />,
  },
  {
    title: "Verification",
    path: Links.Verification,
    icon: (
      <Image
        source={require("assets/verification.png")}
        style={{ width: 22, height: 22, tintColor: COLORS.primary.lightBlue }}
      />
    ),
  },
  // {
  //   title: 'Opportunities',
  //   path: Links.Opportunities,
  //   icon: <FaIcons.FaChartLine />
  // },
  // {
  //   title: 'Reports',
  //   icon: <IoIosPaper />,

  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,

  //   subNav: [

  //     {
  //       title: 'Donations',
  //       path: Links.InventoryLocations,
  //       icon: <IoMdArrowDropright />
  //     },
  //   ]
  // },
  // {
  //   title: 'Support',
  //   path: Links.Support,
  //   icon: <IoMdHelpCircle />
  // }
];

export const BusinessSidebarData = [
  {
    title: "Dashboard",
    path: Links.BusinessDashboard,
    icon: <FiHome size={22} />,
  },
  {
    title: "Rayze Wallet",
    path: Links.RayzeWallet,
    icon: <LuWalletCards size={22} />,
  },
  {
    title: "Donations",
    path: Links.BusinessDonations,
    icon: <FiDollarSign size={22} />,
  },
  // {
  //   title: "Volunteers",
  //   path: Links.BusinessVolunteers,
  //   icon: <FiIcons.FiUsers size={22} />,
  // },
  {
    title: "Campaigns",
    path: Links.BusinessCampaigns,
    icon: <FiCalendar size={22} />,
    detailedNav: [
      {
        title: "Campaign Details",
        path: Links.BusinessCampaignDetails,
        icon: <FiCalendar size={22} />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Nonprofits",
    path: Links.Nonprofits,
    icon: <BiDonateHeart size={22} />,
    detailedNav: [
      {
        title: "Nonprofit Detail",
        path: Links.NonprofitDetail,
        icon: <IoMdArrowDropright />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Employees",
    path: Links.BusinessEmployees,
    icon: <FiUser size={22} />,
    detailedNav: [
      {
        title: "Employee Details",
        path: Links.BusinessEmployeeDetail,
        icon: <FiUser size={22} />,
      },
    ],
  },
  {
    title: "General Settings",
    path: Links.GeneralSettings,
    icon: <FiSettings size={22} />,
  },
  {
    title: "Verification",
    path: Links.Verification,
    icon: (
      <Image
        source={require("assets/verification.png")}
        style={{ width: 22, height: 22, tintColor: COLORS.primary.lightBlue }}
      />
    ),
  },
];
