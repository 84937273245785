import { Box, ButtonBase, IconButton, Modal } from "@mui/material";
import { Close } from "assets/svgs";
import PrimaryButton from "components/Buttons/PrimaryButton";
import PaymentMethodPickerCard from "components/Cards/PaymentMethodPickerCard";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React, { useState } from "react";
import { LuCirclePlus } from "react-icons/lu";
import { updateDefaultPaymentMethod } from "services/stripe-service";
import { ActivityIndicator } from "react-native-web";

const RAYZE_AMOUNT = 10_801;

export default function PaymentMethodPickerModal({
  isVisible,
  onClose,
  modalTitle,
  boxProps,
  paymentMethods,
  defaultPaymentMethod,
  onAdd,
  selectedOrg,
  fetchPaymentMethods,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(defaultPaymentMethod);

  function makeDefaultPressed() {
    setLoading(true);
    paymentMethods.forEach((method) => {
      if (method.id === selectedPaymentMethod?.id) {
        method.isDefault = true;
      } else {
        method.isDefault = false;
      }
    });
    updateDefaultPaymentMethod(
      selectedPaymentMethod?.id,
      selectedOrg?.stripeCustomerId,
      onSuccess
    );
  }

  async function onSuccess() {
    // setLoading(true);
    await fetchPaymentMethods();
    setLoading(false);
    onClose();
    // setLoading(false);
  }

  return (
    <>
      <Modal
        open={isVisible}
        onClose={onClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          width={{ base: 438 }}
          pt={30}
          pb={30}
          bgcolor={COLORS.primary.neutral_100}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          borderRadius={3}
          position="relative"
          px={40}
          boxSizing="border-box"
        >
          {loading && (
            <ActivityIndicator
              size="large"
              color={COLORS.primary.darkGray}
              style={{
                marginTop: 20,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              animating={loading}
            />
          )}
          <IconButton
            sx={{ position: "absolute", top: 16, right: 20 }}
            onClick={onClose}
          >
            <Close fill={COLORS.primary.gray} />
          </IconButton>

          <PrimaryText
            fontSize={24}
            fontWeight={900}
            style={{ textAlign: "center", width: 300, marginLeft: 20 }}
          >
            {modalTitle || "Payment Method"}
          </PrimaryText>

          <Box mt={20} {...boxProps}>
            {/* <PrimaryText
						fontSize={16}
						fontWeight={900}
					>
						{title || "Payment Method"}
						{required && <span style={{ color: COLORS.primary.error }}>*</span>}
					</PrimaryText> */}
            <Box ml={20}>
              {paymentMethods?.length &&
                paymentMethods.map((item, index) => (
                  <PaymentMethodPickerCard
                    key={index}
                    item={item}
                    selectable={true}
                    active={item.id === selectedPaymentMethod?.id}
                    onChange={() => setSelectedPaymentMethod(item)}
                  />
                ))}

              <ButtonBase
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "flex-start",
                  gap: 8,
                  mt: 18,
                }}
                disableRipple
                onClick={onAdd}
              >
                <LuCirclePlus color={COLORS.primary.lightBlue} size={20} />
                <PrimaryText
                  fontSize={16}
                  fontWeight={900}
                  color={COLORS.primary.lightBlue}
                >
                  Add New
                </PrimaryText>
              </ButtonBase>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={44}
            mt={30}
          >
            <PrimaryButton
              title="Cancel"
              variant="outlined"
              onPress={onClose}
              style={{
                borderRadius: 20,
                paddingHorizontal: 24,
                marginBottom: 0,
              }}
            />
            <PrimaryButton
              title="Confirm"
              style={{
                borderRadius: 20,
                paddingHorizontal: 24,
                marginBottom: 0,
              }}
              onPress={makeDefaultPressed}
              disabled={defaultPaymentMethod?.id === selectedPaymentMethod?.id}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
