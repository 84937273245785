import React, { useRef, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  Dimensions,
  Image,
  TouchableOpacity,
} from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useOrganization } from "context/OrganizationContext";
import { dollarFormatter, formatShortNumber, getDecimalFromNumber, getIntegerFromNumber } from "utils/helpers";
import * as FiIcons from "react-icons/fi";
import { useBusinessCampaigns } from "context/Business/BusinessCampaignsContext";
import LinearProgressWithLabel from "components/Progress/LinearProgressWithLabel";
import { useBusinessDonations } from "context/Business/BusinessDonationsContext";
import { BusinessTopDonorCard } from "components/Cards/Business/BusinessTopDonorCard";
import { BusinessTopCausesCard } from "components/Cards/Business/BusinessTopCausesCard";
import { causes } from "constants/Data";
import useMediaQuery, { breakPoints } from "hooks/useMediaQuery";
import { Box, Stack } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import * as FaIcons from "react-icons/fa";
import { ScrollView, useWindowDimensions } from "react-native-web";

const { height } = Dimensions.get("window");
const rayzeIcon = require("assets/misc/default_org_dp.png")

export function BusinessDashboard() {
  const { selectedOrg, userType } = useOrganization();
  const { getResponsiveValue } = useMediaQuery();
  const { activeCampaigns, activeCampaign } = useBusinessCampaigns();
  const {
    topDonors,
    topNonprofits,
    topCauses,
    totalDonationsYTD,
    totalMatchedDonationsYTD,
    totalNonprofitsDonatedToYTD,
    rayzeAccountBalance,
    selectedMonth,
    setSelectedMonth,
  } = useBusinessDonations();

  const width = useWindowDimensions().width;
  const isMobile = width < breakPoints.bp_md;

  const campaignCarouselRef = useRef();

  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const styles = getStyles(getResponsiveValue);

  const sliderSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: breakPoints.bp_xs,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakPoints.bp_sm,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakPoints.bp_md,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakPoints.bp_lg,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakPoints.bp_xl,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: breakPoints.bp_xxl,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ]
  }

  const thisYearName = selectedMonth.getFullYear();
  const isThisYearSelected =
    selectedMonth.getFullYear() === new Date().getFullYear();

  return (
    <>
      {!selectedOrg || loading ? (
        <View style={{ flexGrow: 1, height: height - 100 }}>
          <ActivityIndicator
            animating
            size={"large"}
            style={styles.activity}
            color={COLORS.primary.lightBlue}
          />
        </View>
      ) : (
        <View style={styles.container}>
          <PrimaryText fontSize={24} fontWeight={800} style={styles.header}>
            Dashboard
          </PrimaryText>

          {/** ROW 1 */}
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={24}
            alignItems="center"
            mt={24}
          >
            <StatsCard
              title="Rayze Wallet Balance"
              value={rayzeAccountBalance}
              dollar={true}
              styles={styles}
            />

            <StatsCard
              title="Total Donations YTD"
              value={totalDonationsYTD}
              dollar={true}
              styles={styles}
              color="green"
            />

            <StatsCard
              title={`Total Matched\nDonations YTD`}
              value={totalMatchedDonationsYTD}
              dollar={true}
              styles={styles}
              color="green"
            />

            <StatsCard
              title={`Total Nonprofits\nSupported YTD`}
              value={totalNonprofitsDonatedToYTD}
              styles={styles}
            />
          </Box>

          <Box
            mt={36}
            bgcolor={COLORS.primary.neutral}
            boxShadow="5px 5px 15px 0px #80808026"
            borderRadius={3}
            padding={{ xs: 12, md: 24 }}
          >
            <Stack direction="row" alignItems="flex-end" gap={14}>
              <PrimaryText fontSize={32} fontWeight={400}>
                Stats
              </PrimaryText>

              <View
                style={{
                  flexDirection: "row",
                  // alignItems: "center",
                  marginLeft: 12,
                }}
              >
                {/* <TouchableOpacity
                  onPress={() => {
                    //set selected year to previous year
                    setSelectedMonth(
                      new Date(
                        selectedMonth.setFullYear(selectedMonth.getFullYear() - 1)
                      )
                    );
                  }}
                >
                  <FaIcons.FaChevronLeft
                    size={16}
                    color={COLORS.primary.lightBlue}
                  />
                </TouchableOpacity> */}

                <PrimaryText
                  fontSize={20}
                  fontWeight={900}
                  style={{ marginHorizontal: 4, marginBottom: 2 }}
                  color={COLORS.primary.lightBlue}
                >
                  {thisYearName} YTD
                </PrimaryText>

                {/* <TouchableOpacity
                  disabled={isThisYearSelected}
                  onPress={() => {
                    //set selected year to previous year
                    setSelectedMonth(
                      new Date(
                        selectedMonth.setFullYear(selectedMonth.getFullYear() + 1)
                      )
                    );
                  }}
                  style={{ opacity: isThisYearSelected ? 0.4 : 1 }}
                >
                  <FaIcons.FaChevronRight
                    size={16}
                    color={COLORS.primary.lightBlue}
                  />
                </TouchableOpacity> */}
              </View>
            </Stack>

            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ overflow: "hidden" }}
            >
              <Stack
                direction="row"
                alignItems="center"
                mt={24}
                gap={20}
                flexWrap="wrap"
              >
                <Stack gap={20} flex={1}>
                  {CARDS_DATA.map((item, index) => (
                    <StatsCardOutlined {...item} key={index} />
                  ))}
                </Stack>

                {/* Top Causes */}
                <Stack
                  padding={20}
                  bgcolor={COLORS.primary.white}
                  borderRadius={3}
                  border="1px solid #C4C4C4"
                  minWidth={190}
                  flex={1}
                  height={416}
                  justifyContent="space-between"
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={styles.cardTitle}
                  >
                    {`Top Causes`}
                  </PrimaryText>
                  {!topCauses?.length > 0 ? (
                    <View
                      style={[
                        styles.center,
                        { transform: [{ rotate: "90deg" }] },
                      ]}
                    >
                      <FiIcons.FiBarChart2
                        color={COLORS.primary.neutral_300}
                        style={{
                          minHeight: 100,
                          maxHeight: 160,
                          minWidth: 100,
                          maxWidth: 160,
                        }}
                      />
                    </View>
                  ) : (
                    <View style={{ flex: 1 }}>
                      {topCauses &&
                        topCauses.map((item, index) => {
                          let name = "Old Cause";
                          const cause = causes?.find(
                            (x) =>
                              x.value === item.causeId || x.rayze === item.causeId
                          );
                          if (cause) name = cause.name;

                          return (
                            <BusinessTopCausesCard
                              key={item.causeId}
                              item={item}
                              index={index}
                              name={name}
                            />
                          );
                        })}
                    </View>
                  )}
                </Stack>

                {/* Top Employees */}
                <Stack
                  padding={20}
                  bgcolor={COLORS.primary.white}
                  borderRadius={3}
                  border="1px solid #C4C4C4"
                  minWidth={190}
                  flex={1}
                  height={416}
                  justifyContent="space-between"
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    Top Employee
                  </PrimaryText>
                  {/** GRAPH */}
                  {!topDonors?.length > 0 ? (
                    <View style={styles.center}>
                      <FiIcons.FiUser
                        color={COLORS.primary.neutral_300}
                        style={{
                          minHeight: 140,
                          maxHeight: 222,
                          minWidth: 140,
                          maxWidth: 222,
                        }}
                      />
                      <PrimaryText
                        fontSize={24}
                        fontWeight={800}
                        style={styles.neutral}
                      >
                        {`Top Employees\nOn The Way`}
                      </PrimaryText>
                    </View>
                  ) : (
                    <>
                      {topDonors &&
                        topDonors.map((item, index) => {
                          return (
                            <BusinessTopDonorCard
                              key={item.id}
                              item={item}
                              index={index}
                            />
                          );
                        })}
                    </>
                  )}
                </Stack>

                {/* Top Nonprofits */}
                <Stack
                  padding={20}
                  bgcolor={COLORS.primary.white}
                  borderRadius={3}
                  border="1px solid #C4C4C4"
                  minWidth={190}
                  flex={1}
                  height={416}
                  justifyContent="space-between"
                >
                  <PrimaryText
                    fontSize={20}
                    fontWeight={400}
                    style={{ marginBottom: 12 }}
                  >
                    Top Nonprofits Supported
                  </PrimaryText>
                  {!topNonprofits?.length > 0 ? (
                    <View style={styles.center}>
                      <FiIcons.FiGlobe
                        color={COLORS.primary.neutral_300}
                        style={{
                          minHeight: 140,
                          maxHeight: 222,
                          minWidth: 140,
                          maxWidth: 222,
                        }}
                      />
                      <PrimaryText
                        fontSize={24}
                        fontWeight={800}
                        style={styles.neutral}
                      >
                        {`Awaiting Greatness`}
                      </PrimaryText>
                    </View>
                  ) : (
                    <>
                      {topNonprofits &&
                        topNonprofits.map((item, index) => {
                          const { organization = {} } = item;
                          return (
                            <Stack direction="row" alignItems="center" gap={14}>
                              <PrimaryText fontSize={20}>
                                {index + 1}.
                              </PrimaryText>
                              <Image
                                source={organization?.profilePic ? { uri: organization?.profilePic } : rayzeIcon}
                                style={{
                                  width: 48,
                                  height: 48,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  borderColor: COLORS.primary.neutral_400,
                                }}
                                resizeMode="cover"
                              />

                              <Stack direction="column">
                                <PrimaryText
                                  fontSize={20}
                                  numberOfLines={1}
                                  style={{ textWrap: "wrap" }}
                                >
                                  {organization?.name}
                                </PrimaryText>
                                <PrimaryText fontSize={14} fontWeight={800}>
                                  {dollarFormatter
                                    .format(item?.amount)
                                    ?.slice(0, -3)}
                                </PrimaryText>
                              </Stack>
                            </Stack>
                          );
                        })}
                    </>
                  )}
                </Stack>
              </Stack>
            </ScrollView>
          </Box>

          <Box
            mt={36}
            bgcolor={COLORS.primary.neutral}
            boxShadow="5px 5px 15px 0px #80808026"
            borderRadius={3}
            padding={{ xs: 12, md: 24 }}
            mb={50}
            flex={1}
          >
            <PrimaryText fontSize={32}>Current Campaigns</PrimaryText>

            {activeCampaigns?.length ? (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                  marginTop: 24,
                  gap: 12
                }}>
                <Arrow
                  direction="left"
                  onClick={() => campaignCarouselRef.current.slickPrev()}
                  disabled={!currentSlide}
                />

                <View
                  style={{ flex: 1 }}
                >
                  <Slider
                    ref={campaignCarouselRef}
                    afterChange={(e) =>
                      setCurrentSlide(() =>
                        getResponsiveValue({ xs: e, xxl: e * 2 })
                      )
                    }
                    {...sliderSettings}

                  >
                    {activeCampaigns.map((campaign, index) => (
                      <Box
                        key={index}
                        flex={1}
                      >
                        <View
                          style={{
                            flex: 1,
                            marginHorizontal: 12,
                            backgroundColor: COLORS.primary.white,
                            borderColor: COLORS.primary.neutral_400,
                            borderWidth: 1,
                            borderRadius: 12,
                            padding: 12,
                          }}>
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            alignItems="center"
                            gap={20}
                            mb={32}
                          >
                            <Image
                              source={{
                                uri: campaign?.coverPhoto,
                              }}
                              style={{
                                width: 210,
                                aspectRatio: 16 / 9,
                                resizeMode: "cover",
                                borderRadius: 10,
                              }}
                            />

                            <Stack gap={8}>
                              <PrimaryText fontSize={17} fontWeight={900}>
                                {campaign?.name}
                              </PrimaryText>
                              <PrimaryText fontSize={14}>
                                {campaign?.about}
                              </PrimaryText>
                            </Stack>
                          </Stack>

                          <LinearProgressWithLabel
                            value={(campaign?.raised / campaign?.goal) * 100}
                            fontSize={16}
                            leftText="Goal:"
                            leftValue={"$" + formatShortNumber(campaign?.goal)}
                            leftTextStyle={{
                              color: COLORS.primary.darkBlue,
                              fontSize: 24,
                              marginBottom: 12,
                            }}
                            leftValueStyle={{
                              color: COLORS.primary.lightBlue,
                              fontWeight: 900,
                              fontSize: 24,
                              marginBottom: 12,
                            }}
                            rightText="Remaining:"
                            rightValue={"$" + formatShortNumber(Math.round(campaign?.goal - campaign?.raised))}
                            // rightValue={dollarFormatter
                            //   .format(campaign?.goal - campaign?.raised)
                            //   .slice(0, -3)}
                            rightTextStyle={{
                              color: COLORS.primary.darkBlue,
                              fontSize: 14,
                            }}
                            rightValueStyle={{
                              color: COLORS.primary.lightBlue,
                              fontWeight: 700,
                              fontSize: 14,
                            }}
                            height={15}
                          />

                          <Stack
                            direction={"row"}
                            alignItems={{ xs: "flex-start", md: "center" }}
                            flexWrap="wrap"
                            gap={20}
                            mt={24}
                          >
                            {[
                              {
                                title: "Date Range",
                                value:
                                  moment(campaign?.startDate).format("MM/DD") +
                                  " - " +
                                  moment(campaign?.endDate).format("MM/DD"),
                              },
                              {
                                title: "Match Percentage",
                                value: campaign?.matchPercentage || 0,
                              },
                            ].map((item, index) => (
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={8}
                                key={index}
                              >
                                <PrimaryText fontSize={17}>
                                  {item.title}:
                                </PrimaryText>
                                <PrimaryText
                                  fontSize={17}
                                  fontWeight={900}
                                  color={COLORS.primary.lightBlue}
                                >
                                  {item.value}
                                </PrimaryText>
                              </Stack>
                            ))}

                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={8}
                            key={index}
                            mt={16}
                          >
                            <PrimaryText fontSize={17}>
                              {"Supporters"}:
                            </PrimaryText>
                            <PrimaryText
                              fontSize={17}
                              fontWeight={900}
                              color={COLORS.primary.lightBlue}
                            >
                              {campaign?.backers || 0}
                            </PrimaryText>
                          </Stack>
                        </View>
                      </Box>
                    ))}
                  </Slider>
                </View>
                <Arrow
                  direction="right"
                  onClick={() => campaignCarouselRef.current.slickNext()}
                  disabled={currentSlide >= activeCampaigns?.length - 1}
                />
              </View>
            ) : (
              <View style={styles.center}>
                <FiIcons.FiCalendar
                  color={COLORS.primary.neutral_300}
                  style={{
                    minHeight: 140,
                    maxHeight: 222,
                    minWidth: 140,
                    maxWidth: 222,
                  }}
                />
                <PrimaryText
                  fontSize={24}
                  fontWeight={800}
                  style={styles.neutral}
                >
                  {`No Active Campaigns... Yet`}
                </PrimaryText>
              </View>
            )}
          </Box>
        </View>
      )}
    </>
  );
}

const getStyles = (getResponsiveValue) =>
  StyleSheet.create({
    activity: {
      flex: 1,
      width: "100%",
      backgroundColor: COLORS.primary.white,
    },
    container: {
      flex: 1,
      width: "100%",
      paddingTop: 8,
      backgroundColor: COLORS.primary.white,
    },
    header: {
      color: COLORS.primary.darkBlue,
    },
    row: {
      width: "100%",
      flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    card: {
      margin: 24,
    },
    cardTitle: {
      color: COLORS.primary.darkBlue,
      marginBottom: 12,
    },
    titleBlue: {
      color: COLORS.primary.lightBlue,
    },
    statGreen: {
      color: COLORS.primary.green,
      textAlign: "center",
    },
    statBlue: {
      color: COLORS.primary.lightBlue,
      textAlign: "center",
    },
    neutral: {
      color: COLORS.primary.neutral_300,
      textAlign: "center",
      marginTop: 12,
    },
    center: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    cover_img_v: {
      width: "100%",
      aspectRatio: 16 / 9,
    },
    campaignPhoto: {
      width: "40%",
    },
    progressBar: {
      width: "100%",
      height: 12,
      backgroundColor: COLORS.primary.neutral,
      borderRadius: 6,
      // marginTop: 12,
      overflow: "hidden",
    },
    progress: {
      width: "100%",
      height: "100%",
      backgroundColor: COLORS.primary.lightBlue,
      borderRadius: 6,
    },
  });

const StatsCard = ({ title, value, styles, color, dollar }) => {
  const width = useWindowDimensions().width;
  const isMobile = width < breakPoints.bp_md;
  return (
    <Box
      minWidth={130}
      display="flex"
      flex={1}
      flexDirection="column"
      borderRadius={3}
      boxShadow="5px 5px 15px 0px #80808026"
      bgcolor={COLORS.primary.neutral}
      alignItems="center"
      justifyContent="space-between"
      padding={{ xs: 12, md: 24 }}
      height={{ xs: 75, sm: 80, md: 120, lg: 150 }}
    >
      <View
        style={{
          flex: 1,
          // justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PrimaryText fontSize={isMobile ? 14 : 20} fontWeight={400} style={styles.cardTitle}>
          {title}
        </PrimaryText>
        <Box 
        position={"absolute"} 
        bottom={0} 
        display="flex" 
        alignItems={'flex-end'} 
        flexDirection="row" mt={4}
        >
          <PrimaryText
            fontSize={isMobile ? 20 : 42}
            fontWeight={900}
            style={color === "green" ? styles.statGreen : styles.statBlue}
          >
            {dollar ? "$" + getIntegerFromNumber(value) : value}
          </PrimaryText>
          {dollar && (
            <PrimaryText
              fontSize={isMobile ? 12 : 24}
              fontWeight={900}
              color={color === "green" ? COLORS.primary.green : COLORS.primary.lightBlue}
              style={{ alignSelf: 'flex-end', marginBottom: isMobile ? 1 : 4.5 }}
            >
              {dollar ? getDecimalFromNumber(value) : value}
            </PrimaryText>
          )}
        </Box>
      </View>
    </Box>
  );
};

const StatsCardOutlined = ({ title, value, yoy }) => {
  return (
    <Stack
      padding={20}
      bgcolor={COLORS.primary.white}
      borderRadius={3}
      border="1px solid #C4C4C4"
      height={{ xs: 125, sm: 120, lg: 124 }}
    // maxHeight={{ xs: 125, sm: 120, lg: 80 }}
    >
      <PrimaryText fontSize={20} fontWeight={400}>
        {title}
      </PrimaryText>

      <Stack direction="row" alignItems="flex-end" gap={8} mt={10}>
        <PrimaryText fontSize={32} fontWeight={900}>
          {value}
        </PrimaryText>
        <PrimaryText
          fontSize={20}
          fontWeight={400}
          style={{
            marginBottom: 3,
            color: COLORS.primary.green,
          }}
        >
          {yoy}
        </PrimaryText>
      </Stack>
    </Stack>
  );
};

const Arrow = ({ direction, onClick, disabled }) => {
  const Icon =
    direction === "left" ? FiIcons.FiChevronLeft : FiIcons.FiChevronRight;
  return (
    <Box
      minWidth={40}
      minHeight={40}
      bgcolor={disabled ? COLORS.primary.neutral_300 : COLORS.primary.lightBlue}
      borderRadius={20}
      display={{ xs: "none", sm: "flex" }}
      alignItems="center"
      justifyContent="center"
      style={{ cursor: "pointer" }}
      onClick={() => !disabled && onClick()}
    >
      <Icon
        size={24}
        color={disabled ? COLORS.primary.dark_300 : COLORS.primary.white}
      />
    </Box>
  );
};

const CARDS_DATA = [
  { title: "Employee Participation", value: "82%", yoy: "+18% YoY" },
  { title: "Employee Donations", value: "2,103", yoy: "+23% YoY" },
  { title: "Average Employee Donation", value: "$145", yoy: "+6% YoY " },
];
