import { Box } from "@mui/material";
import SaveAndCancelBtns from "components/Buttons/SaveAndCancelBtns";
import MuiButton from "components/Common/MuiButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { FullModal } from "components/Modals/FullModal";
import { COLORS } from "constants/Colors";
import React, { useCallback, useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getMimeTypeFromBase64 } from "utils/helpers";

const COVER_ASPECT_RATIO = 16 / 9;
const SQUARE_ASPECT_RATIO = 1;

export default function UploadPhotoModal({
  imageUrl,
  onSave,
  onCancel,
  square,
  isOpen,
  title,
  loading,
  freeform,
}) {
  const imgRef = useRef(null);
  const canvaRef = useRef(null);
  const imageData = useRef(null);

  const [imageFile, setImageFile] = useState({ url: imageUrl });
  const [crop, setCrop] = useState({ unit: "%", x: 100, y: 100 });

  const aspectRatio = freeform
    ? null
    : square
    ? SQUARE_ASPECT_RATIO
    : COVER_ASPECT_RATIO;

  // Make the cropped image ready to upload on Firestore
  const onCropCompleted = useCallback(
    async (crop) => {
      const image = imgRef.current;
      const canvas = canvaRef.current;

      console.log("crop completed", image.width, image.height, crop);

      image.crossOrigin = "anonymous";

      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("No 2d context");
      }

      if (crop.unit === "%")
        crop = convertToPixelCrop(crop, image.width, image.height);

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.save();

      // Move the crop origin to the canvas origin (0,0)
      ctx.translate(-crop.x, -crop.y);

      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.width,
        image.height
      );

      ctx.restore();

      const mimeType = getMimeTypeFromBase64(image.src);
      const dataUrl = await canvas.toDataURL(mimeType, 1.0);
      const croppedImage = imageFile;
      croppedImage.dataUrl = dataUrl;
      imageData.current = croppedImage;
    },
    [imageFile]
  );

  const onLoadImage = useCallback(
    (e) => {
      const { width, height } = e.currentTarget;

      const centeredCrop = centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 100,
          },
          freeform ? width / height : aspectRatio,
          width,
          height
        ),
        width,
        height
      );

      console.log("centered crop", centeredCrop);

      setCrop(centeredCrop);

      onCropCompleted(centeredCrop);
    },
    [onCropCompleted, aspectRatio, freeform]
  );

  if (!isOpen) return null;

  return (
    <FullModal title={title || "Upload Photo"} setShowModal={onCancel}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="95%"
        alignSelf="center"
        px={20}
      >
        <PrimaryText
          fontSize={12}
          fontWeight={300}
          color={COLORS.primary.neutral_800}
          style={{ marginTop: 12, marginBottom: 8 }}
        >
          PNG and JPG images are supported.
        </PrimaryText>

        <MuiButton
          title="Upload"
          variant="contained"
          sx={{
            height: 30,
            fontSize: 14,
            fontWeight: 700,
            width: 190,
            paddingX: 0,
          }}
          type="file"
          onChange={setImageFile}
        />

        {imageFile?.url && (
          <>
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              aspect={aspectRatio}
              minHeight={100}
              maxWidth={freeform ? 500 : null}
              minWidth={freeform ? 100 : null}
              style={{ marginTop: 20 }}
              onComplete={(pixelCrop) => onCropCompleted(pixelCrop)}
            >
              <img
                src={imageFile.url}
                alt="Event Cover"
                onLoad={onLoadImage}
                width="100%"
                ref={imgRef}
              />
            </ReactCrop>
            <div>
              <canvas
                ref={canvaRef}
                style={{
                  display: "none",
                }}
              />
            </div>
          </>
        )}

        {!!imageFile?.url && (
          <SaveAndCancelBtns
            onSave={() => onSave?.(imageData.current?.dataUrl)}
            onCancel={onCancel}
            loading={loading}
          />
        )}
      </Box>
    </FullModal>
  );
}
