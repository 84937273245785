import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Links } from "constants/Links";
import { View } from "react-native-web";

import { useDisplay } from "context/DisplayContext";
import { BusinessDashboard } from "screens/Business/BusinessDashboard";
import { Employees } from "screens/Business/Employees";
import { BusinessDonations } from "screens/Business/BusinessDonations";
import { BusinessVolunteers } from "screens/Business/BusinessVolunteers";
import { BusinessCampaigns } from "screens/Business/BusinessCampaigns";
import { BusinessCampaignDetails } from "screens/Business/BusinessCampaignDetails";
import { GeneralSettings } from "screens/Business/GeneralSettings/GeneralSettings";
import { EmployeeDetail } from "screens/Business/EmployeeDetail";
import { Nonprofits } from "screens/Nonprofits/Nonprofits";
import { NonprofitDetail } from "screens/Nonprofits/NonprofitDetail";
import { RayzeWallet } from "screens/RayzeWallet/RayzeWallet";
import DonorDetails from "screens/Donations/DonorDetails";
import { Box } from "@mui/material";

export default function BusinessNavigator() {
  const { showSidebar } = useDisplay();

  return (
    <Box
      flex={1}
      marginLeft={{ xs: 0, lg: showSidebar ? 280 : 60 }}
      alignItems={"center"}
      justifyContent={"center"}
      p={{ xs: 12, md: 24 }}
    >
      <Routes>
        <Route path={Links.BusinessDashboard} element={<BusinessDashboard />} />

        {/** redirects */}
        <Route
          path={"/*"}
          element={<Navigate replace to={Links.BusinessDashboard} />}
        />

        <Route path={Links.RayzeWallet} element={<RayzeWallet />} />
        <Route path={Links.BusinessEmployees} element={<Employees />} />
        <Route path={Links.DonorDetails} element={<DonorDetails />} />
        <Route
          path={Links.BusinessEmployeeDetail}
          element={<EmployeeDetail />}
        />
        <Route path={Links.BusinessDonations} element={<BusinessDonations />} />
        <Route
          path={Links.BusinessVolunteers}
          element={<BusinessVolunteers />}
        />
        <Route path={Links.BusinessCampaigns} element={<BusinessCampaigns />} />
        <Route
          path={Links.BusinessCampaignDetails}
          element={<BusinessCampaignDetails />}
        />

        <Route path={Links.Nonprofits} element={<Nonprofits />} />
        <Route path={Links.NonprofitDetail} element={<NonprofitDetail />} />

        <Route path={Links.GeneralSettings} element={<GeneralSettings />} />
      </Routes>
    </Box>
  );
}
