import React from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { PrimaryText } from "components/Common/PrimaryText";
import { CheckboxActive, CheckboxInactive, ChevronDown } from "assets/svgs";
import { COLORS } from "constants/Colors";

export default function ShiftStartTime({
  options = [],
  value = "",
  onChange = () => {},
  containerProps,
}) {
  return (
    <Box ml={20} {...containerProps}>
      <PrimaryText
        fontSize={14}
        fontWeight={900}
        style={!options?.length ? { color: COLORS.primary.neutral_400 } : null}
      >
        Shift Start Time
      </PrimaryText>

      <Select
        value={options?.length ? value : ["N/A"]}
        multiple
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => selected.join("; ")}
        disabled={!options?.length}
        IconComponent={() => (
          <ChevronDown
            fill={
              options?.length
                ? COLORS.primary.darkBlue
                : COLORS.primary.neutral_400
            }
            fontSize={14}
            style={{
              marginRight: 14,
              width: 34,
            }}
          />
        )}
        sx={selectSx}
      >
        {(options?.length ? options : ["N/A"]).map((option, index) => (
          <MenuItem key={option + "-" + index} value={option}>
            <ListItemText
              primary={option}
              sx={{ marginRight: 40 }}
              primaryTypographyProps={{
                fontSize: 14,
              }}
            />
            <Checkbox
              checkedIcon={<CheckboxActive />}
              icon={<CheckboxInactive />}
            />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

const selectSx = {
  marginLeft: 5,
  borderRadius: 4,
  height: 30,
  width: 165,
  fontSize: 14,
  bgcolor: COLORS.primary.white,
  "& > .MuiSelect-select": {
    display: "flex",
    flexShrink: 1,
    pr: "0 !important",
    pl: 13,
  },
};
