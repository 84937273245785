import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { DONATION_STATUSES } from "constants/Data";
import { COLORS } from "constants/Colors";
import moment from "moment";
import MuiCheckboxBlue from "components/Common/MuiCheckboxBlue";
import { LuCircleCheck, LuCircleX } from "react-icons/lu";
import { ActivityIndicator } from "react-native-web";

export const CompanyDonationCard = ({ item, onPress, isMatch, onApprove, onDeny, loading }) => {
  const {
    userId,
    amount,
    date,
    organization = {},
    corporateStatus = 0,
    anonymous = false,
    companyId,
    match = false,
  } = item;

  const [user, setUser] = useState(item?.user || {});

  useEffect(() => {
    if (anonymous) {
      setUser({
        firstName: "Anonymous",
        lastName: "",
        profilePic: "",
      });
      return;
    }
    if (userId) getUser(userId, setUser);
  }, [userId]);

  let type = "Direct Donation";
  if (isMatch) {
    type = "Employee Match";
  }

  if (!date) return null;

  return (
    <TableRow hover onClick={onPress}>

      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText fontSize={18} fontWeight={900}>
          {organization?.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {moment(date?.toDate() ? date.toDate() : date).format("MM/DD/YYYY")}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {type}
        </PrimaryText>
      </TableCell>
      {/* <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {"payment method"}
        </PrimaryText>
      </TableCell> */}
      <TableCell>
        <PrimaryText fontSize={16} fontWeight={500}>
          {dollarFormatter.format(amount)}
        </PrimaryText>
      </TableCell>
    </TableRow>
  );
};
