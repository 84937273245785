import React, { useRef, useState } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Box,
  ButtonBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { CustomInput } from "components/Inputs/CustomInput";
import { DateInput } from "components/Inputs/DateInput";
import { ChevronLeft, Edit, Files, QRCode } from "assets/svgs";
import MuiButton from "components/Common/MuiButton";
import MuiCard from "components/Common/MuiCard";
import { TextInput, View } from "react-native-web";
import MuiTab from "components/Tabs/MuiTab";
import DonorCard from "components/Cards/DonorCard";
import ChampionCard from "components/Cards/ChampionCard";
import { Links } from "constants/Links";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { useOrganization } from "context/OrganizationContext";
import { useEffect } from "react";
import {
  getCustomLinkById,
  updateCustomLink,
} from "services/custom-link-service";
import moment from "moment";
import ErrorMessage from "components/Common/ErrorMessage";
import MuiSnackbar from "components/Common/MuiSnackbar";
import { QRCodeCanvas } from "qrcode.react";
import { dollarFormatter } from "utils/helpers";
import { useCustomLinks } from "context/CustomLinksContext";
import { DonationCard } from "components/Cards/DonationCard";
import { useFundraisers } from "context/FundraiserContext";
import useMediaQuery from "hooks/useMediaQuery";

const rayzeLogo = require("assets/icon.png");

export default function CustomLinkDetails() {
  const navigate = useNavigate();
  const { getResponsiveValue } = useMediaQuery();
  const { selectedOrg } = useOrganization();
  const { selectedCustomLink, setSelectedCustomLink, customLinkDonations } =
    useCustomLinks();
  const { ourFundraisers, setSelectedFundraiser } = useFundraisers();
  const BASE_URL = `https://dev.donate.rayzeapp.com/${
    selectedOrg?.customUrl ? selectedOrg?.customUrl : "" + selectedOrg?.id
  }/`;

  // const { state } = useLocation();
  // const { id } = state;

  const qrRef = useRef();

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [isEditingUrl, setIsEditingUrl] = useState(false);
  const [customUrl, setCustomUrl] = useState("");
  const [activeTab, setActiveTab] = useState("Donors");
  const [isEditingName, setIsEditingName] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [name, setName] = useState("");
  const [errors, setErrors] = useState({});

  const [champions, setChampions] = useState([]);

  // console.log('test', champions, ourFundraisers, selectedCustomLink)

  useEffect(() => {
    setLoading(true);

    getCustomLinkById({
      orgId: selectedOrg.id,
      id: selectedCustomLink.id,
    })
      .then((doc) => setSelectedCustomLink(doc))
      .catch((err) => console.log(err))
      .finally((_) => setLoading(false));
  }, [selectedOrg.id]);

  useEffect(() => {
    //find champions for the selected custom link
    const champions = ourFundraisers.filter(
      (fundraiser) => fundraiser.customLink?.id === selectedCustomLink.id
    );
    setChampions(champions);
  }, [ourFundraisers]);

  useEffect(() => {
    if (!customLinkDonations || !customLinkDonations.length) return;
    //find donations with referral id's
    const donations = customLinkDonations.filter(
      (donation) => donation.fundraiserUserId
    );
    //join donations by user.id
    const champions = donations.reduce((acc, curr) => {
      const existing = acc.find((item) => item.id === curr.fundraiserUserId);
      if (existing) {
        existing.amount += curr.amount - curr.fee;
        existing.donations.push(curr);
      } else {
        const user = curr.champion;
        acc.push({
          id: curr.fundraiserUserId,
          name: user.firstName + " " + user.lastName,
          email: user.email,
          amount: curr.amount - curr.fee,
          donations: [curr],
          userId: user.id,
          user: user,
        });
      }
      return acc;
    }, []);
    console.log("champions", champions);
    setChampions(champions);
  }, [customLinkDonations]);

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${BASE_URL}${selectedCustomLink?.customUrl}`
      );
      setOpenSnackbar("Link copied!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const removeError = (key) => setErrors((prev) => ({ ...prev, [key]: null }));

  const handleChange = (key, event) => {
    let value = event.target.value;

    setErrors((prev) => ({ ...prev, [key]: null }));

    if (key === "customUrl") {
      //remove special characters except numbers and hyphen
      value = value.replace(/[^0-9a-z-]/gi, "");
      //remove multiple hyphens
      value = value.replace(/-{2,}/g, "-");

      //limit to 24 characters
      value = value.slice(0, 24);

      setCustomUrl(value);
    } else if (key === "name") {
      value = value.slice(0, 24);
      setName(value);
    }
  };

  const handleUpdate = (key) => {
    const newErrors = {};

    if (key === "name" && !name) newErrors.name = "Name can't be empty.";
    if (key === "customUrl" && !customUrl)
      newErrors.customUrl = "Custom Url can't be empty.";

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }

    const data = {};

    if (key === "customUrl") data.customUrl = customUrl;
    if (key === "name") {
      data.name = name;
      data.lowerCaseName = name.toLowerCase();
    }

    setLoading(true);

    updateCustomLink({
      data,
      orgId: selectedOrg?.id,
      id: selectedCustomLink?.id,
    })
      .then((doc) => {
        setSelectedCustomLink((prev) => ({ ...prev, ...doc }));

        if (key === "customUrl") {
          setIsEditingUrl(false);
          setOpenSnackbar("Custom Url updated successfully.");
        }
        if (key === "name") {
          setIsEditingName(false);
          setOpenSnackbar("Name updated successfully.");
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 412)
          setErrors((prev) => ({ ...prev, customUrl: err.message }));
      })
      .finally((_) => setLoading(false));
  };

  const downloadQRCode = () => {
    let canvas = qrRef.current.querySelector("canvas");
    let image = canvas.toDataURL("image/png");
    let anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = `qr-code.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const { totalDonations = 0, backers = [] } = selectedCustomLink;

  //total sum of fundraiserDonations
  // const totalAmounts = customLinkDonations
  // 	? customLinkDonations?.reduce((acc, curr) => acc + curr.amount, 0)
  // 	: 0;
  // const totalFees = customLinkDonations
  // 	? customLinkDonations?.reduce(
  // 		(acc, curr) => acc + (curr?.fee ? curr?.fee : 0),
  // 		0
  // 	)
  // 	: 0;
  // const totalDonations = totalAmounts - totalFees;

  const styles = getStyles(getResponsiveValue);

  return (
    <>
      <Stack direction="row" width="100%">
        <ButtonBase onClick={() => navigate(Links.CustomLinks)}>
          <ChevronLeft stroke={COLORS.primary.darkBlue} />
          <PrimaryText
            fontSize={24}
            fontWeight={900}
            style={{ marginLeft: 10 }}
          >
            Custom Links
          </PrimaryText>
        </ButtonBase>
      </Stack>

      {/* Name */}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        mt={27}
        ml={{ xs: 20, md: 55 }}
      >
        {!isEditingName && (
          <>
            <PrimaryText fontSize={32} fontWeight={900}>
              {selectedCustomLink?.name}
            </PrimaryText>

            <MuiButton
              title="Edit Name"
              variant="outlined"
              color="secondary"
              startIcon={<Edit fill={COLORS.primary.darkGray} width={16} />}
              sx={muiStyles.edit}
              onPress={() => {
                setIsEditingName(true);
                setName(selectedCustomLink?.name);
              }}
            />
          </>
        )}

        {isEditingName && (
          <>
            <Stack spacing={10}>
              <TextInput
                value={name}
                style={styles.nameInput}
                onChange={(e) => handleChange("name", e)}
              />
              <ErrorMessage errorMessage={errors?.name} />
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              mt={errors?.name ? -40 : 0}
            >
              <MuiButton
                sx={muiStyles.save}
                title="Save"
                variant="contained"
                onPress={(_) => handleUpdate("name")}
                disabled={errors?.name}
              />

              <MuiButton
                sx={muiStyles.cancel}
                title="Cancel"
                variant="outlined"
                color="secondary"
                onPress={() => {
                  setIsEditingName(false);
                  removeError("name");
                }}
              />
            </Stack>
          </>
        )}
      </Box>

      {/* Link details */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        ml={{ xs: 20, md: 55 }}
        mt={30}
        mb={30}
        gap={{ xs: 20, xl: 40 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          width={{ xs: "95%", md: "auto" }}
        >
          <PrimaryText fontSize={24} fontWeight={900}>
            Information
          </PrimaryText>

          <TextRow title="Creator:" value={selectedCustomLink?.creator} />
          <TextRow
            title="Creation Date:"
            value={moment(
              selectedCustomLink?.createdAt
                ? selectedCustomLink?.createdAt?.toDate()
                : new Date()
            ).format("MMM/DD/YYYY")}
          />

          <Stack direction="row" flexWrap="wrap" gap={20}>
            <MuiCard
              containerStyle={{
                width: 200,
                height: 100,
                marginTop: 20,
                marginBottom: 40,
                marginHorizontal: 0,
              }}
              title="Total Raised"
              value={dollarFormatter.format(totalDonations)}
            />
            <MuiCard
              containerStyle={{
                width: 200,
                height: 100,
                marginTop: 20,
                marginBottom: 40,
                marginHorizontal: 0,
              }}
              title="Donors"
              value={backers?.length}
            />
          </Stack>
        </Box>

        <View style={styles.line} />

        <Box display="flex" flexDirection="column">
          <PrimaryText fontSize={24} fontWeight={900}>
            Custom URL and QR Code
          </PrimaryText>

          <Box display="flex" mt={16} flexWrap="wrap" gap={10}>
            <PrimaryText
              fontSize={14}
              fontWeight={400}
              style={{
                textWrap: "wrap",
                width: getResponsiveValue({ xs: "95%", sm: "auto" }),
              }}
            >
              {isEditingUrl
                ? BASE_URL
                : `${BASE_URL}${selectedCustomLink?.customUrl}`}
            </PrimaryText>

            {!isEditingUrl && (
              <MuiButton
                title="Edit"
                variant="contained"
                startIcon={<Edit fill={COLORS.primary.white} width={12} />}
                sx={muiStyles.editUrl}
                onPress={() => {
                  setIsEditingUrl(true);
                  setCustomUrl(selectedCustomLink?.customUrl);
                }}
              />
            )}

            {isEditingUrl && (
              <>
                <View>
                  <CustomInput
                    style={{ maxWidth: 150, marginTop: -6 }}
                    borderColor={errors?.customUrl && COLORS.primary.error}
                    value={customUrl}
                    onChange={(e) => handleChange("customUrl", e)}
                    errorMessage={errors?.customUrl}
                  />
                </View>

                <MuiButton
                  sx={muiStyles.save}
                  title="Save"
                  variant="contained"
                  onPress={(_) => handleUpdate("customUrl")}
                  disabled={errors?.customUrl}
                />

                <MuiButton
                  sx={muiStyles.cancel}
                  title="Cancel"
                  variant="outlined"
                  color="secondary"
                  onPress={() => {
                    setIsEditingUrl(false);
                    removeError("customUrl");
                  }}
                />
              </>
            )}
          </Box>

          <MuiButton
            title="Copy Link"
            variant="outlined"
            color="secondary"
            startIcon={
              <Files
                fill={COLORS.primary.darkBlue}
                width={16}
                style={{ opacity: isEditingUrl ? 0.5 : 1 }}
              />
            }
            sx={muiStyles.copyLink}
            onPress={copyLink}
            disabled={isEditingUrl}
          />

          <MuiButton
            title="Download QR Code"
            variant="contained"
            startIcon={<QRCode fill={COLORS.primary.white} width={16} />}
            sx={{ height: 29, fontSize: 14, fontWeight: 700, width: 206 }}
            onPress={downloadQRCode}
            disabled={isEditingUrl}
          />
        </Box>
      </Box>

      <Box width="100%">
        <MuiTab
          tabs={["Donors", "Champions"]}
          active={activeTab}
          onClick={setActiveTab}
        />
        <ShadowContainer style={styles.container}>
          {activeTab === "Donors" && (
            <>
              {/** HEADER */}
              <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
                Custom Links
              </PrimaryText>

              <Box
                display="flex"
                alignItems="center"
                width="100%"
                flexWrap="wrap"
                mb={40}
                mt={14}
                gap={30}
              >
                <CustomInput
                  placeholder="Search"
                  style={{
                    flexGrow: 1,
                    maxWidth: 350,
                  }}
                  value={search}
                  onChangeText={setSearch}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  flexWrap="wrap"
                  gap={25}
                >
                  {/* Start Date */}
                  <Box display="flex" alignItems="center">
                    <PrimaryText fontSize={14} fontWeight={900}>
                      Start Date
                    </PrimaryText>
                    <DateInput
                      value={startDate}
                      onChange={(e) => setStartDate(new Date(e.target.value))}
                      placeholder="MM/DD/YY"
                      long
                    />
                  </Box>

                  {/* End Date */}
                  <Box display="flex" alignItems="center">
                    <PrimaryText
                      fontSize={14}
                      fontWeight={900}
                      style={{ marginRight: 10 }}
                    >
                      End Date
                    </PrimaryText>
                    <DateInput
                      value={endDate}
                      onChange={(e) => setEndDate(new Date(e.target.value))}
                      placeholder="MM/DD/YY"
                      long
                    />
                  </Box>
                </Box>
              </Box>

              {/** TABLE */}
              <TableContainer>
                <Table
                  className="table"
                  style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
                      <TableCell>Payment ID</TableCell>
                      <TableCell>EMAIL</TableCell>
                      <TableCell>Frequency</TableCell>
                      <TableCell>AMOUNT GIVEN</TableCell>
                      <TableCell>DATE</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {customLinkDonations.map((donation) => {
                      console.log("wtf", donation);
                      return <DonationCard key={donation.id} item={donation} />;
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <MuiTablePagination
                count={0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              />
            </>
          )}

          {activeTab === "Champions" && (
            <>
              {/** HEADER */}
              <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
                Custom Links
              </PrimaryText>

              <Box
                display="flex"
                alignItems="center"
                width="100%"
                mb={40}
                mt={14}
              >
                <CustomInput
                  placeholder="Search"
                  style={{
                    flexGrow: 1,
                    maxWidth: 350,
                  }}
                  value={search}
                  onChangeText={setSearch}
                />

                <Box display="flex" alignItems="center" ml={30}>
                  {/* Start Date */}
                  <Box display="flex" alignItems="center">
                    <PrimaryText
                      fontSize={14}
                      fontWeight={900}
                      style={{ marginRight: 10 }}
                    >
                      Start Date
                    </PrimaryText>
                    <DateInput
                      value={startDate}
                      onChange={(e) => setStartDate(new Date(e.target.value))}
                      placeholder="MM/DD/YY"
                      long
                    />
                  </Box>

                  {/* End Date */}
                  <Box display="flex" alignItems="center" ml={25} mr={25}>
                    <PrimaryText
                      fontSize={14}
                      fontWeight={900}
                      style={{ marginRight: 10 }}
                    >
                      End Date
                    </PrimaryText>
                    <DateInput
                      value={endDate}
                      onChange={(e) => setEndDate(new Date(e.target.value))}
                      placeholder="MM/DD/YY"
                      long
                    />
                  </Box>
                </Box>

                <PrimaryButton
                  title="Reset"
                  variant="outlined"
                  titleStyle={{ fontSize: 14, fontWeight: 700 }}
                  style={{ height: 30, margin: 0 }}
                  // onPress={resetFilters}
                />
              </Box>

              {/** TABLE */}
              <Table
                className="table"
                style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
                    <TableCell>EMAIL</TableCell>
                    <TableCell>LATEST DONATION</TableCell>
                    <TableCell>DONATIONS</TableCell>
                    <TableCell>AMOUNT GIVEN</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {champions.map((champion) => (
                    <ChampionCard
                      key={champion.id}
                      item={champion}
                      onPress={() => {
                        console.log("champion", champion);
                        setSelectedFundraiser(champion);
                        navigate(Links.FundraisingDetail);
                      }}
                    />
                  ))}
                  {/* <ChampionCard />
									<ChampionCard />
									<ChampionCard /> */}
                </TableBody>
              </Table>

              <MuiTablePagination
                count={0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(e.target.value);
                  setCurrentPage(0);
                }}
              />
            </>
          )}
        </ShadowContainer>
      </Box>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size="large"
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}

      <MuiSnackbar open={openSnackbar} onClose={() => setOpenSnackbar(false)} />

      <Box ref={qrRef} display="none">
        <QRCodeCanvas
          // id="qrCode"
          value={BASE_URL + customUrl}
          size={300}
          bgColor={"white"}
          imageSettings={{
            src: rayzeLogo,
            x: undefined,
            y: undefined,
            height: 75,
            width: 75,
          }}
          level="H"
          style={{ display: "hidden" }}
        />
      </Box>
    </>
  );
}

export const TextRow = ({ title, value }) => (
  <Stack direction="row" mt={12}>
    <PrimaryText fontSize={20} style={{ width: 150 }}>
      {title}
    </PrimaryText>
    <PrimaryText fontSize={20} fontWeight={900}>
      {value}
    </PrimaryText>
  </Stack>
);

const muiStyles = {
  edit: {
    height: 29,
    fontSize: 14,
    fontWeight: 700,
    ml: 36,
  },
  copyLink: {
    height: 29,
    fontSize: 14,
    fontWeight: 700,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 13,
    width: 132,
    paddingX: 0,
  },
  editUrl: {
    height: 23,
    fontSize: 12,
    mt: -4,
  },
  cancel: {
    height: 23,
    fontSize: 12,
    fontWeight: 700,
  },
  save: {
    height: 23,
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 12,
    marginRight: 12,
  },
};

const getStyles = (getResponsiveValue) =>
  StyleSheet.create({
    activity: {
      width: "100%",
      height: "100%",
      backgroundColor: COLORS.primary.white,
      position: "absolute",
      top: 0,
      bottom: 0,
    },
    container: {
      flex: 1,
      width: "100%",
      flexDirection: "column",
    },
    line: {
      width: 2,
      height: 150,
      backgroundColor: COLORS.primary.neutral_400,
      alignSelf: "center",
      borderRadius: 2,
      display: getResponsiveValue({ xs: "none", lg: "flex" }),
    },
    nameInput: {
      fontSize: 32,
      fontWeight: 900,
      backgroundColor: COLORS.primary.neutral_100,
      paddingHorizontal: 9,
      paddingVertical: 10,
      borderRadius: 5,
    },
  });
