import { firestore, storage } from "utils/firebase";
import firebase from "firebase/compat/app";
import { getUsersFromMembersPromise, getUsersPromise, updateMemberInfo } from "./user-service";
import { collection, doc, updateDoc } from "firebase/firestore";

//need to move to promise for pics
export const getUserOrgs = (
	currentUser,
	setOrganizations,
	selectedOrg,
	setSelectedOrg
) => {
	// console.log('firing?')
	return firestore
		.collection("organizations")
		.where("members", "array-contains", currentUser.id)
		.onSnapshot(
			(querySnapshot) => {
				const temp = [];

				querySnapshot.forEach((doc, index) => {
					const id = doc.id;
					const docData = doc.data();
					const org = {
						id,
						coverPhoto: null,
						...docData
					};
					temp.push(org);
				});

				let promises = temp.map((item, index) =>
					getOrgPicturePromise(item, item.id)
				);
				Promise.all(promises).then((organizations) => {
					let sorted = organizations.sort((a, b) =>
						a?.lowercaseName > b?.lowercaseName ? 1 : -1
					);
					// .filter((x) => x?.type === "nonprofit" || x?.type === "community");
					setOrganizations(sorted);

					if (!selectedOrg) setSelectedOrg(sorted[0]);

					if (sorted?.length === 0) {
						window.confirm(
							"This user is not associated with any organization."
						);
						console.log("nice");

						// logout();
					}
				});
			},
			(e) => {
				console.log("error getting user orgs listener", e);
			}
		);
};

export function getOrgCoverPhoto(orgId, setCoverPhoto) {
	const picRef = storage.ref(`orgCoverPhotos/${orgId}/0.jpg`);

	picRef
		.getDownloadURL()
		.then((uri) => {
			setCoverPhoto(uri);
		})
		.catch((err) => { });
}

export function getOrgPicturePromise(obj, orgId) {
	return new Promise((resolve, reject) => {
		const picRef = storage.ref(`orgCoverPhotos/${orgId}/0.jpg`);

		picRef
			.getDownloadURL()
			.then((uri) => {
				obj.coverPhoto = uri;
				resolve(obj);
			})
			.catch((err) => {
				resolve(obj);
			});
	});
}

export const getOrgPromise = (obj, id) => {
	return new Promise((resolve, reject) => {
		if (obj.cot) {
			obj.organization = obj.org;
			obj.organization.id = obj.orgId;
			resolve(obj);
			return;
		}

		firestore
			.collection("organizations")
			.doc(id)
			.get()
			.then((doc) => {
				//check if user active here
				if (doc.exists) {
					let org = {
						id: doc.id,
						...doc.data()
					};
					obj.organization = org;
					resolve(obj);
				} else {
					resolve(null);
				}
			});
	});
};

export const createOrganization = (
	currentUser,
	organization,
	coverPhotos,
	profilePics,
	onSuccess
) => {
	const orgRef = firestore.collection("organizations");

	if (organization.type === "nonprofit") organization.trendingScore = 10;
	if (organization.type === "business") {
		organization.allowAnonymousDonations = false;
		organization.allowDonationsToAllNonprofits = false;
		organization.autoApproveDonations = false;
		organization.fiscalYear = "January";

		organization.hourlyVolunteerCompensation = 0;
		organization.matchPercentage = 0;
		organization.matchAnyDonation = true;
		organization.maxCompanyMatchPerYear = 0;
		organization.maxEmployeeMatchPerYear = 0;
	}
	organization.createdAt = new Date();
	organization.members = [currentUser.id];
	organization.lowercaseName = organization.name.toLowerCase();
	organization.verified = false;

	orgRef
		.add(organization)
		.then((docRef) => {
			const membersRef = orgRef.doc(docRef.id).collection("members");

			membersRef
				.doc(currentUser.id)
				.set({
					active: true,
					postNewOpportunities: true,
					publicPosts: true,
					role: "Leader",
					verified: true,
					viewDonations: true,
					volunteerApplicationReview: true,
					volunteerCheckIn: true
				})
				.then(() => {
					if (coverPhotos?.length === 0 && profilePics?.length === 0) {
						if (onSuccess) onSuccess(docRef.id);
					} else {
						updateOrgPics(
							docRef.id,
							organization,
							coverPhotos,
							profilePics,
							onSuccess,
							true,
							true
						);
					}
				})
				.then(function () {
					console.log("Document Added ", docRef.id);
				})
				.catch(function (error) {
					console.error("Error adding document: ", error);
				});
		})
		.catch((error) => {
			console.error("Error adding document: ", error);
		});
};

export const updateOrganization = (
	org,
	data,
	coverPhotos,
	profilePics,
	onSuccess
) => {
	const orgRef = firestore.collection("organizations").doc(org.id);

	orgRef
		.update(data)
		.then(() => {
			if (coverPhotos?.length === 0 && profilePics?.length === 0) {
				onSuccess(data);
				// Alert.alert("Success", "Update Successful", [
				//   { text: "Ok", onPress: () => onSuccess(data) },
				// ])
			} else {
				console.log(coverPhotos.length);
				updateOrgPics(org.id, data, coverPhotos, profilePics, onSuccess);
			}
		})
		.catch((err) => {
			console.log("err", err);
			window.alert("Error", "An error has occured please try later.", [
				{ text: "Ok", onPress: onSuccess }
			]);
		});
};

const updateOrgPics = (orgId, data, coverPhotos, profilePics, onSuccess) => {
	const orgRef = firestore.collection("organizations").doc(orgId);
	let promises = [];
	coverPhotos.forEach((val, index) => {
		let ref = storage.ref(`orgCoverPhotos/${orgId}/${index}.jpg`);
		promises.push(
			ref
				.put(val.blob, { cacheControl: "max-age=31536000" })
				.then(async (result) => {
					console.log("result", result);
					const downloadURL = await ref.getDownloadURL();
					orgRef.update({ coverPhoto: downloadURL }).then(() => {
						data.coverPhoto = downloadURL;
					});
				})
				.catch((err) => {
					console.log("err", err);
				})
		);
	});
	profilePics.forEach((val, index) => {
		let ref = storage.ref(`orgProfilePics/${orgId}/${index}.jpg`);
		promises.push(
			ref
				.put(val.blob, { cacheControl: "max-age=31536000" })
				.then(async (result) => {
					console.log("profile pic upload result", result);
					const downloadURL = await ref.getDownloadURL();
					orgRef.update({ profilePic: downloadURL }).then(() => {
						data.profilePic = downloadURL;
					});
				})
				.catch((err) => {
					console.log("err", err);
				})
		);
	});
	console.log("test promises length", promises?.length);
	Promise.all(promises).then(() => {
		onSuccess({ id: orgId, ...data });
		// Alert.alert("Success", "Update Successful", [
		//   { text: "Ok", onPress: () => onSuccess(data) },
		// ])
	});
};

export const getOrgMembers = (
	currentUser,
	organization,
	setMembers,
	setSponsors,
	setUserType,
	setCurrentMember,
	setLoading
) => {
	return firestore
		.collection("organizations")
		.doc(organization.id)
		.collection("members")
		.onSnapshot(
			(membersQuerySnapshot) => {
				let membersData = [];

				membersQuerySnapshot.forEach((memberDoc) => {
					const memberData = memberDoc.data();
					const memberId = memberDoc.id;

					const member = {
						id: memberId,
						...memberData
					};

					membersData.push(member);
				});

				let promises = membersData.map((item, index) =>
					getUsersFromMembersPromise(item.id, item)
				);
				Promise.all(promises).then((users) => {
					const filteredDeleted = users.filter((x) => x !== null);
					const filteredMembers = filteredDeleted.filter(
						(x) => x.role !== "Sponsor"
					);
					const filteredSponsors = filteredDeleted.filter(
						(x) => x.role === "Sponsor"
					);
					setMembers(filteredMembers);
					setSponsors(filteredSponsors);

					const findUs = filteredMembers.find((x) => x.id === currentUser.id);
					if (findUs) {
						// console.log('here!', findUs)
						setCurrentMember(findUs);
						setUserType(findUs.role);
						if (!findUs.verified) {
							updateMemberInfo(organization, currentUser, { verified: true }, () => { })
						}
					}
					setLoading(false);
				});
			},
			(e) => {
				console.log("getOrgMembers error getting members of organization", e);
			}
		);
};

export const getOrgInvitedMembers = (organization, setInvitedMembers) => {
	return firestore
		.collection("inviteRequests")
		.where("orgId", "==", organization.id)
		.onSnapshot(
			(querySnapshot) => {
				let temp = [];
				querySnapshot.forEach((memberDoc) => {
					const data = memberDoc.data();
					const id = memberDoc.id;

					const invite = {
						id,
						...data,
						invite: true
					};

					temp.push(invite);
				});

				setInvitedMembers(temp);
			},
			(e) => {
				console.log("getOrgInvitedMembers error getting invites", e);
			}
		);
};

export const organizationAddNewUser = (
	organization,
	userId,
	role,
	isVerified,
	onSuccess
) => {
	try {
		firestore
			.collection("organizations")
			.doc(organization.id)
			.collection("members")
			.doc(userId)
			.set({
				role: role,
				verified: isVerified ? isVerified : false,
				publicPosts: false,
				postNewOpportunities: false,
				volunteerApplicationReview: false,
				volunteerCheckIn: false,
				viewDonations: false
			})
			.then(() => {
				firestore
					.collection("organizations")
					.doc(organization.id)
					.update({
						members: firebase.firestore.FieldValue.arrayUnion(userId)
					})
					.then(() => {
						onSuccess && onSuccess();
					});
			})
			.catch((error) => {
				console.error("Error adding document: ", error);
			});
	} catch (err) {
		console.log(err);
	}
};

export const inviteMember = (data, onSuccessInvite) => {
	return firestore
		.collection("inviteRequests")
		.add(data)
		.then(() => {
			onSuccessInvite && onSuccessInvite();
		})
		.catch((e) => {
			console.log("error inviting member", e);
			window.alert("Error inviting member", e);
		});
};

export const archiveUser = (orgId, userId, active, invite, onSuccess) => {
	if (invite) {
		return firestore
			.collection("inviteRequests")
			.doc(userId)
			.delete()
			.then(() => {
				onSuccess();
			});
	}

	console.log("here");

	firestore
		.collection("organizations")
		.doc(orgId)
		.update({
			members: active
				? firebase.firestore.FieldValue.arrayUnion(userId)
				: firebase.firestore.FieldValue.arrayRemove(userId)
		})
		.then(() => {
			firestore
				.collection("organizations")
				.doc(orgId)
				.collection("members")
				.doc(userId)
				.update({
					active
				})
				.then(() => {
					onSuccess();
				})
				.catch((e) => {
					console.log("error updating member status", e);
				});
		})
		.catch((e) => {
			console.log("error updating members array", e);
		});
};

export const getOrgFollowers = async (
	organization,
	setFollowers,
	setLoading
) => {
	firestore
		.collection("org_following")
		.where("orgId", "==", organization.id)
		.get()
		.then((querySnapshot) => {
			let followers = [];
			querySnapshot.forEach((doc) => {
				const subDocData = doc.data();
				followers.push(subDocData.userId);
			});
			let promises = followers.map((item, index) => getUsersPromise(item));
			Promise.all(promises).then((users) => {
				const orgFollowers = users.filter((x) => x !== null);
				setFollowers(orgFollowers);
				setLoading(false);
			});
		});
};

//check usernames and custom_urls if existing
export const checkCustomUrls = async (customUrl) => {
	const usernameQuery = await firestore
		.collection("usernames")
		.where("username", "==", customUrl)
		.get();
	console.log("usernameQuery", usernameQuery.size);
	if (usernameQuery.size > 0) return true;
	const customUrlQuery = await firestore
		.collection("custom_urls")
		.doc(customUrl)
		.get();
	console.log("customUrlQuery", customUrlQuery);
	if (customUrlQuery.exists) return true;
	return false;
};

export const saveCustomOrgUrl = async (selectedOrg, customUrl) => {
	let error;
	await firestore
		.collection("custom_urls")
		.doc(customUrl)
		.set({ orgId: selectedOrg.id })
		.catch((e) => {
			error = e;
			console.log("error saving custom url", e);
		});
	await firestore
		.collection("organizations")
		.doc(selectedOrg.id)
		.update({ customUrl })
		.catch((e) => {
			error = e;
			console.log("error saving custom url", e);
		});

	await firestore
		.collection("custom_urls")
		.where("orgId", "==", selectedOrg.id)
		.get()
		.then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				if (doc.id !== customUrl) {
					doc.ref.delete();
				}
			});
		});
	return error;
};

/* Upload cover photo from @data-url */
export const uploadPhoto = async ({ orgId, imageUrl, index = 0, type }) => {
	console.log({ imageUrl });

	const dirs = {
		cover: "orgCoverPhotos/",
		profile: "orgProfilePics/",
		"theme.logo": "logo/"
	};

	const documentProperties = {
		cover: "coverPhoto",
		profile: "profilePic",
		"theme.logo": "theme.logo"
	};

	const imageStorageRef = storage.ref(`${dirs[type]}${orgId}/${index}.jpg`);
	await imageStorageRef.putString(imageUrl, "data_url");
	const imageStorageUrl = await imageStorageRef.getDownloadURL();

	console.log('imageStorageUrl', imageStorageUrl, type);

	const orgRef = doc(firestore, "organizations", orgId);
	await updateDoc(orgRef, { [documentProperties[type]]: imageStorageUrl });

	return {
		orgId,
		coverPhoto: imageStorageUrl
	};
};
