import { Box, IconButton, Modal, Stack } from "@mui/material";
import { Close } from "assets/svgs";
import MuiButton from "components/Common/MuiButton";
import MuiSnackbar from "components/Common/MuiSnackbar";
import MuiTextInput from "components/Common/MuiTextInput";
import { PrimaryText } from "components/Common/PrimaryText";
import { CustomInput } from "components/Inputs/CustomInput";
import { COLORS } from "constants/Colors";
import { useOrganization } from "context/OrganizationContext";
import useMediaQuery from "hooks/useMediaQuery";
import React, { useState } from "react";
import { ActivityIndicator } from "react-native-web";
import { createCustomLink } from "services/custom-link-service";

const INITIAL_STATE = {
  name: "",
  customUrl: "",
};

export default function CreateCustomLinkModal({ open, onClose, onCreateLink }) {
  // context
  const { selectedOrg } = useOrganization();
  const { getResponsiveValue } = useMediaQuery();

  const [customLink, setCustomLink] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState();

  const handleChange = (key, event) => {
    let value = event.target.value;

    if (errors?.[key]) setErrors((prev) => ({ ...prev, [key]: "" }));

    if (key === "customUrl") {
      //remove special characters except numbers and hyphen
      value = value.replace(/[^0-9a-z-]/gi, "");
      //remove multiple hyphens
      value = value.replace(/-{2,}/g, "-");

      //limit to 24 characters
      value = value.slice(0, 24);
    }
    if (key === "name") value = value.slice(0, 24);

    setCustomLink((prev) => ({ ...prev, [key]: value }));
  };

  const checkErrors = () => {
    const newErrors = {};

    if (!customLink?.name) newErrors.name = "Name is required.";
    if (!customLink?.customUrl)
      newErrors.customUrl = "Custom link can't be empty.";

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return true;
    } else return false;
  };

  const handleCreateCustomLink = () => {
    const hasErrors = checkErrors();

    if (hasErrors) return;

    setLoading(true);

    createCustomLink({
      orgId: selectedOrg.id,
      name: customLink?.name,
      customUrl: customLink?.customUrl,
    })
      .then((doc) => {
        onCreateLink(doc);
        handleClose();
        setOpenSnackbar("Custom link created successfully.");
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 412)
          setErrors((prev) => ({ ...prev, customUrl: err.message }));
      })
      .finally((_) => setLoading(false));
  };

  const handleClose = () => {
    onClose?.();
    setCustomLink(INITIAL_STATE);
    setErrors({});
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={muiStyles.container}>
          {/* Header */}
          <Box
            width="100%"
            height={109}
            borderBottom={`1px solid ${COLORS.primary.lightGray}`}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <PrimaryText fontSize={24} fontWeight={900}>
              Create New Link
            </PrimaryText>

            <PrimaryText
              fontSize={16}
              fontWeight={500}
              color={COLORS.primary.gray}
            >
              the people are going to love it!
            </PrimaryText>

            <IconButton sx={muiStyles.close} onClick={handleClose}>
              <Close fill={COLORS.primary.gray} />
            </IconButton>
          </Box>

          {/* Body */}
          <Box mx={{ xs: 20, md: 40 }} mt={40}>
            <MuiTextInput
              label="Custom Link Name"
              required={true}
              placeholder="Enter Name"
              boxProps={{ mb: 20 }}
              onChange={(e) => handleChange("name", e)}
              value={customLink?.name}
              errorMessage={errors?.name}
            />

            <PrimaryText fontSize={16} fontWeight={900}>
              Custom Link URL
              <PrimaryText color={COLORS.primary.error}>*</PrimaryText>
            </PrimaryText>

            <Stack
              direction="row"
              flexWrap="wrap"
              mb={6}
              gap={{ xs: 20, sm: 5 }}
              alignItems="center"
            >
              <PrimaryText
                fontSize={14}
                style={{ width: getResponsiveValue({ xs: "95%", sm: "auto" }) }}
              >
                {`https://donate.rayzeapp.com/${
                  selectedOrg?.customUrl
                    ? selectedOrg?.customUrl
                    : "orgs/" + selectedOrg?.id
                }/`}
              </PrimaryText>

              <CustomInput
                value={customLink?.customUrl}
                onChange={(e) => handleChange("customUrl", e)}
                errorMessage={errors?.customUrl}
                style={{ marginTop: -8 }}
              />
            </Stack>
          </Box>

          {/* Action Buttons */}
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            justifyContent="center"
            spacing={50}
            mt={40}
          >
            <MuiButton
              disabled={loading}
              variant="outlined"
              title="Cancel"
              color="secondary"
              onPress={handleClose}
            />
            <MuiButton
              disabled={loading}
              variant="contained"
              title="Create"
              onPress={handleCreateCustomLink}
            />

            <ActivityIndicator
              animating={loading}
              color={COLORS.primary.lightBlue}
            />
          </Stack>
        </Box>
      </Modal>

      <MuiSnackbar open={openSnackbar} onClose={() => setOpenSnackbar(false)} />
    </>
  );
}

const muiStyles = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    maxWidth: 730,
    height: 430,
    borderRadius: 3,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: `1px solide ${COLORS.primary.lightGray}`,
  },
  close: {
    position: "absolute",
    top: 8,
    right: 8,
  },
};
