import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import { BusinessSidebarData, SidebarData } from "./SidebarData";
import { SubMenu } from "./SubMenu";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useAuth } from "context/AuthContext";
import { logout } from "services/auth-service";
import { useDisplay } from "context/DisplayContext";
import { getOrg, getUsersName } from "utils/helpers";
import { useOrganization } from "context/OrganizationContext";
import { ROLES } from "constants/Data";
import { CustomPicker } from "components/Inputs/CustomPicker";
import SidebarTips from "components/Modals/SidebarTips";
import Welcome from "components/Modals/Welcome";
import { Links } from "constants/Links";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Drawer, IconButton } from "@mui/material";
import useMediaQuery from "hooks/useMediaQuery";
import { LuMenu } from "react-icons/lu";

const SidebarNav = styled.nav`
  background: ${COLORS.primary.offWhite};
  width: ${({ sidebar }) => (sidebar ? "280px" : "60px")};
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 350ms;
  z-index: 10;
  font-family: Montserrat, Arial, serif;
  overflow: scroll;
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid lightgray",
    color: state.isSelected ? COLORS.primary.white : COLORS.primary.darkGray,
    paddingHorizontal: 12,

    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 300,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    width: 115,
    paddingVertical: 0,
    marginVertical: 100,
  }),
  singleValue: (styles) => ({
    ...styles,
    color: COLORS.primary.darkGray,
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 300,
  }),
};

const Sidebar = () => {
  const { currentUser } = useAuth();
  const {
    selectedOrg,
    setSelectedOrg,
    ourOrgOptions,
    userType,
    currentMember,
  } = useOrganization();

  const { showSidebar, setShowSidebar } = useDisplay();
  const navigate = useNavigate();
  const location = useLocation();

  const { getResponsiveValue } = useMediaQuery();
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [subnav, setSubnav] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [activeTip, setActiveTip] = useState("");
  const [hideTips, setHideTips] = useState(true);

  const rayzeLogo = require("assets/rayzeLogo.png");

  function logoutPressed() {
    logout();
    window.location.reload(false);
  }

  useEffect(() => {
    if (!currentMember) return;
    if (currentMember?.hideTips === undefined) {
      setActiveTip("welcome");
      setHideTips(false);
    } else {
      setHideTips(currentMember?.hideTips);
    }
  }, [currentMember]);

  const nav =
    selectedOrg?.type !== "business" ? SidebarData : BusinessSidebarData;

  return (
    <>
      {/** TOP NAV */}
      <Box
        display="flex"
        flexDirection="row"
        px={12}
        backgroundColor={COLORS.primary.offWhite}
        height={80}
        justifyContent={{ xs: "space-between", lg: "flex-end" }}
        alignItems="center"
        ml={{ xs: 0, lg: showSidebar ? 280 : 60 }}
      >
        {/* <TouchableOpacity style={styles.notificationContainer}>
          <FaIcons.FaBell size={20} color={COLORS.primary.white} />
        </TouchableOpacity> */}

        <IconButton
          sx={{ display: { xs: "block", lg: "none" } }}
          onClick={() => setIsShowSidebar(true)}
        >
          <LuMenu size={24} />
        </IconButton>

        <TouchableOpacity
          style={styles.userContainer}
          onPress={() => setShowProfileMenu(!showProfileMenu)}
        >
          <View style={styles.userIcon}>
            {currentUser?.profilePic ? (
              <Image
                source={currentUser?.profilePic}
                style={styles.profilePic}
              />
            ) : (
              <FaIcons.FaUser size={20} color={COLORS.primary.white} />
            )}
          </View>
          <View style={{ flex: 1, marginHorizontal: 24 }}>
            <PrimaryText
              fontSize={20}
              fontWeight={900}
              style={styles.userName}
              numberOfLines={1}
            >
              {getUsersName(currentUser)}
            </PrimaryText>
            {!!userType && (
              <PrimaryText
                fontSize={12}
                fontWeight={400}
                style={styles.status}
                numberOfLines={1}
              >
                {ROLES.find((x) => x.value === userType)?.label}
              </PrimaryText>
            )}
          </View>
          <FiIcons.FiChevronDown size={24} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </Box>

      <Drawer
        open={getResponsiveValue({ xs: isShowSidebar, lg: true })}
        onClose={() => setIsShowSidebar(false)}
        variant={getResponsiveValue({ xs: "temporary", lg: "persistent" })}
      >
        <Box
          bgcolor={COLORS.primary.offWhite}
          width={showSidebar ? "280px" : "60px"}
          height="100vh"
          display="flex"
          justify-content="center"
          position="fixed"
          top={0}
          left={0}
          transition="350ms"
          z-index={10}
          font-family="Montserrat, Arial, serif"
          overflow="scroll"
        >
          <View
            style={{
              width: "100%",
              justifyContent: "space-between",
              paddingBottom: 12,
            }}
          >
            <View>
              <View style={styles.companyInfoContainer}>
                <View
                  style={[
                    styles.rayzeContainer,
                    !showSidebar && { justifyContent: "center" },
                  ]}
                >
                  <Image
                    source={rayzeLogo}
                    style={[
                      styles.rayzeLogo,
                      !showSidebar && {
                        marginBottom: 0,
                        height: 30,
                        width: 60,
                      },
                    ]}
                  />

                  {showSidebar && (
                    <View
                      style={{
                        width: "100%",
                        paddingHorizontal: 12,
                        paddingTop: 12,
                        paddingBottom: 10,
                        borderTopWidth: 1,
                        borderTopColor: COLORS.primary.lightGray,
                      }}
                    >
                      <PrimaryText
                        fontSize={16}
                        fontWeight={800}
                        style={styles.myOrgTitle}
                        numberOfLines={1}
                      >
                        My Organizations
                      </PrimaryText>
                    </View>
                  )}
                </View>

                {showSidebar && (
                  <View style={{ marginHorizontal: 12 }}>
                    <SidebarTips
                      path="sidebar"
                      index={activeTip === "sidebar" ? 0 : activeTip}
                      show={activeTip === "sidebar"}
                      onNext={() => setActiveTip((prev) => 1)}
                    >
                      <CustomPicker
                        styles={customStyles}
                        value={
                          setSelectedOrg && getOrg(ourOrgOptions, selectedOrg)
                        }
                        onChange={(value) =>
                          setSelectedOrg(
                            ourOrgOptions.find((x) => x.value === value).org
                          )
                        }
                        options={ourOrgOptions}
                        label={selectedOrg?.name}
                      />
                    </SidebarTips>
                  </View>
                )}
              </View>

              {nav.map((item, index) => {
                if (selectedOrg?.type === "business" && userType !== "Leader") {
                  if (window.location.pathname !== Links.BusinessDashboard) {
                    navigate(Links.BusinessDashboard);
                  }
                  // return null;
                }

                if (item.path === Links.Settings && userType !== "Leader")
                  return null;
                if (item.path === Links.Verification && userType !== "Leader")
                  return null;
                if (
                  item.path === Links.Verification &&
                  selectedOrg?.type !== "nonprofit"
                )
                  return null;
                if (
                  item.path === Links.Donations &&
                  selectedOrg?.type !== "nonprofit"
                )
                  return null;
                if (
                  item.path === Links.Fundraising &&
                  selectedOrg?.type !== "nonprofit"
                )
                  return null;

                if (
                  item.path === Links.Nonprofits &&
                  selectedOrg?.type === "business" &&
                  !selectedOrg?.stripeComplete
                )
                  return null;

                return (
                  <SidebarTips
                    index={activeTip}
                    path={item.path}
                    show={
                      item?.path === location.pathname &&
                      activeTip !== "welcome" &&
                      activeTip !== "sidebar" &&
                      activeTip !== ""
                    }
                    onNext={() => setActiveTip((prev) => prev + 1)}
                    key={"sidebar-tips-" + index}
                  >
                    <SubMenu
                      onClick={() => setIsShowSidebar(false)}
                      subnav={subnav}
                      setSubnav={setSubnav}
                      item={item}
                      key={index}
                      currentUser={currentUser}
                      showSidebar={showSidebar}
                      setShowSidebar={setShowSidebar}
                      hideBusinessEmployeeNav={selectedOrg?.type === "business" && userType !== "Leader"}
                    />
                  </SidebarTips>
                );
              })}
            </View>

            {/** SHOW SIDEBAR BUTTON */}
            <TouchableOpacity
              style={{
                alignSelf: showSidebar ? "flex-end" : "center",
                marginRight: showSidebar ? 12 : 0,
              }}
              onPress={() => setShowSidebar(!showSidebar)}
            >
              {showSidebar ? (
                <FaIcons.FaChevronLeft
                  size={20}
                  color={COLORS.primary.darkGray}
                />
              ) : (
                <FaIcons.FaChevronRight
                  size={20}
                  color={COLORS.primary.darkGray}
                />
              )}
            </TouchableOpacity>
          </View>
        </Box>
      </Drawer>

      {showProfileMenu && (
        <View style={styles.profileMenu}>
          <TouchableOpacity style={styles.menuButton} onPress={logoutPressed}>
            <PrimaryText
              fontSize={12}
              fontWeight={300}
              style={styles.userName}
              numberOfLines={1}
            >
              Logout
            </PrimaryText>
            <FaIcons.FaSignOutAlt size={20} color={COLORS.primary.darkGray} />
          </TouchableOpacity>
        </View>
      )}

      {activeTip === "welcome" && !hideTips && (
        <Welcome onPress={() => setActiveTip("sidebar")} />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  companyInfoContainer: {
    marginVertical: 12,
    // marginHorizontal: 12,
    zIndex: 500,
  },
  rayzeContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  rayzeLogo: {
    height: 92,
    width: 132,
    resizeMode: "contain",
    marginVertical: 30,
  },

  companyName: {
    fontSize: 16,
    color: COLORS.primary.black,
    marginVertical: 12,
    textAlign: "center",
  },
  showSidebarButton: {
    alignSelf: "center",
  },

  //top bar
  topBar: {
    flexDirection: "row",
    paddingHorizontal: 12,
    backgroundColor: COLORS.primary.offWhite,
    height: 80,
    justifyContent: "flex-end",
    alignItems: "center",

    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
  },
  notificationContainer: {
    backgroundColor: COLORS.primary.green,
    height: 36,
    width: 36,
    borderRadius: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  userContainer: {
    flexDirection: "row",
    // justifyContent:'space-around',
    alignItems: "center",
  },
  userIcon: {
    backgroundColor: COLORS.primary.darkGray,
    height: 36,
    width: 36,
    borderRadius: 18,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  profilePic: {
    width: "100%",
    height: "100%",
    resizeMode: "cover",
  },
  userName: {
    fontSize: 14,
    lineHeight: 24,
    color: COLORS.primary.darkGray,
  },
  status: {
    fontSize: 12,
    color: COLORS.primary.lightBlue,
  },
  myOrgTitle: {
    fontSize: 16,
    lineHeight: 24,
    color: COLORS.primary.darkBlue,
    textAlign: "left",
    width: "100%",
  },
  profileMenu: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: COLORS.primary.white,
    right: 0,
    top: 80,
    height: 40,
    width: 200,
    paddingHorizontal: 12,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3.5,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  menuButton: {
    flexDirection: "row",
    width: "100%",
    height: 40,
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default Sidebar;
