import { Box } from "@mui/material";
import SaveAndCancelBtns from "components/Buttons/SaveAndCancelBtns";
import GooglePlaceInput from "components/Common/GooglePlaceInput";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React, { useMemo, useState } from "react";
import { FiEdit, FiInfo } from "react-icons/fi";
import { TouchableOpacity, View } from "react-native-web";

export default function EditableAddress({
  property,
  onEdit,
  onSave,
  onCancel,
  title,
  editing,
  required,
  disabled,
  maxWidth,
  campaign,
  selectedAddress,
  disabledText,
}) {
  editing = editing === property;

  const [newAddress, setNewAddress] = useState();

  const address = useMemo(() => {
    const { line1, city, state, zipCode } = selectedAddress || {};
    return `${line1 ? capitalize(line1) + ", " : ""}${capitalize(
      city
    )}, ${capitalize(state)} ${zipCode || ""}`;
  }, [selectedAddress]);

  function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    <>
      <View
        style={{
          opacity: disabled ? 0.4 : 1,
          maxWidth,
        }}
      >
        <PrimaryText
          fontSize={campaign ? 20 : 17}
          fontWeight={campaign ? 700 : 600}
          style={[
            { marginBottom: 3 },
            campaign && {
              color: COLORS.primary.neutral_500,
              marginLeft: 8,
              marginTop: 4,
            },
          ]}
        >
          {title}
          {required && <span style={{ color: "red" }}>*</span>}
        </PrimaryText>

        {/* selectedAddress */}
        {!editing && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 16,
              maxWidth: 330,
            }}
          >
            <PrimaryText
              fontSize={17}
              fontWeight={900}
              style={[{ marginRight: 16 }, campaign && { marginLeft: 8 }]}
            >
              {address}
            </PrimaryText>
            <TouchableOpacity
              onPress={() => onEdit(property)}
              disabled={disabled}
            >
              {disabledText && disabled ? (
                <FiInfo size={17} data-tooltip-id={`tooltip-${title}`} />
              ) : (
                <FiEdit size={17} />
              )}
            </TouchableOpacity>
          </View>
        )}
      </View>

      {/* render while editing */}
      {!!editing && (
        <Box display="flex" flexDirection="column" mt={6} mb={16}>
          <GooglePlaceInput
            selectedAddress={selectedAddress}
            placeholder=" "
            styles={{
              container: {
                marginTop: 0,
              },
              control: {
                backgroundColor: COLORS.primary.neutral,
                borderWidth: 0.5,
              },
            }}
            boxProps={{ width: "100%", mt: 0 }}
            onChange={(address) => setNewAddress(address)}
          />

          <PrimaryText
            fontSize={12}
            fontWeight={300}
            color={COLORS.primary.darkBlue}
          >
            *This address cannot be a PO Box
          </PrimaryText>

          <SaveAndCancelBtns
            boxProps={{ mt: 7 }}
            onSave={() => onSave(property, newAddress)}
            onCancel={onCancel}
          />
        </Box>
      )}
    </>
  );
}
