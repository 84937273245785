import { useCauses } from "context/CausesContext";
import React, { useState, createContext, useEffect, useContext } from "react";
import { fetchMoreOrgs, getAllNonprofits } from "services/businesses/business-nonprofit-service";
import { useOrganization } from "../OrganizationContext";
import { causes } from "constants/Data";

export const BusinessNonprofitsContext = createContext();

export const BusinessNonprofitsProvider = ({ children }) => {
  const { allCauses } = useCauses();

  const [getNonprofits, setGetNonprofits] = useState(false);
  const [allNonprofits, setAllNonprofits] = useState([]);
  const [selectedNonprofit, setSelectedNonprofit] = useState(null);

  const [lastDoc, setLastDoc] = useState(null);
  const [orgs, setOrgs] = useState([]);
  const [getOrgs, setGetOrgs] = useState(false);
  const [page, setPage] = useState(1);
  

  useEffect(() => {
    if (!getNonprofits) return;
    console.log('here')
    getAllNonprofits(causes, setAllNonprofits);
  }, [getNonprofits]);

  useEffect(() => {
    if (getOrgs) {
      fetchMoreOrgs(lastDoc, setLastDoc, orgs, setOrgs, "nonprofit", causes);
    }
  }, [getOrgs]);

  function gotOrgs(orgs) {
    const filtered = orgs.filter(x => x.verified)
    setOrgs(filtered);
  }

  function nextPage() {
    console.log('next page')
    // fetchMoreOrgs(lastDoc, setLastDoc, orgs, setOrgs, "nonprofit", causes);
    setPage(page + 1);
  }

  return (
    <BusinessNonprofitsContext.Provider
      value={{
        setGetNonprofits,
        allNonprofits,
        setAllNonprofits,
        orgs,
        setOrgs,
        getOrgs,
        setGetOrgs,
        page,
        setPage,
        nextPage,
        selectedNonprofit,
        setSelectedNonprofit,

      }}
    >
      {children}
    </BusinessNonprofitsContext.Provider>
  );
};

export const useBusinessNonprofits = () =>
  useContext(BusinessNonprofitsContext);
