import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { COLORS } from "constants/Colors";
import { CustomInput } from "components/Inputs/CustomInput";
import { PrimaryText } from "components/Common/PrimaryText";
import { FiUserPlus } from "react-icons/fi";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { getUser, updateMemberInfo } from "services/user-service";
import { FullModal } from "components/Modals/FullModal";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  getUsersName,
  validateEmail,
  validateEmailDoesntExist,
} from "utils/helpers";
import { useOrganization } from "context/OrganizationContext";
import { ROLES } from "constants/Data";
import { RiAlertFill } from "react-icons/ri";
import { archiveUser, inviteMember } from "services/organizations-service";
import { useAuth } from "context/AuthContext";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { CustomSwitch } from "components/Inputs/CustomSwitch";
import { Button } from "components/Common/Button";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { BusinessEmployeeCard } from "components/Cards/Business/BusinessEmployeeCard";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { BiLink } from "react-icons/bi";
import { LiaInfoCircleSolid } from "react-icons/lia";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DeactiveLinkConfirmation from "components/Modals/DeactiveLinkConfirmation";
import MuiTablePagination from "components/Common/MuiTablePagination";
import {
  checkInviteLinkExists,
  deleteInviteLink,
  generateInviteLink,
  getInvite,
} from "services/invite-service";
import { MuiChipsInput } from "mui-chips-input";
import { useUsers } from "context/UserContext";
import useMediaQuery from "hooks/useMediaQuery";

const options = [
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <Box sx={{ p: 0 }}>{children}</Box>}</div>;
}

export function Employees() {
  const { getResponsiveValue } = useMediaQuery();
  const navigate = useNavigate();
  const { selectedOrg, members, users, invitedMembers, userType } =
    useOrganization();
  const { setSelectedUser: setSelectedEmployee } = useUsers();
  const { currentUser } = useAuth();

  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedView, setSelectedView] = useState(options[0]?.value);
  const [ascending, setAscending] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [sorted, setSorted] = useState([]);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  //user add and edit
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState(null);
  const [invitePosition, setInvitePosition] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [inviteLink, setInviteLink] = useState(null);

  const [inviteEmails, setInviteEmails] = useState([]);

  const [isDeactivateConfirmation, setIsDeactivateConfirmation] =
    useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  //on search change
  useEffect(() => {
    if (search) {
      const temp = users.filter(
        (x) =>
          x.firstName.toLowerCase().includes(search.toLowerCase()) ||
          x.lastName.toLowerCase().includes(search.toLowerCase())
      );

      setFiltered(temp);
    } else {
      setFiltered(users);
    }
  }, [search, users]);

  useEffect(() => {
    if (filtered) {
      let temp = cloneDeep(filtered);

      switch (selectedView) {
        case "active":
          temp = temp.filter((x) => x.active);
          break;
        case "archived":
          temp = temp.filter((x) => !x.active);
          break;
        default:
          break;
      }

      temp.sort((a, b) => sortObject(a.firstName, b.firstName));

      setSorted(temp);
    }
  }, [selectedView, ascending, filtered]);

  useEffect(() => {
    if (!showEditUser && !showConfirmModal) {
      setSelectedUser(null);
      setLoading(false);
    }
  }, [showEditUser, showConfirmModal]);

  useEffect(() => {
    setEmailError(null);
    setEmailError(
      !validateEmail(inviteEmail) ||
        validateEmailDoesntExist(inviteEmail, users)
    );
  }, [inviteEmail]);

  useEffect(() => {
    setIsLinkCopied(false);

    if (showInviteUser) {
      if (!selectedOrg) return;
      checkInvite();
    }
  }, [showInviteUser]);

  async function checkInvite() {
    let invite = await checkInviteLinkExists(selectedOrg.id);
    if (invite) {
      setInviteLink(invite);
    } else {
      invite = await generateInviteLink(selectedOrg.id);
      setInviteLink(invite);
    }
  }

  function sortObject(a, b) {
    if (a === b) return 0;

    if (!ascending) {
      return a < b ? 1 : -1;
    } else {
      return a < b ? -1 : 1;
    }
  }

  function onArchiveUser(user) {
    setSelectedUser(user);
    setShowConfirmModal(true);
  }

  function archive() {
    setLoading(true);
    archiveUser(
      selectedOrg.id,
      selectedUser.id,
      !selectedUser.active,
      selectedUser?.invite,
      onSuccessUpdate
    );
  }

  function inviteUser() {
    setLoading(true);
    //invite users via email
    const promises = inviteEmails.map((email) => {
      const data = {
        orgId: selectedOrg.id,
        orgName: selectedOrg.name,
        role: inviteRole || "Employee",
        position: invitePosition,
        email,
      };
      inviteMember(data);
    });
    Promise.all(promises).then(onSuccessInvite).catch(onSuccessInvite);
  }

  function onSuccessInvite() {
    setShowInviteUser(false);
    setLoading(false);
    setInviteRole(null);
    setInviteEmail(null);
    setInvitePosition(null);
    window.alert("Invite successfully sent");
  }

  function editUser(user) {
    setSelectedUser(user);
    setShowEditUser(true);
  }

  function onChangeUserInfo(prop, value) {
    const temp = cloneDeep(selectedUser);
    temp[prop] = value;
    setSelectedUser(temp);
    updateMemberInfo(
      selectedOrg,
      selectedUser,
      { [prop]: value },
      onSuccessUpdate
    );
  }

  useEffect(() => {
    setEmailError(null);

    if (showEditUser === "edit") {
      setSubmitDisabled(!selectedUser);
    } else {
      //adding user
      setSubmitDisabled(
        !validateEmail(selectedUser?.email) ||
          validateEmailDoesntExist(selectedUser?.email, users)
      );
    }
  }, [selectedUser]);

  function userSelected(donation) {
    let userId = donation?.id;
    console.log("test", userId);
    if (!userId) return;
    getUser(userId, onGotUser);
  }

  function onGotUser(user) {
    setSelectedEmployee(user);
    navigate(Links.DonorDetails);
  }

  function onSuccessUpdate() {
    setShowConfirmModal(false);
    setLoading(false);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentTableData = () => {
    const data = sorted.concat(invitedMembers);
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return data.slice(firstPageIndex, lastPageIndex);
  };

  const styles = getStyles(getResponsiveValue);

  return (
    <>
      <ShadowContainer style={styles.container}>
        {/** HEADER */}
        <PrimaryText fontSize={32} fontWeight={900} style={[styles.title]}>
          Employees
        </PrimaryText>
        <View
          style={{
            zIndex: 999,
            flexDirection: getResponsiveValue({ xs: "column", md: "row" }),
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 12,
            gap: 20,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              flexGrow: 1,
              width: getResponsiveValue({ xs: "100%", md: "60%" }),
              justifyContent: getResponsiveValue({
                xs: "center",
                md: "flex-start",
              }),
            }}
          >
            <CustomInput
              placeholder={"Search"}
              style={{ flexGrow: 1, maxWidth: 400 }}
              onChangeText={setSearch}
              value={search}
              backgroundColor={COLORS.primary.white}
            />
          </View>
          <View
            style={{
              flexDirection: getResponsiveValue({ xs: "column", sm: "row" }),
              alignItems: "center",
              gap: 12,
            }}
          >
            {userType === "Leader" && (
              <Button
                title={"Invite Employee"}
                onPress={() => setShowInviteUser(true)}
                iconRight={
                  <FiUserPlus size={18} color={COLORS.primary.white} />
                }
              />
            )}
            <PrimaryText
              fontSize={14}
              fontWeight={800}
              style={{ color: COLORS.primary.darkGray, marginHorizontal: 4 }}
            >
              View
            </PrimaryText>

            <CustomPicker
              value={selectedView}
              onChange={(value) => setSelectedView(value)}
              options={options}
            />
          </View>
        </View>

        {/** TABLE */}
        <TableContainer>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ textAlign: "left" }}>NAME</TableCell>
                <TableCell>ROLE</TableCell>
                {/* <TableCell>JOB TITLE</TableCell> */}
                <TableCell>EMAIL</TableCell>
                {userType === "Leader" && (
                  <>
                    <TableCell>Edit</TableCell>
                    <TableCell>
                      {selectedView === "active" ? "Archive" : "Unarchive"}
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {currentTableData() &&
                currentTableData().map((item, index) => {
                  return (
                    <BusinessEmployeeCard
                      key={item.id}
                      item={item}
                      onArchive={() => onArchiveUser(item)}
                      onEdit={() => editUser(item)}
                      isCurrentUser={currentUser?.id === item?.id}
                      currentUserRole={userType}
                      onPress={() => userSelected(item)}
                    />
                  );
                })}
            </TableBody>
          </Table>

          <MuiTablePagination
            count={sorted.concat(invitedMembers).length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={(_, page) => setCurrentPage(page)}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(e.target.value);
              setCurrentPage(0);
            }}
            setCurrentPage={setCurrentPage}
          />
        </TableContainer>

        {/** EDIT USER */}
        {showEditUser && (
          <FullModal
            title={"Edit Team Member"}
            setShowModal={setShowEditUser}
            minHeight={500}
          >
            <View style={{ flex: 1 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: COLORS.primary.lightBlue,
                  },
                }}
              >
                <Tab
                  label="Permissions"
                  style={{
                    color: COLORS.primary.lightBlue,
                    fontSize: 12,
                    fontWeight: 700,
                    fontFamily: "Montserrat",
                  }}
                />
                {/* <Tab
                  label="Notifications"
                  style={{
                    color: COLORS.primary.lightBlue,
                    fontSize: 12,
                    fontWeight: 700,
                    fontFamily: "Montserrat",
                  }}
                /> */}
              </Tabs>
              <View
                style={{
                  height: 2,
                  backgroundColor: COLORS.primary.lightGray,
                  marginTop: -2,
                  zIndex: -1,
                }}
              />

              {/** PERMISSIONS */}
              <TabPanel value={value} index={0}>
                <View style={{ flex: 1 }}>
                  <PrimaryText
                    fontSize={20}
                    fontWeight={800}
                    style={{
                      textAlign: "center",
                      marginVertical: 12,
                    }}
                  >
                    {selectedUser?.firstName
                      ? getUsersName(selectedUser)
                      : selectedUser?.email}
                  </PrimaryText>
                </View>
                <View style={styles.roleRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={900}
                    style={{ flex: 1 }}
                  >
                    Role
                  </PrimaryText>
                  <CustomPicker
                    // style={{ maxWidth: 170 }}
                    value={selectedUser?.role}
                    onChange={(value) => onChangeUserInfo("role", value)}
                    options={ROLES}
                  />
                </View>

                {/* <View style={styles.roleRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={900}
                    style={{ flex: 1 }}
                  >
                    Position
                  </PrimaryText>
                  <CustomPicker
                    // style={{ maxWidth: 170 }}
                    value={selectedUser?.position}
                    onChange={(value) => onChangeUserInfo("position", value)}
                    options={POSITIONS}
                  />
                </View> */}

                {selectedOrg?.type === "nonprofit" && (
                  <View style={styles.settingsRow}>
                    <PrimaryText
                      fontSize={16}
                      fontWeight={400}
                      style={styles.setting}
                    >
                      View Donations
                    </PrimaryText>
                    <CustomSwitch
                      value={
                        selectedUser?.viewDonations !== undefined
                          ? selectedUser?.viewDonations
                          : true
                      }
                      onValueChange={(value) =>
                        onChangeUserInfo("viewDonations", value)
                      }
                    />
                  </View>
                )}

                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Create Public Posts
                  </PrimaryText>

                  <CustomSwitch
                    value={
                      selectedUser?.publicPosts !== undefined
                        ? selectedUser?.publicPosts
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("publicPosts", value)
                    }
                  />
                </View>
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Post New Events
                  </PrimaryText>
                  <CustomSwitch
                    value={
                      selectedUser?.postNewOpportunities !== undefined
                        ? selectedUser?.postNewOpportunities
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("postNewOpportunities", value)
                    }
                  />
                </View>
                {/* <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Volunteer Application Review
                  </PrimaryText>
                  <CustomSwitch
                    value={
                      selectedUser?.volunteerApplicationReview !== undefined
                        ? selectedUser?.volunteerApplicationReview
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("volunteerApplicationReview", value)
                    }
                  />
                </View> */}
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Attendee Check-in
                  </PrimaryText>
                  <CustomSwitch
                    value={
                      selectedUser?.volunteerCheckIn !== undefined
                        ? selectedUser?.volunteerCheckIn
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("volunteerCheckIn", value)
                    }
                  />
                </View>
              </TabPanel>

              {/** NOTIFICATIONS */}
              <TabPanel value={value} index={1}>
                <View style={{ flex: 1 }}>
                  <PrimaryText
                    fontSize={20}
                    fontWeight={800}
                    style={{
                      textAlign: "center",
                      marginTop: 12,
                    }}
                  >
                    {getUsersName(selectedUser)}
                  </PrimaryText>
                </View>
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    New Application Received
                  </PrimaryText>
                  <CustomSwitch
                    value={selectedUser?.receiveNewApplications}
                    onValueChange={(value) =>
                      onChangeUserInfo("receiveNewApplications", value)
                    }
                  />
                </View>
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Post Comments
                  </PrimaryText>
                  <CustomSwitch
                    value={selectedUser?.receivePostComments}
                    onValueChange={(value) =>
                      onChangeUserInfo("receivePostComments", value)
                    }
                  />
                </View>
              </TabPanel>
            </View>

            {/* <PrimaryText fontSize={16} fontWeight={500} >New Location Name</PrimaryText> */}
          </FullModal>
        )}

        {/** INVITE USER */}
        {showInviteUser && (
          <FullModal
            title="Invite Employees"
            subtitle="(the more the merrier)"
            setShowModal={setShowInviteUser}
          >
            <View
              style={{
                width: "100%",
                // maxWidth: 600,
                backgroundColor: COLORS.primary.white,
              }}
            >
              <PrimaryText
                fontSize={16}
                fontWeight={900}
                style={{ textAlign: "center" }}
              >
                Email Addresses
              </PrimaryText>
              {/* <CustomInput
                placeholder={"Enter Email Address"}
                style={{ minWidth: 524, marginTop: 6, marginHorizontal: 80 }}
                onChangeText={setInviteEmail}
                value={inviteEmail}
              /> */}

              <View style={{ paddingHorizontal: 20 }}>
                <MuiChipsInput
                  // label="Enter email addresses and press enter"
                  value={inviteEmails}
                  onChange={setInviteEmails}
                  style={{
                    width: "100%",
                    marginTop: 6,
                    borderWidth: 0.5,
                    borderRadius: 15,
                    borderColor: COLORS.primary.lightGray,
                  }}
                  InputProps={{
                    style: {
                      fontSize: 14,
                      color: COLORS.primary.darkGray,
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                    },
                  }}
                  hideClearAll
                  validate={(chipValue) => {
                    return {
                      isError:
                        !validateEmail(chipValue) ||
                        validateEmailDoesntExist(chipValue, users) ||
                        inviteEmails.includes(chipValue),
                      textError: inviteEmails.includes(chipValue)
                        ? "Email already exists in list"
                        : validateEmailDoesntExist(chipValue, users)
                        ? "Email already exists"
                        : "Invalid email",
                    };
                  }}
                />
              </View>

              {/* <TouchableOpacity style={{ alignSelf: "center", marginTop: 20 }}>
                <AiOutlinePlusCircle size={30} />
              </TouchableOpacity> */}

              <View style={styles.btnRow}>
                <View>
                  <View style={styles.textRow}>
                    <LiaInfoCircleSolid
                      size={12}
                      color={COLORS.primary.neutral_500}
                    />

                    <PrimaryText
                      fontSize={10}
                      fontWeight={500}
                      style={{
                        color: COLORS.primary.neutral_500,
                        marginLeft: 2,
                      }}
                    >
                      Invite employees with this link
                    </PrimaryText>
                  </View>

                  <PrimaryButton
                    disabled={isLinkCopied}
                    title={isLinkCopied ? "Link Copied" : "Copy Invite Link"}
                    variant="outlined"
                    icon={
                      <BiLink
                        color={isLinkCopied && COLORS.primary.blue}
                        size={20}
                      />
                    }
                    onPress={() => {
                      navigator.clipboard.writeText(inviteLink);
                      setIsLinkCopied(true);
                    }}
                    style={{
                      paddingHorizontal: 24,
                      borderColor: isLinkCopied && COLORS.primary.blue,
                      marginBottom: 0,
                    }}
                    titleStyle={{
                      color: isLinkCopied && COLORS.primary.blue,
                    }}
                  />
                </View>

                {/* We've to hide this button if there is no link generated. */}
                <PrimaryButton
                  title="Deactivate Link"
                  variant="link"
                  style={{
                    paddingHorizontal: 0,
                    marginLeft: 15,
                    marginBottom: 0,
                  }}
                  titleStyle={{ fontSize: 12 }}
                  onPress={() => setIsDeactivateConfirmation(true)}
                />

                <PrimaryButton
                  title="Send"
                  style={{ width: 92, marginBottom: 0 }}
                  onPress={inviteUser}
                  disabled={inviteEmails.length === 0}
                />
              </View>
            </View>
          </FullModal>
        )}

        {/* Deactive Link Confirmation Modal */}
        {isDeactivateConfirmation && (
          <DeactiveLinkConfirmation
            setShow={setIsDeactivateConfirmation}
            onDeactivate={() => {
              setInviteLink(null);
              setIsLinkCopied(false);
              deleteInviteLink(selectedOrg.id);
              setIsDeactivateConfirmation(false);
              setShowInviteUser(false);
            }}
          />
        )}

        {showConfirmModal && (
          <ConfirmModal
            title={`${selectedUser?.active ? "Archive" : "Unarchive"} ${
              selectedUser?.firstName
                ? selectedUser?.firstName + " " + selectedUser?.lastName
                : selectedUser?.email
            }`}
            message={"Are you sure?"}
            onConfirm={() => archive()}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
      </ShadowContainer>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </>
  );
}

const getStyles = (getResponsiveValue) =>
  StyleSheet.create({
    activity: {
      width: "100%",
      height: "100%",
      backgroundColor: COLORS.primary.white,
      position: "absolute",
      top: 0,
      bottom: 0,
    },
    container: {
      flex: 1,
      width: "100%",
      flexDirection: "column",
      backgroundColor: COLORS.primary.offWhite,
    },
    title: {
      marginBottom: 12,
    },
    button: {
      flexDirection: "row",
      backgroundColor: COLORS.primary.lightBlue,
      // height: 30,
      justifyContent: "center",
      alignItems: "center",
      paddingHorizontal: 20,
      paddingVertical: 6.5,
      marginRight: 12,
      borderRadius: 20,
    },
    body: {
      flex: 1,
      width: "100%",
      backgroundColor: COLORS.primary.white,
      marginTop: 12,
      alignItems: "center",
    },

    settingsRow: {
      flexDirection: "row",
      paddingVertical: 8,
      paddingHorizontal: 20,
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomColor: COLORS.primary.lightGray,
      borderBottomWidth: 0.5,
    },
    roleRow: {
      flexDirection: "row",
      paddingVertical: 8,
      paddingHorizontal: 20,
      alignItems: "center",
      justifyContent: "space-between",
    },
    setting: {},
    errorContainer: {
      backgroundColor: "#FEEFEF",
      height: 32,
      width: 524,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingHorizontal: 16,
      paddingVertical: 6,
      borderRadius: 8,
      flexDirection: "row",
      margin: 12,
      alignSelf: "center",
    },
    errorMessage: {
      color: COLORS.primary.error,
      paddingLeft: 8,
    },
    btnRow: {
      flexDirection: getResponsiveValue({ xs: "column", sm: "row" }),
      justifyContent: getResponsiveValue({ xs: "center", sm: "space-between" }),
      paddingHorizontal: 20,
      marginTop: 24,
      alignItems: getResponsiveValue({ xs: "center", sm: "flex-end" }),
      gap: 12,
    },
    btnRow1: {
      flexDirection: getResponsiveValue({ xs: "column", sm: "row" }),
      alignItems: getResponsiveValue({ xs: "center", sm: "row" }),
      justifyContent: "flex-start",
    },
    textRow: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 7,
      marginLeft: 9,
    },
  });
