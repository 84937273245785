import { Stack } from "@mui/material";
import { Files } from "assets/svgs";
import EditableAddressSquare from "components/Common/EditableAddressSquare";
import MuiButton from "components/Common/MuiButton";
import { PrimaryText } from "components/Common/PrimaryText";
import TextInputSquare from "components/Inputs/TextInputSquare";
import UploadPhotoModal from "components/Modals/UploadPhotoModal";
import { COLORS } from "constants/Colors";
import React, { useState } from "react";
import { Image } from "react-native-web";

export default function CompanyProfile({ selectedOrg }) {
  const [coverPhotoModal, setCoverPhotoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    ...(selectedOrg || {}),
  });

  const handleChange = (e) => {
    const { name, value } = e?.target || {};
    setProfileDetails((prev) => ({
      ...(prev || {}),
      [name]: value,
    }));
  };

  const handleAddress = (address) => {
    setProfileDetails((prev) => ({
      ...(prev || {}),
      ...address,
    }));
  };

  const handleCoverUpload = (dataUrl) => {
    let coverPhoto = profileDetails?.coverPhoto || [];

    if (typeof profileDetails?.coverPhoto === "string")
      coverPhoto = [coverPhoto];

    setProfileDetails((prev) => ({
      ...(prev || {}),
      coverPhoto: [...coverPhoto, dataUrl],
    }));

    setCoverPhotoModal(false);
  };

  return (
    <Stack px={20}>
      <PrimaryText fontSize={24}>Company Profile Details</PrimaryText>

      {/* Cover Photo */}
      <PrimaryText fontSize={16} fontWeight={600} style={{ marginTop: 34 }}>
        Profile Cover Photo
        <span style={{ color: COLORS.primary.error }}>*</span>
      </PrimaryText>

      <PrimaryText fontSize={14} fontWeight={200}>
        This will appear on your companies profile in the app
      </PrimaryText>

      <Stack
        direction="row"
        alignItems="center"
        gap={20}
        mt={12}
        flexWrap="wrap"
      >
        {typeof profileDetails?.coverPhoto === "object"
          ? profileDetails?.coverPhoto?.map((item, index) => (
              <Image
                key={"cover" + index}
                source={{ uri: item }}
                style={{ width: 258, height: 171, borderRadius: 10 }}
              />
            ))
          : !!profileDetails?.coverPhoto && (
              <Image
                source={{ uri: profileDetails?.coverPhoto }}
                style={{ width: 258, height: 171, borderRadius: 10 }}
              />
            )}
        {/* Cover Upload */}
        <Stack
          alignItems="center"
          width="100%"
          maxWidth={258}
          height={171}
          border={`1px dashed ${COLORS.primary.darkBlue}`}
          borderRadius="10px"
          justifyContent="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setCoverPhotoModal(true)}
        >
          <Files width={24} fill={COLORS.primary.darkBlue} />
          <PrimaryText
            fontSize={12}
            style={{ marginTop: 10, textAlign: "center" }}
          >
            Click here to upload a new{"\n"} cover photo
          </PrimaryText>

          <PrimaryText
            fontSize={10}
            fontWeight={200}
            style={{ textAlign: "center", marginTop: 10 }}
          >
            Supported format: PNG, JPG, PDF{"\n"} (10mb Max) 16x9 resolution
          </PrimaryText>
        </Stack>
      </Stack>

      <Stack mt={40} gap={20} maxWidth={442}>
        <TextInputSquare
          required={true}
          label="Organization Name"
          value={profileDetails?.name}
          onChange={handleChange}
          name="name"
        />
        <EditableAddressSquare
          selectedAddress={profileDetails?.address}
          required={true}
          label="Address"
          captionBottom="Cannot be a P.O. Box"
          captionRight="(Match IRS Documents)"
          value={profileDetails?.address}
          onChange={handleAddress}
        />

        <TextInputSquare
          required={true}
          label="Phone Number"
          value={profileDetails?.phone}
          onChange={handleChange}
          name="phone"
        />
        <TextInputSquare
          required={true}
          label="Employer Identification Number (EIN)"
          value={profileDetails?.ein}
          onChange={handleChange}
          name="ein"
        />
        <TextInputSquare
          required={true}
          label="Website URL"
          value={profileDetails?.website}
          onChange={handleChange}
          name="website"
        />
        <TextInputSquare
          required={true}
          label="About"
          multiline={true}
          value={profileDetails?.about}
          onChange={handleChange}
          name="about"
        />

        <Stack
          direction="row"
          alignItems="center"
          mt={12}
          width="100%"
          gap={44}
          mb={44}
        >
          <MuiButton
            title="Save Changes"
            variant="contained"
            sx={{ paddingY: 6, fontSize: 14, fontWeight: 700 }}
          />
          <MuiButton
            title="Cancel"
            variant="outlined"
            color="secondary"
            sx={{ paddingY: 6, fontSize: 14, fontWeight: 700 }}
          />
        </Stack>
      </Stack>

      {!!coverPhotoModal && (
        <UploadPhotoModal
          onCancel={() => setCoverPhotoModal(false)}
          isOpen={true}
          title="Cover Photo"
          onSave={(image) => handleCoverUpload(image)}
          loading={loading}
        />
      )}
    </Stack>
  );
}
