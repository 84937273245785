import { Box, IconButton, Modal, Radio, Stack } from "@mui/material";
import MuiButton from "components/Common/MuiButton";
import MuiCheckboxBlue from "components/Common/MuiCheckboxBlue";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import moment from "moment";
import React, { useRef, useState } from "react";
import { LuChevronRight, LuCircle, LuCircleCheck, LuX } from "react-icons/lu";
import { TouchableOpacity } from "react-native-web";

export default function ExportDonationModal({ open, onClose }) {
  const startDateRef = useRef();
  const endDateRef = useRef();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleDatePicker = ({ start, end }) => {
    if (start) startDateRef.current.showPicker();
    else if (end) endDateRef.current.showPicker();
  };

  return (
    <Modal open={open}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="100%"
          maxWidth={458}
          minHeight={100}
          bgcolor={COLORS.primary.white}
          position="relative"
          borderRadius={6}
          py={24}
          px={30}
          display="flex"
          flexDirection="column"
          mx={12}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 12,
              right: 12,
            }}
            onClick={onClose}
          >
            <LuX size={24} />
          </IconButton>

          <PrimaryText
            fontSize={17}
            fontWeight={900}
            style={{ textAlign: "center", width: "100%" }}
          >
            Export Donation Data
          </PrimaryText>

          <PrimaryText
            fontSize={18}
            fontWeight={900}
            style={{ marginTop: 20, marginBottom: 8 }}
          >
            Donation Type
          </PrimaryText>

          {[
            "Employee Matches",
            "Direct Donations",
            "Deposits to Rayze Wallet",
            "Employee Gifts",
          ].map((item, index) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              key={index}
            >
              <PrimaryText fontSize={16}>{item}</PrimaryText>
              <MuiCheckboxBlue />
            </Stack>
          ))}

          <PrimaryText
            fontSize={18}
            fontWeight={900}
            style={{ marginTop: 20, marginBottom: 8 }}
          >
            Date
          </PrimaryText>

          {[
            "All Time",
            "Past 30 Days",
            "Past 90 Days",
            "Past 6 Months",
            "Past Year",
            "2024",
            "YTD",
            "Custom",
          ].map((item, index) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              key={index}
            >
              <PrimaryText fontSize={16}>{item}</PrimaryText>
              <Radio
                checkedIcon={<LuCircleCheck size={20} />}
                icon={<LuCircle size={20} />}
              />
            </Stack>
          ))}

          <PrimaryText
            fontSize={18}
            fontWeight={900}
            style={{ marginTop: 20, marginLeft: 10 }}
          >
            Specific Date Range
          </PrimaryText>

          <Stack direction="row" alignItems="center" mt={8} gap={26}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
              maxWidth={300}
              boxShadow="0px 0px 5px 0px #7C7C7C4D"
              height={44}
              borderRadius={2.5}
            >
              <input
                type="date"
                style={{ visibility: "hidden", width: 1 }}
                ref={startDateRef}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Stack
                flex={1}
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDatePicker({ start: true })}
              >
                <PrimaryText fontSize={16}>
                  {startDate
                    ? moment(startDate).format("DD/MM/YYYY")
                    : "Start Date"}
                </PrimaryText>
              </Stack>

              <LuChevronRight color={COLORS.primary.darkBlue} size={20} />

              <input
                type="date"
                style={{ visibility: "hidden", width: 1 }}
                ref={endDateRef}
                onChange={(e) => setEndDate(e.target.value)}
              />

              <Stack
                flex={1}
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => handleDatePicker({ end: true })}
              >
                <PrimaryText fontSize={16}>
                  {endDate ? moment(endDate).format("DD/MM/YYYY") : "End Date"}
                </PrimaryText>
              </Stack>
            </Stack>

            <TouchableOpacity
              onPress={() => {
                setStartDate();
                setEndDate();
              }}
            >
              <PrimaryText fontSize={16} fontWeight={600}>
                Reset
              </PrimaryText>
            </TouchableOpacity>
          </Stack>

          <Stack alignItems="center" mt={15}>
            <PrimaryText fontSize={12} style={{ textAlign: "center" }}>
              Report will be sent to your user account email
            </PrimaryText>

            <PrimaryText fontSize={12}>
              <i>your@emailaddress.com</i>
            </PrimaryText>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={28}
          >
            <MuiButton
              title="Cancel"
              variant="outlined"
              color="secondary"
              sx={{ paddingY: 6, fontSize: 14, fontWeight: 700 }}
              onClick={onClose}
            />

            <MuiButton
              title="Export To Email"
              variant="contained"
              sx={{ paddingY: 6, fontSize: 14, fontWeight: 700 }}
              endIcon={<LuChevronRight color={COLORS.primary.white} />}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
