import { firestore } from "utils/firebase";

export const listenForRecurringDonationChanges = (
	org,
	setOurRecurringDonations
) => {
	return firestore
		.collection("donations_recurring")
		.where("orgId", "==", org.id)
		.where("active", "==", true)
		.orderBy("startDate", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let donations = [];

				querySnapshot.forEach((doc) => {
					donations.push({
						id: doc.id,
						...doc.data()
					});
				});

				setOurRecurringDonations(donations);
			},
			(e) => {
				console.log("listenForRecurringDonationChanges err", e);
			}
		);
};

export const getRecentDonations = async (selectedOrg, setHistory) => {
	firestore
		.collection("donations")
		.where("orgId", "==", selectedOrg?.id)
		.orderBy("date", "desc")
		// .limit(10)
		.get()
		.then((querySnapshot) => {
			let history = [];
			querySnapshot.forEach((doc) => {
				history.push({
					...doc.data(),
					id: doc.id
				});
			});
			setHistory(history);
		})
		.catch((e) => {
			console.log("getRecentDonations error", e);
		});
};
