import { Box, Divider, IconButton, Modal, Stack } from "@mui/material";
import MuiButton from "components/Common/MuiButton";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import React from "react";
import { LuX } from "react-icons/lu";
import { TouchableOpacity } from "react-native-web";
import { dollarFormatter } from "utils/helpers";

export default function DonationMatchModal({ onClose, open, selectedDonations, matchPercentage }) {

  return (
    <Modal open={open}>
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="100%"
          maxWidth={524}
          minHeight={100}
          bgcolor={COLORS.primary.white}
          position="relative"
          borderRadius={6}
          py={24}
          px={30}
          display="flex"
          flexDirection="column"
          alignItems="center"
          mx={12}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 12,
              right: 12,
            }}
            onClick={onClose}
          >
            <LuX size={24} />
          </IconButton>

          <PrimaryText
            fontSize={24}
            fontWeight={900}
            style={{ textAlign: "center", width: "100%" }}
          >
            Donation Match Approval
          </PrimaryText>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={20}
            width="100%"
            maxWidth={340}
          >
            <PrimaryText fontSize={16} fontWeight={900}>
              Employee
            </PrimaryText>
            <PrimaryText fontSize={16} fontWeight={900}>
              Amount
            </PrimaryText>
          </Stack>

          <Stack
            width="100%"
            maxWidth={340}
            maxHeight={340}
            overflow="auto"
            mt={8}
          >
            {selectedDonations.map((item) => (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={16}
                key={item}
              >
                <PrimaryText fontSize={16}>{item?.name}</PrimaryText>
                <PrimaryText fontSize={16} fontWeight={900}>
                  {item?.matchAmount}
                </PrimaryText>
              </Stack>
            ))}
          </Stack>

          <Divider
            width="70%"
            bgcolor={COLORS.primary.neutral_400}
            sx={{ my: 24 }}
          />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            maxWidth={340}
          >
            <PrimaryText fontSize={16}>Total Employee Matches:</PrimaryText>
            <PrimaryText fontSize={16} fontWeight={900}>
              {selectedDonations.length}
            </PrimaryText>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            maxWidth={340}
            mt={16}
          >
            <PrimaryText fontSize={16}>Total Match Amount:</PrimaryText>
            <PrimaryText fontSize={16} fontWeight={900}>
              {dollarFormatter.format(
                selectedDonations.reduce((acc, item) => {
                  return acc + item?.matchAmount;
                }, 0)
              )}
            </PrimaryText>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            maxWidth={340}
            mt={16}
          >
            <PrimaryText fontSize={16}>Payment Method:</PrimaryText>
            <Stack direction="row" alignItems="center" gap={10}>
              <PrimaryText fontSize={16} fontWeight={900}>
                xx-3921
              </PrimaryText>

              <TouchableOpacity>
                <PrimaryText
                  fontSize={16}
                  fontWeight={900}
                  color={COLORS.primary.lightBlue}
                >
                  Change
                </PrimaryText>
              </TouchableOpacity>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            mt={28}
            width="100%"
          >
            <MuiButton
              title="Cancel"
              variant="outlined"
              color="secondary"
              sx={{ paddingY: 6, fontSize: 14, fontWeight: 700 }}
              onClick={onClose}
            />

            <MuiButton
              title="Approve"
              variant="contained"
              sx={{ paddingY: 6, fontSize: 14, fontWeight: 700 }}
            />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}
