import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { COLORS } from "constants/Colors";
// import { Header } from "components/Header"
import { CustomInput } from "components/Inputs/CustomInput";
import { PrimaryText } from "components/Common/PrimaryText";
import * as FiIcons from "react-icons/fi";
import { cloneDeep } from "lodash";
import { ConfirmModal } from "components/Modals/Confirmation";
import { EditUserCard } from "components/Cards/EditUserCard";
import { updateMemberInfo } from "services/user-service";
import { FullModal } from "components/Modals/FullModal";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  getUsersName,
  validateEmail,
  validateEmailDoesntExist,
} from "utils/helpers";
import { useOrganization } from "context/OrganizationContext";
import { POSITIONS, ROLES } from "constants/Data";
import { RiAlertFill } from "react-icons/ri";
import { archiveUser, inviteMember } from "services/organizations-service";
import { useAuth } from "context/AuthContext";
import { functions } from "utils/firebase";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { CustomSwitch } from "components/Inputs/CustomSwitch";
import { Button } from "components/Common/Button";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";

const options = [
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];

function TabPanel(props) {
  const { children, value, index } = props;

  return <div>{value === index && <Box sx={{ p: 0 }}>{children}</Box>}</div>;
}

export function Team() {
  const { selectedOrg, members, setMembers, invitedMembers, userType } =
    useOrganization();
  const { currentUser } = useAuth();

  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedView, setSelectedView] = useState(options[0]?.value);
  const [ascending, setAscending] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [sorted, setSorted] = useState([]);

  //user add and edit
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditUser, setShowEditUser] = useState(false);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState(null);
  const [invitePosition, setInvitePosition] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!members) return;
    const membersMutate = members.map((x) => {
      return { uid: x.id };
    });
    const stringifiedMembers = JSON.stringify(membersMutate);
    functions
      .httpsCallable("getAuthUsers")(stringifiedMembers)
      .then((res) => {
        let temp = [];
        members.forEach((member) => {
          const find = res?.data.find((x) => x.uid === member.id);
          if (find) member.email = find.email;
          temp.push(member);
        });
        setUsers(temp);
      })
      .catch((e) => {
        console.log("error getting auth users", e);
      });
  }, [members, selectedOrg]);

  //on search change
  useEffect(() => {
    if (search) {
      const temp = users.filter(
        (x) =>
          x.firstName.toLowerCase().includes(search.toLowerCase()) ||
          x.lastName.toLowerCase().includes(search.toLowerCase())
      );

      setFiltered(temp);
    } else {
      setFiltered(users);
    }
  }, [search, users]);

  useEffect(() => {
    if (filtered) {
      let temp = cloneDeep(filtered);

      switch (selectedView) {
        case "active":
          temp = temp.filter((x) => x.active || x.active === undefined);
          break;
        case "archived":
          temp = temp.filter((x) => !x.active);
          break;
        default:
          break;
      }

      temp.sort((a, b) => sortObject(a.firstName, b.firstName));

      setSorted(temp);
    }
  }, [selectedView, ascending, filtered]);

  useEffect(() => {
    if (!showEditUser && !showConfirmModal) {
      setSelectedUser(null);
      setLoading(false);
    }
  }, [showEditUser, showConfirmModal]);

  useEffect(() => {
    setEmailError(null);
    setEmailError(
      !validateEmail(inviteEmail) ||
        validateEmailDoesntExist(inviteEmail, users)
    );
  }, [inviteEmail]);

  function sortObject(a, b) {
    if (a === b) return 0;

    if (!ascending) {
      return a < b ? 1 : -1;
    } else {
      return a < b ? -1 : 1;
    }
  }

  function onArchiveUser(user) {
    setSelectedUser(user);
    setShowConfirmModal(true);
  }

  function archive() {
    setLoading(true);
    archiveUser(
      selectedOrg.id,
      selectedUser.id,
      !selectedUser.active,
      selectedUser?.invite,
      onSuccessUpdate
    );
  }

  function inviteUser() {
    setLoading(true);
    //invite user via email
    const data = {
      orgId: selectedOrg.id,
      orgName: selectedOrg.name,
      role: inviteRole,
      position: invitePosition,
      email: inviteEmail,
    };
    inviteMember(data, onSuccessInvite);
  }

  function onSuccessInvite() {
    window.alert("Invite successfully sent");
    setShowInviteUser(false);
    setLoading(false);
    setInviteRole(null);
    setInviteEmail(null);
    setInvitePosition(null);
    
  }

  function editUser(user) {
    setSelectedUser(user);
    setShowEditUser(true);
  }

  function onChangeUserInfo(prop, value) {
    const temp = cloneDeep(selectedUser);
    temp[prop] = value;
    setSelectedUser(temp);
    updateMemberInfo(
      selectedOrg,
      selectedUser,
      { [prop]: value },
      onSuccessUpdate
    );
  }

  useEffect(() => {
    setEmailError(null);

    if (showEditUser === "edit") {
      setSubmitDisabled(!selectedUser);
    } else {
      //adding user
      setSubmitDisabled(
        !validateEmail(selectedUser?.email) ||
          validateEmailDoesntExist(selectedUser?.email, users)
      );
    }
  }, [selectedUser]);

  function onSuccessUpdate() {
    setShowConfirmModal(false);
    setLoading(false);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentTableData = () => {
    const data = sorted.concat(invitedMembers);
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return data.slice(firstPageIndex, lastPageIndex);
  };

  return (
    <>
      <ShadowContainer style={styles.container}>
        {/** HEADER */}
        <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
          Team
        </PrimaryText>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          width="100%"
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
          marginBottom={12}
          gap={12}
        >
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems="center"
            gap={12}
          >
            {userType === "Leader" && (
              <Button
                title={"Invite Team Member"}
                onPress={() => setShowInviteUser(true)}
                iconRight={
                  <FiIcons.FiUserPlus size={18} color={COLORS.primary.white} />
                }
              />
            )}
            <CustomInput
              placeholder={"Search"}
              style={{ width: "100%", maxWidth: 350 }}
              onChangeText={setSearch}
              value={search}
            />
          </Box>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              maxWidth: 200,
            }}
          >
            <PrimaryText
              fontSize={14}
              fontWeight={800}
              style={{ color: COLORS.primary.darkGray, marginHorizontal: 4 }}
            >
              View
            </PrimaryText>

            <CustomPicker
              value={selectedView}
              onChange={(value) => setSelectedView(value)}
              options={options}
            />
          </View>
        </Box>

        {/** TABLE */}
        <TableContainer>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ textAlign: "left" }}>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Email</TableCell>
                {userType === "Leader" && (
                  <>
                    <TableCell>Edit</TableCell>
                    <TableCell>
                      {selectedView === "active" ? "Archive" : "Unarchive"}
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {currentTableData() &&
                currentTableData().map((item, index) => {
                  return (
                    <EditUserCard
                      key={index}
                      item={item}
                      onArchive={() => onArchiveUser(item)}
                      onEdit={() => editUser(item)}
                      isCurrentUser={currentUser?.id === item?.id}
                      currentUserRole={userType}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <MuiTablePagination
          count={currentTableData()?.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e.target.value);
            setCurrentPage(0);
          }}
        />

        {/** EDIT USER */}
        {showEditUser && (
          <FullModal
            title={"Edit Team Member"}
            setShowModal={setShowEditUser}
            minHeight={500}
          >
            <View style={{ flex: 1 }}>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: COLORS.primary.lightBlue,
                  },
                }}
              >
                <Tab
                  label="Permissions"
                  style={{
                    color: COLORS.primary.lightBlue,
                    fontSize: 12,
                    fontWeight: 700,
                    fontFamily: "Montserrat",
                  }}
                />
                {/* <Tab
                  label="Notifications"
                  style={{
                    color: COLORS.primary.lightBlue,
                    fontSize: 12,
                    fontWeight: 700,
                    fontFamily: "Montserrat",
                  }}
                /> */}
              </Tabs>
              <View
                style={{
                  height: 2,
                  backgroundColor: COLORS.primary.lightGray,
                  marginTop: -2,
                  zIndex: -1,
                }}
              />

              {/** PERMISSIONS */}
              <TabPanel value={value} index={0}>
                <View style={{ flex: 1 }}>
                  <PrimaryText
                    fontSize={20}
                    fontWeight={800}
                    style={{
                      textAlign: "center",
                      marginVertical: 12,
                    }}
                  >
                    {selectedUser?.firstName
                      ? getUsersName(selectedUser)
                      : selectedUser?.email}
                  </PrimaryText>
                </View>
                <View style={styles.roleRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={900}
                    style={{ flex: 1 }}
                  >
                    Role
                  </PrimaryText>
                  <CustomPicker
                    // style={{ maxWidth: 170 }}
                    value={selectedUser?.role}
                    onChange={(value) => onChangeUserInfo("role", value)}
                    options={ROLES}
                  />
                </View>

                <View style={styles.roleRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={900}
                    style={{ flex: 1 }}
                  >
                    Position
                  </PrimaryText>
                  <CustomPicker
                    // style={{ maxWidth: 170 }}
                    value={selectedUser?.position}
                    onChange={(value) => onChangeUserInfo("position", value)}
                    options={POSITIONS}
                  />
                </View>

                {selectedOrg?.type === "nonprofit" && (
                  <View style={styles.settingsRow}>
                    <PrimaryText
                      fontSize={16}
                      fontWeight={400}
                      style={styles.setting}
                    >
                      View Donations
                    </PrimaryText>
                    <CustomSwitch
                      value={
                        selectedUser?.viewDonations !== undefined
                          ? selectedUser?.viewDonations
                          : true
                      }
                      onValueChange={(value) =>
                        onChangeUserInfo("viewDonations", value)
                      }
                    />
                  </View>
                )}

                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Create Public Posts
                  </PrimaryText>

                  <CustomSwitch
                    value={
                      selectedUser?.publicPosts !== undefined
                        ? selectedUser?.publicPosts
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("publicPosts", value)
                    }
                  />
                </View>
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Post New Events
                  </PrimaryText>
                  <CustomSwitch
                    value={
                      selectedUser?.postNewOpportunities !== undefined
                        ? selectedUser?.postNewOpportunities
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("postNewOpportunities", value)
                    }
                  />
                </View>
                {/* <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Volunteer Application Review
                  </PrimaryText>
                  <CustomSwitch
                    value={
                      selectedUser?.volunteerApplicationReview !== undefined
                        ? selectedUser?.volunteerApplicationReview
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("volunteerApplicationReview", value)
                    }
                  />
                </View> */}
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Attendee Check-in
                  </PrimaryText>
                  <CustomSwitch
                    value={
                      selectedUser?.volunteerCheckIn !== undefined
                        ? selectedUser?.volunteerCheckIn
                        : true
                    }
                    onValueChange={(value) =>
                      onChangeUserInfo("volunteerCheckIn", value)
                    }
                  />
                </View>
              </TabPanel>

              {/** NOTIFICATIONS */}
              <TabPanel value={value} index={1}>
                <View style={{ flex: 1 }}>
                  <PrimaryText
                    fontSize={20}
                    fontWeight={800}
                    style={{
                      textAlign: "center",
                      marginTop: 12,
                    }}
                  >
                    {getUsersName(selectedUser)}
                  </PrimaryText>
                </View>
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    New Application Received
                  </PrimaryText>
                  <CustomSwitch
                    value={selectedUser?.receiveNewApplications}
                    onValueChange={(value) =>
                      onChangeUserInfo("receiveNewApplications", value)
                    }
                  />
                </View>
                <View style={styles.settingsRow}>
                  <PrimaryText
                    fontSize={16}
                    fontWeight={400}
                    style={styles.setting}
                  >
                    Post Comments
                  </PrimaryText>
                  <CustomSwitch
                    value={selectedUser?.receivePostComments}
                    onValueChange={(value) =>
                      onChangeUserInfo("receivePostComments", value)
                    }
                  />
                </View>
              </TabPanel>
            </View>

            {/* <PrimaryText fontSize={16} fontWeight={500} >New Location Name</PrimaryText> */}
          </FullModal>
        )}

        {/** INVITE USER */}
        {showInviteUser && (
          <FullModal
            title="Invite Team Member"
            setShowModal={setShowInviteUser}
          >
            <View
              style={{
                maxWidth: 500,
                width: "100%",
                backgroundColor: COLORS.primary.white,
              }}
            >
              <View style={styles.roleRow}>
                <PrimaryText
                  fontSize={16}
                  fontWeight={900}
                  style={[styles.setting, { width: 60 }]}
                >
                  Email
                </PrimaryText>
                <CustomInput
                  placeholder={"User's email address"}
                  style={{ marginLeft: 12, maxWidth: 350, flex: 1 }}
                  onChangeText={setInviteEmail}
                  value={inviteEmail}
                />
              </View>
              {emailError && (
                <View style={styles.errorContainer}>
                  <RiAlertFill
                    width={16}
                    height={16}
                    fill={COLORS.primary.error}
                    stroke={COLORS.primary.white}
                  />
                  <PrimaryText
                    fontSize={12}
                    fontWeight={400}
                    style={styles.errorMessage}
                  >
                    {validateEmailDoesntExist(inviteEmail, users)
                      ? "Email already exists"
                      : "Email Invalid"}
                  </PrimaryText>
                </View>
              )}

              <View style={[styles.roleRow, { zIndex: 999 }]}>
                <PrimaryText
                  fontSize={16}
                  fontWeight={900}
                  style={[styles.setting, { width: 60 }]}
                >
                  Role
                </PrimaryText>
                <CustomPicker
                  style={{ marginLeft: 12, maxWidth: 350, flex: 1 }}
                  value={inviteRole}
                  onChange={(value) => setInviteRole(value)}
                  options={ROLES}
                />
              </View>
              <View style={styles.roleRow}>
                <PrimaryText
                  fontSize={16}
                  fontWeight={900}
                  style={[styles.setting, { width: 60 }]}
                >
                  Position
                </PrimaryText>
                <CustomPicker
                  style={{ marginLeft: 12, maxWidth: 350, flex: 1 }}
                  value={invitePosition}
                  onChange={(value) => setInvitePosition(value)}
                  options={POSITIONS}
                />
              </View>

              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    opacity:
                      emailError ||
                      !inviteEmail ||
                      !inviteRole ||
                      !invitePosition
                        ? 0.4
                        : 1,
                    width: 200,
                    alignSelf: "center",
                    marginTop: 20,
                  },
                ]}
                onPress={inviteUser}
                disabled={
                  emailError || !inviteEmail || !inviteRole || !invitePosition
                }
              >
                <PrimaryText
                  fontSize={14}
                  fontWeight={800}
                  style={{ color: COLORS.primary.white }}
                >
                  {"Send Invitation"}
                </PrimaryText>
              </TouchableOpacity>

              {/* <PrimaryText fontSize={16} fontWeight={500} >New Location Name</PrimaryText> */}
            </View>
          </FullModal>
        )}

        {showConfirmModal && (
          <ConfirmModal
            title={`${selectedUser?.active ? "Archive" : "Unarchive"} ${
              selectedUser?.firstName + " " + selectedUser?.lastName
            }`}
            message={"Are you sure?"}
            onConfirm={() => archive()}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
      </ShadowContainer>
      {loading && (
        <ActivityIndicator
          animating={loading}
          size={"large"}
          style={styles.activity}
          color={COLORS.primary.lightBlue}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    // backgroundColor: COLORS.primary.white,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    // height: 30,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
    paddingVertical: 6.5,
    marginRight: 12,
    borderRadius: 20,
  },
  body: {
    flex: 1,
    width: "100%",
    backgroundColor: COLORS.primary.white,
    marginTop: 12,
    alignItems: "center",
  },

  settingsRow: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomColor: COLORS.primary.lightGray,
    borderBottomWidth: 0.5,
  },
  roleRow: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  setting: {},
  errorContainer: {
    backgroundColor: "#FEEFEF",
    height: 32,
    width: 350,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 6,
    borderRadius: 8,
    flexDirection: "row",
    margin: 12,
    marginLeft: 87,
  },
  errorMessage: {
    color: COLORS.primary.error,
    paddingLeft: 8,
  },
  title: {
    marginBottom: 12,
  },
});
