import {
  Box,
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Misc from "assets/misc";
import { ChevronLeft } from "assets/svgs";
import LoadingStateless from "components/Common/LoadingStateless";
import MuiCard from "components/Common/MuiCard";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { PrimaryText } from "components/Common/PrimaryText";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import { CustomInput } from "components/Inputs/CustomInput";
import { ProfilePic } from "components/Pics/ProfilePic";
import { COLORS } from "constants/Colors";
import { useOrganization } from "context/OrganizationContext";
import { useUsers } from "context/UserContext";
import { capitalize } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDonationHistoryByOrgId } from "services/businesses/business-donations-service";
import { formattedPhoneNumber } from "utils";
import { dollarFormatter, getUsersName } from "utils/helpers";

export default function DonorDetails() {
  const { selectedUser } = useUsers();
  const { selectedOrg } = useOrganization();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Donation History");
  const [donationHistory, setDonationHistory] = useState([]);

  // pagination states
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState();

  const userInfo = useMemo(
    () => ({
      Gender: capitalize(selectedUser?.gender),
      Location: selectedUser?.city
        ? selectedUser?.city + ", " + selectedUser?.state
        : selectedUser?.zipCode,
      Birthday: moment(selectedUser?.birthday.toDate()).format("DD/MM/YYYY"),
      "Join Date": moment(selectedUser?.createdAt.toDate()).format(
        "DD/MM/YYYY"
      ),
    }),
    [selectedUser]
  );

  const userContact = useMemo(
    () => ({
      Email: selectedUser?.email,
      Phone: formattedPhoneNumber(selectedUser?.phone),
    }),
    [selectedUser]
  );

  const getDonations = () => {
    setLoading(true);

    getDonationHistoryByOrgId(selectedUser, selectedOrg, setDonationHistory, setLoading);

    // getSelectedUserDonations({
    //   userId: selectedUser?.id,
    //   donations: donationHistory,
    //   rowsPerPage: newRowsPerPage || rowsPerPage,
    //   currentPage: page,
    //   nextPage: newPage || page,
    //   donationsCount,
    // })
    //   .then(({ donationsCount, donations, totalDonation }) => {
    //     setTotalDonation(totalDonation);
    //     setDonationsCount(donationsCount);
    //     setDonationHistory(donations);

    //     setPage(newPage || 0);

    //     setRowsPerPage(newRowsPerPage || rowsPerPage);
    //   })
    //   .catch((err) => console.log(err))
    //   .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDonations();
  }, []);

  useEffect(() => {
    if (search) {
      setFiltered(
        donationHistory?.filter(x => x.organization.name.toLowerCase().includes(search.toLowerCase()))
      )
    } else {
      setFiltered(donationHistory);
    }
  }, [search, donationHistory]);

  const totalDonation = useMemo(() => {
    return donationHistory.reduce((acc, curr) => acc + curr.amount, 0);
  }, [donationHistory]);

  function onBack() {
    navigate(-1);
  }

  function onBack() {
    navigate(-1);
  }

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };

  return (
    <Box width="100%">
      <Box display="flex" alignItems="center">
        <IconButton onClick={onBack}>
          <ChevronLeft stroke={COLORS.primary.darkBlue} />
        </IconButton>

        <PrimaryText fontSize={24} fontWeight={900} style={{ marginLeft: 17 }}>
          Donation Details
        </PrimaryText>
      </Box>

      {/* User Details Section */}
      <Box mt={28} display="flex" flexWrap="wrap" gap={40}>
        <ProfilePic uri={selectedUser?.profilePic} size={215} />

        <Box>
          <PrimaryText fontSize={32} fontWeight={900}>
            {getUsersName(selectedUser)} -{" "}
            <PrimaryText
              fontSize={32}
              fontWeight={900}
              style={{ color: COLORS.primary.neutral_500 }}
            >
              @{selectedUser?.username}
            </PrimaryText>
          </PrimaryText>

          <Box display="flex" flexWrap="wrap" mt={14} gap={20}>
            <Box>
              <PrimaryText fontSize={24}>User Information</PrimaryText>

              {Object.entries(userInfo).map(([key, value], index) => (
                <RowItem title={key} value={value} key={key + index} />
              ))}
            </Box>

            <Box>
              <PrimaryText fontSize={24}>Contact Information</PrimaryText>

              {Object.entries(userContact).map(([key, value], index) => (
                <RowItem title={key} value={value} key={key + index} />
              ))}
            </Box>
          </Box>
        </Box>

        <Box
          width={2}
          height={160}
          bgcolor={COLORS.primary.neutral_400}
          mt={40}
          display={{ xs: "none", md: "block" }}
        />

        <Box display="flex" flexDirection="column" alignItems="center">
          <PrimaryText fontSize={32} fontWeight={900}>
            Details
          </PrimaryText>

          <Box display="flex" alignItems="center" mt={40}>
            <MuiCard
              value={dollarFormatter.format(totalDonation)}
              title="Total Donated"
              containerStyle={{
                width: 220,
                height: 100,
              }}
            />

            {/* <MuiCard
							value="195 hrs"
							title="Volunteering"
							containerStyle={{
								width: 220,
								height: 100
							}}
						/> */}
          </Box>
        </Box>
      </Box>

      {/* Tab & Table */}
      <Box width="100%" mt={110}>
        {/* Tabs */}
        <Box display="flex">
          {["Donation History" /* "Volunteering" */].map((title, index) => (
            <Tab
              isActive={activeTab === title}
              title={title}
              onClick={setActiveTab}
              key={title + index}
            />
          ))}
        </Box>

        {/* Table */}
        <ShadowContainer>
          <Box mb={20} mt={10}>
            <CustomInput
              placeholder="Search"
              style={{ maxWidth: 380 }}
              onChangeText={setSearch}
              value={search}
            />
          </Box>

          {activeTab === "Donation History" && (
            <DonationHistoryTable data={currentTableData()} loading={loading} />
          )}

          {activeTab === "Volunteering" && (
            <VolunteeringTable loading={loading} />
          )}

          {loading && <LoadingStateless />}
          <MuiTablePagination
            count={filtered?.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            onRowsPerPageChange={(e) => {
              setRowsPerPage(e.target.value);
              setCurrentPage(0);
            }}
          />

        </ShadowContainer>
      </Box>
    </Box>
  );
}

const RowItem = ({ title, value }) => (
  <Box display="flex" alignItems="center" mt={15}>
    <PrimaryText
      fontSize={20}
      style={{
        width: 100,
      }}
    >
      {title}:
    </PrimaryText>
    <PrimaryText
      fontSize={20}
      fontWeight={900}
      style={{ color: title === "Email" ? COLORS.primary.lightBlue : null }}
    >
      {value}
    </PrimaryText>
  </Box>
);

const Tab = ({ title, isActive, onClick }) => (
  <ButtonBase
    sx={{
      ml: 40,
      boxShadow: isActive ? "5px 5px 15px rgba(128, 128, 128, 0.15)" : "",
      borderRadius: "10px 10px 0 0",
      overflow: "hidden",
    }}
    onClick={() => onClick(title)}
  >
    <Box
      pt={8}
      pb={10}
      px={25}
      bgcolor={isActive ? COLORS.primary.neutral : COLORS.primary.neutral_200}
    >
      <PrimaryText fontSize={24} fontWeight={900}>
        {title}
      </PrimaryText>
    </Box>
  </ButtonBase>
);

const DonationHistoryTable = ({ data, loading }) => (
  <TableContainer>
    <Table className="table">
      <TableHead>
        <TableRow>
          {["NONPROFIT", "FREQUENCY", "AMOUNT GIVEN", "DATE"].map(
            (title, index) => (
              <TableCell key={title + index}>{title}</TableCell>
            )
          )}
        </TableRow>
      </TableHead>

      {!loading && (
        <TableBody>
          {data?.map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell>
                  <PrimaryText fontSize={20} fontWeight={900}>
                    {row?.organization?.name}
                  </PrimaryText>
                </TableCell>
                <TableCell>
                  <PrimaryText fontSize={16}>{row.recurring ? "Recurring" : "One-time"}</PrimaryText>
                </TableCell>
                <TableCell>
                  <PrimaryText fontSize={16}>{dollarFormatter.format(row.amount)}</PrimaryText>
                </TableCell>
                <TableCell>
                  <PrimaryText fontSize={16}>{moment(row.date).format("MM/DD/YYYY")}</PrimaryText>
                </TableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      )}
    </Table>
  </TableContainer>
);

const VolunteeringTable = ({ loading }) => (
  <TableContainer>
    <Table className="table">
      <TableHead>
        <TableRow>
          {["EVENT NAME", "DATE", "LOCATION", "HOURS VOLUNTEERED"].map(
            (title, index) => (
              <TableCell key={title + index}>{title}</TableCell>
            )
          )}
        </TableRow>
      </TableHead>

      {!loading && (
        <TableBody>
          <TableRow>
            <TableCell>
              <Box
                display="flex"
                alignItems="center"
                alignSelf="center"
                justifyContent="center"
                flexShrink={1}
              >
                <img
                  src={Misc.emptyPicRect}
                  alt="Event"
                  width={110}
                  height={70}
                  style={{
                    borderWidth: 0.5,
                    borderColor: COLORS.primary.neutral_400,
                    borderRadius: 12,
                    marginRight: 18,
                  }}
                />
                <PrimaryText
                  fontSize={20}
                  fontWeight={900}
                  style={{ color: COLORS.primary.lightBlue }}
                >
                  Event Name
                </PrimaryText>
              </Box>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>
                Sun Dec 29, 2024 @ 3:14pm MST
              </PrimaryText>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>LIttleton, CO, 80127</PrimaryText>
            </TableCell>
            <TableCell>
              <PrimaryText fontSize={16}>4 hrs</PrimaryText>
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Table>
  </TableContainer>
);
