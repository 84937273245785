import React, { useEffect, useState } from "react";
import { StyleSheet, ActivityIndicator } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { ShadowContainer } from "components/Containers/ShadowContainer";
import MuiTablePagination from "components/Common/MuiTablePagination";
import { CustomInput } from "components/Inputs/CustomInput";
import { DateInput } from "components/Inputs/DateInput";
import PrimaryButton from "components/Buttons/PrimaryButton";
import { FiUpload } from "react-icons/fi";
import CustomLinksCard from "components/Cards/CustomLinksCard";
import { AddCircle } from "assets/svgs";
import CreateCustomLinkModal from "components/Modals/CreateCustomLinkModal";
import { useOrganization } from "context/OrganizationContext";
import { deleteCustomLink } from "services/custom-link-service";
import exportCSV from "utils/export-csv";
import moment from "moment";
import MuiSnackbar from "components/Common/MuiSnackbar";
import { useCustomLinks } from "context/CustomLinksContext";
import { useNavigate } from "react-router-dom";
import { Links } from "constants/Links";
import { ConfirmModal } from "components/Modals/Confirmation";
import { useFundraisers } from "context/FundraiserContext";
import useMediaQuery from "hooks/useMediaQuery";
import { sortRows } from "utils/sorting";

export default function CustomLinks() {
  const { selectedOrg } = useOrganization();
  const {
    setGetCustomLinks,
    customLinks,
    setCustomLinks,
    setSelectedCustomLink,
  } = useCustomLinks();
  const { setGetFundraisers } = useFundraisers();
  const { getResponsiveValue } = useMediaQuery();

  // console.log(customLinks)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState({
    sortBy: "totalDonations",
    order: "desc",
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  console.log({ showConfirmModal });

  useEffect(() => {
    setGetCustomLinks(true);
    setGetFundraisers(true);
  }, []);

  useEffect(() => {
    let sorted = [...(customLinks || [])].map((item) => ({
      ...item,
      donors: item?.backers?.length,
    }));

    if (sorted.length) {
      sorted = sortRows({
        sortby: sorting.sortBy,
        tableRows: sorted,
        ascending: sorting.order === "asc",
      });
    }

    if (search) {
      sorted = sorted.filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.customUrl.toLowerCase().includes(search.toLowerCase())
      );
    }
    setFiltered(sorted);
  }, [search, customLinks, sorting]);

  const resetFilters = () => {
    setSearch("");
    setStartDate(null);
    setEndDate(null);
    // getCustomLinks({});
  };

  // Export custom links in CSV
  const exportCustomLinks = () => {
    const currentData = customLinks;
    if (!currentData?.length) return;
    const dataToExport = currentData.map((row) => {
      return {
        NAME: row?.name,
        URL: row?.customUrl,
        "CREATION DATE": moment(row?.createdAt.toDate()).format("MM/DD/YYYY"),
        DONORS: 0,
        "AMOUNT RAISED": 0,
      };
    });
    exportCSV({
      data: dataToExport,
      filename: "Custom Links",
    });
  };

  // get custom links by pagination and filters
  // const getCustomLinks = useCallback(
  // 	({ newPage, newRowsPerPage, startFrom, endTo, searchText }) => {
  // 		setLoading(true);

  // 		getCustomLinksByOrgId({
  // 			orgId: selectedOrg?.id,
  // 			links: customLinks,
  // 			currentPage,
  // 			nextPage: newPage ?? currentPage,
  // 			linksCount,
  // 			rowsPerPage: newRowsPerPage || rowsPerPage,
  // 			search: searchText ?? search,
  // 			startDate: startFrom ?? startDate,
  // 			endDate: endTo ?? endDate
  // 		})
  // 			.then(({ customLinkDocs, totalLinks }) => {
  // 				setCustomLinks(customLinkDocs);
  // 				setLinksCount(totalLinks);

  // 				setCurrentPage(newPage || 0);
  // 				if (newRowsPerPage) setRowsPerPage(newRowsPerPage);
  // 			})
  // 			.catch((err) => console.log(err))
  // 			.finally((_) => setLoading(false));
  // 	},
  // 	[
  // 		selectedOrg?.id,
  // 		// customLinks,
  // 		// linksCount,
  // 		currentPage,
  // 		rowsPerPage,
  // 		search,
  // 		startDate,
  // 		endDate
  // 	]
  // );

  // useEffect(() => {
  // 	if (!customLinks?.length && !search && !startDate && !endDate)
  // 		getCustomLinks({});
  // }, [getCustomLinks, search, startDate, endDate]);

  const handleSearch = (searchText) => {
    setSearch(searchText);
    // getCustomLinks({ searchText });
  };

  const inActiveLink = async (id) => {
    setLoading(true);
    await deleteCustomLink(selectedOrg?.id, id);
    setShowConfirmModal(false);
    setLoading(false);
    return;

    // updateCustomLink({
    // 	orgId: selectedOrg?.id,
    // 	id,
    // 	data: {
    // 		status: "inactive"
    // 	}
    // })
    // 	.then(() => {
    // 		const existingLinks = [...(customLinks || [])];

    // 		existingLinks.splice(index, 1);

    // 		setCustomLinks(existingLinks);

    // 		setOpenSnackbar("Custom link has been deleted.");
    // 	})
    // 	.catch((err) => console.log(err))
    // 	.finally((_) => setLoading(false));
  };

  const handleDateChange = (e, key) => {
    const value = e.target.value;

    if (key === "startDate" && value > endDate) setEndDate(null);
    if (key === "endDate" && value < endDate) setStartDate(null);

    console.log({ key, value });
    if (key === "startDate") setStartDate(value);
    if (key === "endDate") setEndDate(value);

    // if (key === "startDate" && value && endDate && value < endDate)
    // 	getCustomLinks({ startFrom: value });
    // if (key === "endDate" && value && startDate && value > startDate)
    // 	getCustomLinks({ endTo: value });
  };

  function customLinkSelected(doc) {
    setSelectedCustomLink(doc);
    navigate(Links.CustomLinkDetails);
  }

  const currentTableData = () => {
    const firstPageIndex = currentPage * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return filtered.slice(firstPageIndex, lastPageIndex);
  };

  const handleSort = (sortBy) => {
    if (sorting.sortBy === sortBy) {
      setSorting({
        ...sorting,
        order: sorting.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSorting({ sortBy, order: "asc" });
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" mt={38}>
        <PrimaryText
          fontSize={24}
          fontWeight={600}
          style={{
            marginBottom: 9,
            marginLeft: getResponsiveValue({ xs: 12, lg: 36 }),
          }}
        >
          What Are Custom Links?
        </PrimaryText>
        <PrimaryText
          fontSize={16}
          style={{
            marginBottom: 27,
            width: getResponsiveValue({ xs: "90%", md: "65%" }),
            marginLeft: getResponsiveValue({ xs: 12, lg: 84 }),
          }}
        >
          You can use custom links for tracking donations in a variety of ways.
          Want to let people donate to a particular part of your nonprofit like
          supplies or employee salaries? Use custom links. Want to set up a
          competition for who can raise the most between different teams? Use
          custom links. They are a very powerful way to engage donors and keep
          people engaged with your cause!
        </PrimaryText>
      </Box>
      <ShadowContainer style={styles.container}>
        {/** HEADER */}
        <PrimaryText fontSize={32} fontWeight={900} style={styles.title}>
          Custom Links
        </PrimaryText>

        <Box
          display="flex"
          flexDirection={{ xs: "column", xl: "row" }}
          alignItems={{ xs: "flex-start", xl: "center" }}
          justifyContent={{ xs: "flex-start", xl: "space-between" }}
          width="100%"
          mb={40}
          mt={14}
          gap={12}
        >
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={12}
          >
            {/* Create new link button */}
            <PrimaryButton
              title="Create New Link"
              icon={<AddCircle color="white" style={{ marginRight: 10 }} />}
              style={{ marginBottom: 0, paddingHorizontal: 16, height: 29 }}
              fontSize={14}
              fontWeight={700}
              // titleStyle={{
              // 	fontSize: 14,
              // 	fontWeight: 700
              // }}
              onPress={() => setIsCreateModalOpen(true)}
            />

            <CustomInput
              placeholder="Search"
              style={{
                width: "100%",
                maxWidth: 350,
                marginHorizontal: 4,
              }}
              value={search}
              onChangeText={handleSearch}
            />
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row" }}
            gap={12}
          >
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={12}
            >
              {/* Start Date */}
              <Box display="flex" alignItems="center">
                <PrimaryText
                  fontSize={14}
                  fontWeight={900}
                  style={{ marginRight: 10 }}
                >
                  Start Date
                </PrimaryText>
                <DateInput
                  value={startDate}
                  onChange={(e) => {
                    console.log("test", e.target.value);
                    handleDateChange(e, "startDate");
                  }}
                  placeholder="MM/DD/YY"
                  long
                />
              </Box>

              {/* End Date */}
              <Box display="flex" alignItems="center">
                <PrimaryText
                  fontSize={14}
                  fontWeight={900}
                  style={{ marginRight: 10 }}
                >
                  End Date
                </PrimaryText>
                <DateInput
                  value={endDate}
                  onChange={(e) => handleDateChange(e, "endDate")}
                  placeholder="MM/DD/YY"
                  long
                />
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap={12}
            >
              {/* Reset */}
              <PrimaryButton
                title="Reset"
                variant="outlined"
                fontSize={14}
                fontWeight={700}
                // titleStyle={{ fontSize: 14, fontWeight: 700 }}
                style={{ height: 30, margin: 0, marginHorizontal: 4 }}
                onPress={resetFilters}
              />
              {/* Export Button */}
              <PrimaryButton
                title="Export Report"
                onPress={exportCustomLinks}
                icon={<FiUpload color="white" style={{ marginRight: 10 }} />}
                style={{ marginBottom: 0, paddingHorizontal: 16, height: 29 }}
                fontSize={14}
                fontWeight={700}
              />
            </Box>
          </Box>
        </Box>

        {/** TABLE */}
        <TableContainer>
          <Table
            className="table"
            sx={{
              borderTopLeftRadius: "12px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "left" }}>
                  <TableSortLabel
                    direction={sorting.order}
                    active={sorting.sortBy === "name"}
                    onClick={() => handleSort("name")}
                  >
                    NAME
                  </TableSortLabel>
                </TableCell>
                <TableCell>LINK URL</TableCell>
                <TableCell>CREATION DATE</TableCell>
                <TableCell>
                  <TableSortLabel
                    direction={sorting.order}
                    active={sorting.sortBy === "donors"}
                    onClick={() => handleSort("donors")}
                  >
                    DONORS
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    direction={sorting.order}
                    active={sorting.sortBy === "totalDonations"}
                    onClick={() => handleSort("totalDonations")}
                  >
                    AMOUNT RAISED
                  </TableSortLabel>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {currentTableData() &&
                currentTableData().map((doc, index) => {
                  return (
                    <CustomLinksCard
                      key={index}
                      // onInactiveLink={() => inActiveLink(doc.id, index)}
                      onInactiveLink={() => setShowConfirmModal(doc)}
                      {...doc}
                      selectedOrg={selectedOrg}
                      onPress={() => customLinkSelected(doc)}
                    />
                  );
                })}
            </TableBody>

            {loading && (
              <ActivityIndicator
                animating={loading}
                size="large"
                style={styles.activity}
                color={COLORS.primary.lightBlue}
              />
            )}
          </Table>
        </TableContainer>

        <MuiTablePagination
          disabled={loading}
          count={filtered?.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          setCurrentPage={setCurrentPage}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e.target.value);
            setCurrentPage(0);
          }}
        />
      </ShadowContainer>

      <MuiSnackbar open={openSnackbar} onClose={() => setOpenSnackbar(false)} />

      <CreateCustomLinkModal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onCreateLink={(doc) => setCustomLinks((prev) => [doc, ...(prev || [])])}
      />

      {!!showConfirmModal && (
        <ConfirmModal
          title={`Are you sure you want to \ndelete ${showConfirmModal?.name}?`}
          message={"This action cannot be undone"}
          onConfirm={() => inActiveLink(showConfirmModal.id, 0)}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  activity: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  container: {
    flex: 1,
    width: "100%",
    flexDirection: "column",
  },
});
