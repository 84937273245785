import styled from "@emotion/styled";
import { Box, ButtonBase, Paper } from "@mui/material";
import ErrorMessage from "components/Common/ErrorMessage";
import { PrimaryText } from "components/Common/PrimaryText";
import { COLORS } from "constants/Colors";
import { causes } from "constants/Data";
import React, { useState } from "react";

export default function SelectCauses({
  selectedCauses = [],
  onChange = () => {},
  errorMessage,
  maxLimit,
  ...props
}) {
  const [error, setError] = useState();

  const handleChange = (cause) => {
    const causes = [...selectedCauses];
    const index = causes.indexOf(cause);

    if (error) setError(null);

    // remove the cause if exists
    if (index > -1) causes.splice(index, 1);
    else if (maxLimit && causes.length >= maxLimit)
      setError(`You can select maximum ${maxLimit} causes.`);
    else causes.push(cause);

    onChange(causes);
  };

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-evenly"
        gap={12}
        my={12}
        {...props}
      >
        {causes.map((cause) => (
          <Cause
            key={cause.value}
            isActive={
              selectedCauses.includes(cause.value) ||
              selectedCauses.includes(cause.rayze)
            }
            onChange={handleChange}
            {...cause}
          />
        ))}
      </Box>
      <ErrorMessage errorMessage={errorMessage || error} marginTop={20} />
    </>
  );
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ isActive }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: 80,
  height: 80,
  borderRadius: 10,
  justifyContent: "center",
  padding: 5,
  "&.MuiPaper-elevation1": {
    boxShadow: `0px 2px 5px 0px rgba(61, 61, 61, 0.19)`,
  },
  backgroundColor: isActive
    ? COLORS.primary.lightBlue
    : COLORS.primary.neutral_100,
}));

const StyledImage = styled("img")(({ isActive }) => ({
  width: 30,
  height: 30,
  objectFit: "contain",
  marginBottom: 8,
  filter: isActive ? "invert(1) brightness(2)" : "none",
}));

export const Cause = (props) => {
  let { name, icon, value, onChange, isActive, containerStyle } = props;

  if (!name) {
    let causeObj = causes.find((cause) => cause.value === props?.value);
    if (!causeObj) {
      causeObj = causes.find((cause) => cause.rayze === props?.value);
    }
    if (!causeObj) return null;
    const { name: causeName, icon: causeIcon } = causeObj;
    name = causeName;
    icon = causeIcon;
  }

  return (
    <ButtonBase sx={containerStyle} onClick={() => onChange?.(value)} id={name}>
      <StyledPaper isActive={isActive}>
        <StyledImage src={icon} alt={name} isActive={isActive} />
        <PrimaryText
          fontSize={10}
          fontWeight={400}
          style={{
            textAlign: "center",
            color: isActive ? COLORS.primary.white : COLORS.primary.darkBlue,
          }}
          numberOfLines={2}
        >
          {name}
        </PrimaryText>
      </StyledPaper>
    </ButtonBase>
  );
};
