import React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Box } from "@mui/material";
import { PrimaryText } from "components/Common/PrimaryText";
import MuiButton from "components/Common/MuiButton";
import { FiChevronRight } from "react-icons/fi";

export default function StartingCampaings({ open, onPress }) {
  return (
    <Backdrop open={open}>
      <Box
        sx={{
          width: "100%",
          maxWidth: 523,
          height: 263,
          bgcolor: "white",
          borderRadius: "25px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          mx: 12,
        }}
      >
        <PrimaryText
          fontWeight={900}
          fontSize={24}
          style={{ textAlign: "center" }}
        >
          Before Starting Your
          <br />
          First Campaign
        </PrimaryText>

        <PrimaryText
          fontSize={17}
          style={{ textAlign: "center", marginTop: 12 }}
        >
          Let’s make sure your settings and
          <br />
          payment processing are set up!
        </PrimaryText>

        <MuiButton
          title="Go To Settings"
          variant="contained"
          endIcon={<FiChevronRight />}
          sx={{ mt: "28px" }}
          onPress={onPress}
        />
      </Box>
    </Backdrop>
  );
}
