import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Links } from "constants/Links";

import { Dashboard } from "screens/Dashboard/Dashboard";
import { useDisplay } from "context/DisplayContext";
import { Team } from "screens/Team/Team";
import { Settings } from "screens/Settings/Settings";
import { Events } from "screens/Events/Events";
import { EventDetail } from "screens/Events/EventDetail-new";
import { Donations } from "screens/Donations/Donations";
import { Fundraising } from "screens/Fundraising/Fundraising";
import { Verification } from "screens/Verification/Verification";
import { UserDetail } from "screens/Users/UserDetail";
import Congrats from "screens/Auth/2e-Congrats";
import { FundraisingDetail } from "screens/Fundraising/FundraisingDetail";
import DonorDetails from "screens/Donations/DonorDetails";
import CustomLinks from "screens/CustomLinks/CustomLinks";
import CustomLinkDetails from "screens/CustomLinks/CustomLinkDetails";
import { Box } from "@mui/material";

export default function MainNavigator() {
  const { showSidebar } = useDisplay();

  return (
    <Box
      flex={1}
      marginLeft={{ xs: 0, lg: showSidebar ? 280 : 60 }}
      alignItems={"center"}
      justifyContent={"center"}
      p={{ xs: 12, md: 24 }}
    >
      <Routes>
        <Route path={Links.Dashboard} element={<Dashboard />} />

        {/** redirects */}
        <Route
          path={"/*"}
          element={<Navigate replace to={Links.Dashboard} />}
        />

        <Route path={Links.Team} element={<Team />} />

        <Route path={Links.Events} element={<Events />} />
        <Route path={Links.EventDetail} element={<EventDetail />} />

        <Route path={Links.Donations} element={<Donations />} />

        <Route path={Links.DonorDetails} element={<DonorDetails />} />

        <Route path={Links.Fundraising} element={<Fundraising />} />
        <Route path={Links.FundraisingDetail} element={<FundraisingDetail />} />
        <Route path={Links.CustomLinks} element={<CustomLinks />} />
        <Route path={Links.CustomLinkDetails} element={<CustomLinkDetails />} />
        <Route path={Links.UserDetails} element={<UserDetail />} />

        <Route path={Links.Settings} element={<Settings />} />
        <Route path={Links.Verification} element={<Verification />} />
      </Routes>
    </Box>
  );
}
