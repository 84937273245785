import React, { useEffect, useState } from "react";
import { PrimaryText } from "components/Common/PrimaryText";
import { ProfilePic } from "components/Pics/ProfilePic";
import { TableCell, TableRow } from "@mui/material";
import { getUser } from "services/user-service";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { DONATION_STATUSES } from "constants/Data";
import { COLORS } from "constants/Colors";
import * as FiIcons from "react-icons/fi";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native-web";
import moment from "moment";
import { Button } from "components/Common/Button";
import { OrgPic } from "components/Pics/OrgPic";

export const OrgCard = ({
  nonprofit,
  onPress,
  onDonate,
  sum
}) => {
  const [showAllCauses, setShowAllCauses] = useState(false);

  const verificationIcon = require("assets/verification.png");
  return (
    <TableRow
      key={nonprofit.id}
      hover
      onClick={onPress}
      style={{ cursor: "pointer" }}
    >
      <TableCell width={36} style={{ alignItems: "center" }}>
        <OrgPic uri={nonprofit?.profilePic} size={36} />
      </TableCell>
      <TableCell style={{ textAlign: "left" }}>
        <PrimaryText
          fontSize={20}
          fontWeight={900}
        >
          {nonprofit.name}
        </PrimaryText>
      </TableCell>
      <TableCell>
        <PrimaryText
          fontSize={14}
          fontWeight={900}
        >
          <Image
            source={verificationIcon}
            style={{
              width: 20,
              height: 20,
              tintColor: nonprofit?.verified
                ? COLORS.primary.sunflower
                : COLORS.primary.lightGray
            }}
          />
        </PrimaryText>
      </TableCell>
      <TableCell
        onMouseOver={() =>
          setShowAllCauses(nonprofit.causes.length > 2 ? true : false)
        }
        onMouseOut={() => setShowAllCauses(false)}
        style={{ width: 300, justifyContent: "center" }}
      >
        {showAllCauses ? (
          <View style={{ flex: 1, paddingHorizontal: 24 }}>
            <View
              style={{
                backgroundColor: COLORS.primary.lightBlue,
                height: "100%",
                borderRadius: 10,
                padding: 4,
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              {nonprofit.causes.map((cause, index) => (
                <PrimaryText
                  fontSize={16}
                  fontWeight={500}
                  style={{ color: COLORS.primary.white }}
                >
                  {cause}
                  {nonprofit.causes.length - 1 !== index && ", "}
                </PrimaryText>
              ))}
            </View>
          </View>
        ) : (
          <>
            <PrimaryText
              fontSize={16}
              fontWeight={500}
            >
              {nonprofit.causes[0]}
            </PrimaryText>
            {nonprofit.causes.length > 1 && (
              <PrimaryText
                fontSize={16}
                fontWeight={500}
              >
                ,<br />
                {nonprofit.causes[1]}...
              </PrimaryText>
            )}
          </>
        )}
      </TableCell>
      <TableCell>
        <View style={{ alignItems: "center" }}>
          <PrimaryText
            fontSize={16}
            fontWeight={500}
          >
            {dollarFormatter
              .format(sum || 0)
              .slice(0, -3)}
          </PrimaryText>
          <PrimaryText
            fontSize={12}
            fontWeight={400}
            style={{ color: COLORS.primary.gray }}
          >
            Total
          </PrimaryText>
        </View>
      </TableCell>
      <TableCell>
        <Button
          title={"Donate"}
          style={{
            backgroundColor: COLORS.primary.green,
            paddingVertical: 4,
            text: {
              fontSize: 14
            }
          }}
          iconLeft={
            <FiIcons.FiDollarSign
              size={12}
              color={COLORS.primary.white}
              style={{ marginRight: 6 }}
            />
          }
          onPress={onDonate}
        />
      </TableCell>
      {/* <TableCell>
				<View style={{ alignItems: "center" }}>
					<PrimaryText
						fontSize={16}
						fontWeight={500}
					>
						{nonprofit?.totalVolunteerHours || 0}
					</PrimaryText>
					<PrimaryText
						fontSize={12}
						fontWeight={400}
						style={{ color: COLORS.primary.gray }}
					>
						Total
					</PrimaryText>
				</View>
			</TableCell> */}
    </TableRow>
  );
};

const styles = StyleSheet.create({
  button: {
    // paddingHorizontal: 4,
  }
});
