import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { COLORS } from "constants/Colors";
import { ActivityIndicator, Dimensions, ScrollView } from "react-native-web";
import { ModalHeader } from "./ModalHeader";

const { height } = Dimensions.get("window");

export function FullModal({
  children,
  setShowModal,
  title,
  minHeight,
  style = {},
  containerStyle = {},
  subtitle = null,
  maxWidth,
  loading = false,
  zIndex = 999,
}) {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <View
      style={[
        styles.container,
        { width: windowSize[0], height: windowSize[1], zIndex },
        containerStyle,
      ]}
    >
      <View style={[styles.body, style, { maxWidth: maxWidth || 500 }]}>
        <ModalHeader
          title={title}
          onClose={() => setShowModal(false)}
          subtitle={subtitle}
        />
        <ScrollView
          style={{
            flex: 1,
            width: "100%",
            maxWidth: maxWidth || 500,
            minHeight,
            backgroundColor: COLORS.primary.white,
          }}
        >
          {children}
        </ScrollView>
        {loading && (
          <View
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 999,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator
              animating={loading}
              size={"large"}
              style={styles.activity}
              color={COLORS.primary.blue}
            />
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: "fixed",
    flex: 1,
    height,
    backgroundColor: "rgba(0,0,0,0.7)",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0,
    paddingHorizontal: 12,
  },
  body: {
    maxHeight: height - 200,
    borderRadius: 12,
    backgroundColor: COLORS.primary.white,
    paddingVertical: 22,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignSelf: "center",
    width: "100%",
    maxWidth: 500,
  },
});
